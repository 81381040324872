// import {CognitoUser, AuthenticationDetails} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk";

function getCookie(name) {
    let clist = document.cookie.split(';')
    let cookies = clist.map((centry) => {return centry.split('=')});
    let cookie_val;
    cookies.forEach((cookie) => {
        if(cookie[0].trim() === name) {
            cookie_val = cookie[1].trim()}
    })
    return cookie_val;
}

// TODO - get region, id_pool and bucket from config file.
function getPreSignedUrl(elementId,
                         imagePath,
                         s3BucketName ='darwin-graphics-test',
                         cognitoIdPool='eu-west-1:7b843f90-ba62-4960-af3a-3cab6bc6918d',
                         awsRegion='eu-west-1'
                         ) {

    let s3Resource;
    let accessToken = getCookie('access_token');
    let idToken = getCookie('id_token');

    // Initialize the Amazon Cognito credentials provider
    AWS.config.region = awsRegion;
    try {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: cognitoIdPool,
            Logins: {
                "cognito-idp.eu-west-1.amazonaws.com/eu-west-1_1RYH1hN91": idToken
            },
        });
    } catch(err) {
        console.log(err)
        window.alert(err.message)
    }

    AWS.config.credentials.clearCachedId();
    AWS.config.credentials.get(function (err) {
        if (err) {
           console.log(err, err.stack);

        } else {
            let params = {AccessToken: accessToken};
            let cognitoIdProvider = new AWS.CognitoIdentityServiceProvider();
            try {
                cognitoIdProvider.getUser(params, function (err, data) {
                    if (err) {
                        console.log(err, err.stack);
                    } else {
                        // an error occurred
                        let params = {
                            Bucket: s3BucketName ,
                            Key: imagePath
                        };

                        s3Resource = new AWS.S3({
                            apiVersion: '2006-03-01',
                            params: {Bucket: s3BucketName }
                        });

                        let url = s3Resource.getSignedUrl('getObject', params);
                        console.log('The URL is', url);

                        let imgElement = document.getElementById(elementId)

                        if (imgElement != undefined) {
                            imgElement.src = url;
                        }
                    }
                });
            } catch(err) {
                console.log(err)
            }
        }
    });
}

export default getPreSignedUrl;