import React from "react";
// import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import Table from "../../common/table";

import useFetch from "../../common/useFetch";

import getApiUrl from "../../common/functions/get_api_url";

const urlCurrent = new URL(document.URL);
const params = new URLSearchParams(urlCurrent.search);
//const qs_auth = params.get('access_token') !== undefined ? '?access_token=' + params.get('access_token') + '&id_token=' + params.get('access_token') : '';


function addLinkUrl(row) {
    // Adds the target_url attribute to each row so that <Link /> will work, can't compute it during row rendering.
    row.target_url = '/Vehicle/Detail/' + row["Vehicle_ID"];
    row.data_url = '/Vehicle/Data/' + row["Vehicle_ID"];
    return row
}

function VehicleListTable2() {
    // let { objType, objId, objAction } = ParseLocation();
    let data = useFetch(getApiUrl('Vehicle'));
    data = data.map(addLinkUrl)
    const columns = React.useMemo(
        () => [
            {
                Header: 'Vehicles',
                columns: [
                    // { Header: '', accessor: 'graphic',  Cell: ({ row }) => (
                    //         <Link to={ row.original.target_url }>
                    //             {/*<img src={row.original.graphic.length < 4 ? "": row.original.graphic} alt={'Vehicle icon'}/>*/}
                    //             <img src="" alt="Vehicle icon"></img>
                    //         </Link>)
                    // },
                    { Header: 'Registration No.', accessor: 'registration_number', Cell: ({ row }) => (
                            <Link to={ row.original.target_url }>{row.original.registration_number}</Link>),
                    },
                    { Header: 'Make / Model', accessor: 'make', Cell: ({ row }) => <span>{row.original.make} {row.original.model}</span>},
                    { Header: 'Map', accessor: 'map_focus', Cell: ({ row }) => <span className={"center"}><i className={"ft-crosshair"}></i></span>},
                    { Header: 'Telemetry', accessor: 'data_link', Cell: ({ row }) => <span className={"center"}>
                            <Link to={row.original.data_url}><i className={"ft-info"}></i></Link>
                            </span>},
                    // { Header: 'Model', accessor: 'model', },
                    // { Header: 'AWS', accessor: 'aws_connected', },
                ],
            },
        ],
        []
    );
    return (
        <Table columns={columns} data={data} className={"box-container"}/>
    );
};

export default VehicleListTable2