import React, { useState } from "react";
import  { useTable, useFilters, useSortBy } from "react-table";

function Table({columns, data, tableName}) {
    const [filterInput, setFilterInput] = useState("");
    const [filterColumn, setFilterColumn] = useState("");

    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        setFilter
    } = useTable({
        columns,
        data
    },
        useFilters,
        useSortBy
    );

    const handleFilterChange = e => {
        let filterColumn='';
        if (tableName === 'vehicles') {
            filterColumn = 'registration_number'
        } else if (tableName === 'customers'){
            filterColumn = 'customer_name'
        } else if (tableName === 'liveries'){
            filterColumn = 'Vehicle_ID'
        }

        let value = e.target.value || undefined;

        setFilter(filterColumn, value);
        setFilterInput(value);
    };

    /*
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */

    return (
        <>
            <input
                value={filterInput}
                onChange={handleFilterChange}
                placeholder={"Search "}
            />
            <table className="table col-sm-4 table-white-space table-bordered row-grouping display no-wrap icheck table-sm"{...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </>
    );
}

export default Table