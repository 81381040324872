import React from 'react';
import Table from "../../common/table";
import { Link } from "react-router-dom";

// import useFetch from "../../common/useFetch";
// import getApiUrl from "../../common/functions/get_api_url";
// import ParseLocation from "../../common/functions/parse_location";
import addRowLinkUrl from "../../common/functions/add_row_link_url";
import TableActionBar from "../../common/tableActionBar";
// import getVehicleLiveryData from "../../common/functions/vehicle_livery_api";
import Spinner from "../../UI/Spinner/Spinner";

class VehicleLiveryTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {vehicle_liveries: []};
        this.updateTableCard = React.createRef();
    }

    // componentDidMount() {
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    // componentWillUnmount() {
    // }

    render() {
        let body;

        if (this.state.vehicle_liveries.length > 0) {

            const columns = [
                {
                    Header: 'Vehicle Livery',
                    columns: [
                        { Header: <input type="checkbox" className="input-chk" id="chkAll" name="select_cb"/>,
                            accessor: 'row_select', Cell: ({row}) => (
                                <input type="checkbox" className="input-chk" name="row_select_cb"></input>)
                        },
                        { Header: 'Livery Name', accessor: 'Vehicle_ID', Cell: ({row}) => (
                                <Link to={row.original.target_url}>{row.original.Vehicle_ID}</Link>)
                        },
                    ],
                },
            ];
            let col_data = this.state.vehicle_liveries.map(row => addRowLinkUrl(row, 'Vehicle_ID'))
            body = <Table columns={columns} data={col_data} tableName={"liveries"}/>
            // body = <div>{"TABLE"}</div>
        } else {
            body = <Spinner/>
        }

        return (
            <div className="card-body">
                {/*<TableSearchBar />*/}
                <TableActionBar />
                { body }
            </div>
        )
    }
}
export default VehicleLiveryTable;