import React from 'react';

function CardHeaderControls() {
        return(
            <span>
                <button className="heading-elements-toggle">
                    <i className="fa fa-ellipsis-v font-medium-3"></i>
                </button>
                <div className="heading-elements">
                    <ul className="list-inline mb-0">
                        <li><a data-action="collapse"><i className="ft-minus"></i></a></li>
                        <li><a data-action="reload"><i className="ft-rotate-cw"></i></a></li>
                        <li><a data-action="close"><i className="ft-x"></i></a></li>
                    </ul>
                </div>
            </span>
        )
}

export default CardHeaderControls;