import React from 'react';
import VehicleSlideoutCard from "../../dashboard/cards/dashboardVehicleSlideoutCard";

class VehicleSlideOut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {Customer_ID: 'initial'}
        // Create a reference that is passed to child components to enable their state to be updated.
        this.updateChildState=React.createRef()

        // this.childStateHandler = this.childStateHandler.bind(this)
    };

    // childStateHandler(state) {
    //     this.setState(state)
    // }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("Slideout Did Update, updating children")
        this.updateChildState.current.setState(this.state)
    }
    componentDidMount() {
        console.log("Slideout Did Mount, updating children")
        this.updateChildState.current.setState(this.state)
    }

    render() {

        return (
            <div id="vehicle-slideout"
                 className="vehicle-slideout slideout-fixed slideout-hide slideout-light menu-accordion menu-shadow menu-border">
                {/* State Communication debugging - 2 lines, comment out when happy */}
                {/*<div>cid:{this.state.customer_id}</div>*/}
                {/*<div>vid:{this.state.vehicle_id}</div>*/}
                <div className="vehicle-slideout-content slideout-hide ps-container ps-theme-dark">
                    <VehicleSlideoutCard ref={this.updateChildState}/>

                </div>
            </div>
        );
    }
}

export default VehicleSlideOut