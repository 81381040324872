import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import MapContainer from "../../map/mapContainer";

class DashboardMapCard extends React.Component {
    constructor(props) {
        super(props);
        // this.childStateHandler = this.childStateHandler.bind(this)
        this.state = {
            Customer_ID: '', // customer
            Vehicle_ID: '', // vehicle id, set when map feature is clicked on
            vehicleDetails: '', // Details from the vehicles table
            vehicleTelemetry: '', // Telemetry -> Last Known Location
        }
    };

    // childStateHandler() {
    //  // dashboard
    //     this.setState({
    //         someVar: 'some value'
    //     })
    // }
    // constructor(props) {
    //     super(props);
    //     this.state={mapSlideOutId: '',
    //         mapSlideOutData: ''};
    //     this.childStateHandler = this.childStateHandler.bind(this);
    // }
    // childStateHandler() {
    //  // dashboardMapCard
    //     // window.alert('dashboardMapCard - state handler')
    //     this.setState({
    //         someVar: 'some value'
    //     })
    // }
    componentDidMount() {
    
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
    
    }
    render() {
        // const updateParentHandler = this.props.updateParentHandler;
    
        return (
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Vehicle Locations</h4>
                    {/*<div>ID: </div>*/}
                    <div>{this.state.mapSlideOutId}</div>
                    <CardHeaderControls {...this.props}/>
                    {/*<div className={"float-right"}>*/}
                    {/*    <div>Customer ID: {this.state.Customer_ID}</div>*/}
                    {/*    <div>Vehicle ID: {this.state.vehicle_id}</div>*/}
                    {/*    <button onClick={() => this.props.updateParentHandler({Customer_ID: 'DashMapCard-cid'})} > MapCard - Set Customer </button>*/}
                    {/*</div>*/}


                </div>
                <div className="card-content show">
                    <div className="card-body">
                        <MapContainer {...this.props} ref={this.updateChildState} updateParentHandler={this.props.updateParentHandler}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardMapCard;