import React from 'react';


import CardHeaderControls from "../../common/cardHeaderControls";
import CustomerListTable from "../parts/customerListTable";

class CustomerListCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            // CARD CONTAINER
            <div className="card overflow-auto ">

                {/* CARD CONTENT HEADER */}
                <div className="card-header">
                    <h4 className="card-title">Customers</h4>
                    <CardHeaderControls/>
                </div>

                {/* CARD CONTENT BODY */}
                <div className="card-content show">
                    <div className="card-body">
                        <CustomerListTable {...this.props}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerListCard;