import React from 'react';
import ColorPicker1 from "../ColorPicker/colorPicker1";
import FileUploadControl from "../../common/fileUploader";
import classes from './Input.css'


// This structure is used to map Form elementType to the JSX for that element.
const Input = ( props ) => {
    let labelElement = '';
    let inputElement = null;
    const inputclasses = [classes.InputElement];

    if (props.invalid && props.shouldValidate && props.touched) {
        inputclasses.push(classes.Invalid);
    }

    if (props.elementType !== 'hidden') {
        labelElement = <label className={classes.Label}>{ props.label }</label>
    }

    switch ( props.elementType ) {
        case ( 'input' ):
            debugger
            inputElement = <input
                // className={inputclasses.join(' ')}
                {...props.elementConfig}
                id={props.id}
                value={props.value}
                onChange={props.changed}
            />;
            break;

        case ( 'colorpicker1' ):
            inputElement = <ColorPicker1
                // className={inputclasses.join(' ')}
                {...props.elementConfig}
                className={'zOrder5'}
                id={props.id}
                value={props.value}
                onChange={props.changed} />;
            break;

        case ( 'fileupload1' ):
            inputElement = <FileUploadControl
                // className={inputclasses.join(' ')}
                {...props.elementConfig}
                id={props.id}
                value={props.value}
                folder={props.folder}
                onChange={props.changed} />;
            break;

        case ( 'rangeslider' ):
            debugger
            inputElement =
                <div className="row bm-20">
                    <input className="custom-range float-right"
                           type="range"
                           id={props.id}
                           {...props.elementConfig}
                    />
                </div>;
            break;

        case ( 'hidden' ):
            inputElement = <input
                // className={inputclasses.join(' ')}
                {...props.elementConfig}
                id={props.id}
                value={props.value}
                onChange={props.changed}
                />;
            break;

        case ( 'date' ):
            inputElement = <input
                // className={inputclasses.join(' ')}
                    className={'pickadate-selectors'}
                {...props.elementConfig}
                id={props.id}
                value={props.value}
                onChange={props.changed}
            />;
            break;

        case ( 'textarea' ):
            inputElement = <textarea
                className={inputclasses.join(' ')}
                {...props.elementConfig}
                id={props.id}
                value={props.value}
                onChange={props.changed} />;
            break;

        case ( 'select' ):
            inputElement = (
                <select
                    className={inputclasses.join(' ')}
                    id={props.id}
                    value={props.value}
                    onChange={props.changed}
                >{props.value}
                    {props.elementConfig.options.map(option =>
                        <option selected={option['value']===props.value} value={option['value']}>{option['value']}</option>
                    )}
                </select>
            );
            break;

        case ( 'checkbox' ):
            inputElement = (
                // <div className="form-group col-md-4">
                //      <input type="checkbox" id="switchery" className="switchery" checked readOnly/>
                //      <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">Lidor</label>
                //  </div>

                <div className="form-group col-md-4">
                    <input
                        type="checkbox"
                        id={props.id}
                        name={"cb_"+props.field}
                        className="switchery"
                        onChange={props.changed}
                        checked={props.value}>

                    </input>
                    {/*<label htmlFor={"cb_"+props.field} className="font-medium-2 text-bold-600 ml-1">{props.field}</label>*/}
                </div>

            );
            break;

        default:
            inputElement = <input
                className={inputclasses.join(' ')}
                {...props.elementConfig}
                id={props.id}
                value={props.value}
                onChange={props.changed} />;
    }

    return (
        // <div className={classes.Input}>
        <div className="row">
            <div className="col">{ labelElement }</div>
            <div className="col">{ inputElement }</div>
        </div>
    );

};

export default Input;