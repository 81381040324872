import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import checkValidity from "../../common/functions/check_validity";
import ParseLocation from "../../common/functions/parse_location";
import Spinner from "../../UI/Spinner/Spinner";
import Input from "../../UI/Input/Input";

// const URL = require('url'); // nodejs-builtin

class VehicleEditInsuranceCard extends React.Component {
    constructor(props) {
        super(props);
        this.updateHandler = this.updateHandler.bind(this)
        this.state = {
            formIsValid: true,
            formIsDirty: false,
            loading: true,
            vehicleDetails: {},
            vehicleForm: {
                insuranceForm: {
                    insurer: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            country: 'uk'
                        },
                        validation: {
                            required: true,
                            maxLength: 30
                        },
                        label: 'Insurer:',
                        value: '',
                        // valid: true,
                        errors: [],
                    },
                    policy_number: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            country: 'uk'
                        },
                        validation: {
                            required: true,
                            maxLength: 20
                        },
                        label: 'Policy Number:',
                        value: '',
                        // valid: true,
                        errors: [],
                    },
                    policy_start_date: {
                        elementType: 'date',
                        elementConfig: {},
                        validation: {
                            required: true,
                            isDate: true,
                        },
                        label: 'Policy start date:',
                        value: '',
                        // valid: true,
                        errors: [],
                    },
                    policy_end_date: {
                        elementType: 'date',
                        elementConfig: {},
                        validation: {
                            required: true,
                            isDate: true,
                        },
                        label: 'Policy end date:',
                        value: '',
                        // valid: true,
                        errors: [],
                    }
                // TODO - Add Claims Table
                }
            }
        }
    }

    updateHandler = (fieldName, value) => {
        // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
        let updatedForm = { ...this.state.vehicleForm };
        let updatedFormElement = {...updatedForm[fieldName]};
        updatedFormElement.value = value;
        checkValidity(updatedFormElement);
        updatedFormElement.touched = true;
        updatedForm[fieldName] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({vehicleForm: updatedForm });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedCustomerForm = {
            ...this.state.customerForm
        };
        const updatedFormElement = {
            ...updatedCustomerForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        // updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        checkValidity(updatedFormElement)
        updatedFormElement.touched = true;
        updatedCustomerForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedCustomerForm) {
            formIsValid = updatedCustomerForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({customerForm: updatedCustomerForm,formIsValid: formIsValid, formIsDirty: true});
    }

    // componentDidMount() {
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        if(this.state.vehicleDetails !== prevState.vehicleDetails) {
            // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
            let updateForm = { ...this.state.vehicleForm };
            // Object.keys(this.state.vehicleDetails).forEach(field => {
            //     debugger
            //     if (this.state.vehicleForm.hasOwnProperty(field)) {
            //         let updateElement = { ...updateForm[field] };
            //         if (updateElement.childre)
            //
            //         updateElement.value = this.state.vehicleDetails[field];
            //         updateForm[field] = { ...updateElement };
            //     }
            // });
            // this.setState({vehicleForm: { ...updateForm }})
            //
            Object.keys(this.state.vehicleForm).forEach(field => {
                debugger
                if (field.slice(-4) != 'Form') {
                    // root level fields
                    if (this.state.vehicleForm.hasOwnProperty(field)) {
                        let updateElement = { ...updateForm[field] };
                        updateElement.value = this.state.vehicleDetails[field];
                        updateForm[field] = { ...updateElement };
                    }

                } else {
                    // nested level fields
                    let node = field.slice(0, field.length - 4);
                    Object.keys(this.state.vehicleForm[field]).forEach(nodeField => {
                        if (this.state.vehicleDetails[node].hasOwnProperty(nodeField)) {
                            let updateElement = { ...updateForm[field][nodeField] };
                            updateElement.value = this.state.vehicleDetails[node][nodeField];
                            updateForm[field][nodeField] = { ...updateElement };
                        }
                    });
                }
            });
            this.setState({vehicleForm: { ...updateForm }})
        }
    }

    // componentWillUnmount() {
    // }


    render() {
        const {objType, objId, objAction} = ParseLocation();

        // Always start with a spinner
        let card_content = <Spinner />

        // Once the Card data has been loaded then show the real content
        // if (this.state.liveryDetail !== null) {
        //     card_content = processRows(this.state.liveryDetail)
        // }
        // if (this.state.vehicleDetails !== null && this.state.vehicleDetails.Vehicle_ID === this.state.vehicleForm.Vehicle_ID.value) {
        if (JSON.stringify(this.state.vehicleDetails) !== "{}" || objAction === 'Add') {
            card_content = []
            Object.keys(this.state.vehicleForm).forEach((key, index) => {
                if (key.slice(-4) !== 'Form') {
                    let input_content = <Input {...this.state.vehicleForm[key]} field={key} />
                    card_content.push(input_content);
                } else {
                    let node = key.slice(0, key.length -4);
                    Object.keys(this.state.vehicleForm[key]).forEach((nodeKey, index) => {
                        let input_content = <Input {...this.state.vehicleForm[key][nodeKey]} field={nodeKey} />
                        card_content.push(input_content);
                    });
                }
            });
        }

        return (
            <div className="card">
                <div className="card-header">
                    <div><h4 className="card-title">Insurance</h4></div>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleEditInsuranceCard;