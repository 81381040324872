import React from 'react';
import { PhotoshopPicker } from 'react-color';

class ColorPicker1 extends React.Component  {
    constructor(props) {
        // Always call super(props) in a constructor
        super(props);
        this.state = {
            displayColorPicker: false,
            selectedColour: this.props.value,
            originalColour: this.props.value,
            background: this.props.value
        }
    }

    // componentDidMount() {
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    // componentWillUnmount() {
    // }

    // Called by the button to show the colour selection popover
    handleClick = () => {
        this.setState({
            displayColorPicker: true,
            // originalColour: this.state.background
        })
    };

    // Callback method for the PhotoshopPicker, called whenever a colour is selected
    handleChangeComplete = (color, event) => {
        this.setState({
            background: color.hex,
            selectedColour: color.hex
        });
    };

    // Callback method for the PhotoshopPicker, called whenever the OK button is clicked
    // hides the colour selector and calls the updater callback
    handleAccept = (color, event) => {
        this.setState({ displayColorPicker: false});
        this.props.changed(this.props.field, this.state.background);
    }

    // Callback method for the PhotoshopPicker, called whenever the cancel button is clicked.
    // hides the colour selector and reverts the colour back to it's original value
    handleCancel = (color, event) => {
        this.setState({ displayColorPicker: false, background: this.state.originalColour });
    }

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '5',
            background: '#eeeeee',
        }
        const cover = {
            position: 'fixed',
            zIndex: '3',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        const pickerBorder = {
            background: 'red',
            border: '1px solid green'
        }
        const btnColour = {
            width: 80,
            background: this.state.background
        }


        return (
            <div id={this.props.field}>
                { this.state.displayColorPicker ? <div style={ popover }>
                    {/*<div style={ cover } onClick={ this.handleClose } />*/}
                    <PhotoshopPicker
                        color={ this.state.background }
                        // onchange={ this.handleChange }
                        onChangeComplete={ this.handleChangeComplete }
                        onAccept={ this.handleAccept }
                        onCancel={ this.handleCancel }
                    />
                </div>:<button onClick={ this.handleClick } style={btnColour}><span>&nbsp;{ this.state.selectedColour.trim() }&nbsp;</span></button>}
            </div>
        )
    }
}

export default ColorPicker1;

{/*// https://casesandberg.github.io/react-color/#api-onChange*/}
