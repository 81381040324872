import React from 'react';
import Chart from "react-google-charts";
import Spinner from "../../UI/Spinner/Spinner";
import CardHeaderControls from "../../common/cardHeaderControls";
import ParseLocation from "../../common/functions/parse_location";
//
// let wsClient = null;
// let wsClientVehicleId = null;
let {objType, objId, objAction} = ParseLocation();

class VehicleDataCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: {},
            vehicleTelemetry: {}, // Single instance of telemetry data, may be incomplete
            vehicleHistory: [[  // Array of fleshed-out telemetry data over time.
                'timestamp',
                // 'dt_canbus',
                // 'dt_filter',
                'dt_mqtt',
                'delta_1',
                'dt_iot',
                'delta_2',
                'lambda_time',
                'delta_3',
                'dt_streamproc',
                'BatteryVoltage',
                'AvailableEnergy',
                'VehicleSpeed',
                'Speed_Actual',
                'Pedal_Actual',
                'Right_Indicator_B',
                'Left_Indicator_B',
                'Steer_Actual',
                'Horn_B',
                'Main_Beam_B',
                'Dipped_Lights_B',
                'Side_Lights_B',
                'Reverse_Light_B',
                'Brake_Light_B',
                'Brake_Actual'
                // 'LATITUDE',
                // 'LONGITUDE'
            ]],

            Customer_ID: '',
            Vehicle_ID: objId,
        };
        // this.updatePageState = React.createRef();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentDidMount() {
    }

    render() {
        let num_cols = 2;
        let tbody =
            // <tbody className="width-100-per overflow-auto">
                <tr>
                    <td colSpan="2"><Spinner/></td>
                </tr>
            // </tbody>;
        if (this.state.vehicleHistory.length > 0) {
            let history = this.state.vehicleHistory
            // let rows;
            let array_column = (arr, n) => arr.map(e => e[n]);
            let fields = history.slice(0)[0]
            history.shift()
            let table_history = history.slice(-Math.min(5, history.length))

            // history.slice(history.length - 10 , history.length)
            let trs = []
            // for (let i = 0; i < 10; i++) {
            //     trs.push(<tr>
            //         <td>{i}</td>
            //     </tr>)
            // }
            for (const [i, field] of fields.entries()) {
                let tds = [];

                let col_data = array_column(table_history, i);
                num_cols = col_data.length;
                col_data.forEach(function(value) {

                    if (Object.prototype.toString.call(value) === '[object Date]'){
                        value = String(value.toLocaleTimeString('en-GB'));
                    }
                    tds.push(<td className="overflow-hidden h5" maxWidth="40px">{ value }</td>)
                })
                // let td_row = <table className="table-sm"><tr className="row">{ tds }</tr></table>

                trs.push(<tr id={'tbl_row_'+i} className="width-95-per">
                    <td>{ field }</td>
                    {/*<td>*/}
                        { tds }
                    {/*</td>*/}
                </tr>)
            }

            // tbody = <tbody className="width-100-per">{trs}</tbody>
            tbody =  trs
            history.unshift(fields);
        }

        let chart = <Spinner />
        if (this.state.vehicleHistory.length > 0) {

            // let history = [
            //     ["timestamp", "VehicleSpeed", "Speed_Actual"],
            //     ["a", 1, 1],
            //     ["b", 2, 4],
            //     ["c", 3, 9],
            //     [d, 4, 16],
            //     ["e", 5, 25],
            // ]
            let rows;
            let array_column = (arr, n) => arr.map(e => e[n]);
            let history = this.state.vehicleHistory.slice()

            // Field names that we want for this graph, convert to indices from the main vehicleHistory
            let chart_fields = ['dt_mqtt', 'VehicleSpeed'];
            let history_fields = history.slice(0)[0]
            history.shift()
            let history_indices = [];
            chart_fields.forEach( (field) => {
                let index = history_fields.indexOf(field)
                if(index >= 0) {
                    history_indices.push(index)
                }
            })

            let chart_history = []; // will hold data for just the columns we want
            history.forEach((row) => {
                console.log(row)
                let new_row = [];
                for (let [i, col] of row.entries()) {
                    if (history_indices.indexOf(i) >= 0) {
                        new_row.push(col);
                    }
                }
                chart_history.push(new_row);
            }) //forEach row loop

            chart_history.unshift(chart_fields)
            if (chart_history.length > 1) {
                chart =
                    <Chart
                        width={'600px'}
                        height={'400px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={ chart_history }
                        options={
                            {
                                hAxis: {
                                    title: 'Time',
                                },
                                vAxis: {
                                    title: 'Values',
                                },
                                series: {
                                    1: {curveType: 'function'},
                                },
                            }
                        }
                        rootProps={{'data-testid': '1'}}
                    />
            }
            // history.unshift(fields)
        }

        return (
            
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Telemetry</h4>
                    <CardHeaderControls {...this.props}/>
                </div>

                <div className="card-content">
                    <div className="card-body">
                        <div className="row">
                        <div className="col-md-6">
                            
                                <table className="table-sm width-100-per">

                                    <th className="width-100-per">
                                        <tr className="width-100-per overflow-hidden">
                                            <td className="width-100 overflow-hidden">Attribute</td>
                                            <td className="width-90-per overflow-hidden" colSpan={num_cols}>Values</td>
                                        </tr>
                                    </th>
                                    { tbody }
                                </table>
                          
                        </div>

                       
                        </div>
                        
                    </div>
                </div>
            </div>
       

            
        );
    }
}

export default VehicleDataCard;
