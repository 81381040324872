// map box code id here
// import React from "react";
import mapboxgl from "mapbox-gl";
// import { useState } from "react"
import getApiUrl from "../common/functions/get_api_url";

function show_slideout() {
    let slideout = document.getElementById('vehicle-slideout');
    if (slideout.classList.contains('slideout-hide')) {
        slideout.classList.remove('slideout-hide');
        slideout.classList.add('slideout-show');
    }

}

function avg(arr) {
    function sum(total, num) {
        return total + num;
    }
    let arr_sum =arr.reduce(sum, 0);
    return arr_sum / arr.length;
}

let calc_center_ll = function(data) {
    let lat = [];
    let lng = [];
    let clat = 0;
    let clng = 0;

    if (data) {
        // let feature_count = data.features.length;
        data.features.map(f => {
            lat.push(parseFloat(f.geometry.coordinates[0]));
            lng.push(parseFloat(f.geometry.coordinates[1]));
        });
        clat = avg(lat);
        clng = avg(lng);
        // clat = lat.reduce((previous, current) => current += previous) / feature_count
        // clng = lng.reduce((previous, current) => current += previous) / feature_count

        let center_point = [clat, clng];
        let bounds = [
            [Math.min(...lat) - 0.2, Math.min(...lng) - 0.2],
            [Math.max(...lat) + 0.2, Math.max(...lng) + 0.2]
        ]

        return {center_point, bounds}
    }
}

function createMap(component) {
    let map = new mapboxgl.Map({
        container: component.mapContainer,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [component.state.lng, component.state.lat],
        zoom: component.state.zoom
    });
    map.parent = component;
    map.addControl(new mapboxgl.FullscreenControl());
    map.hoveredVehicleId = null;
    map.on('move', () => {
        map.parent.setState({
            lng: map.getCenter().lng.toFixed(4),
            lat: map.getCenter().lat.toFixed(4),
            zoom: map.getZoom().toFixed(2)
        });
    });

    map.updateVehicleLocations = function(data) {
        console.log("state has vehicle locations:" + data.features.length);
        try {
            let srcCarLocations
            try {
                 srcCarLocations = map.getSource('Car-locations');
            } catch(e) {
                // pass
            }
            if (srcCarLocations === undefined) {
                // Add Data Source to map for Car-Locations
                map.addSource('Car-locations', {
                    'type': 'geojson',
                    'data': data,
                    'cluster': true,
                    'clusterMaxZoom': 14,
                    'clusterRadius': 30
                });
                debugger

                map.addLayer({
                    'id': 'vehicle-clusters',
                    'type': 'circle',
                    'source': 'Car-locations',
                    filter: ['has', 'point_count'],
                    paint: {
                    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                    // with three steps to implement three types of circles:
                    //   * Blue, 20px circles when point count is less than 100
                    //   * Yellow, 30px circles when point count is between 100 and 750
                    //   * Pink, 40px circles when point count is greater than or equal to 750
                    'circle-color': [
                    'step', ['get', 'point_count'],
                        '#51bbd6',
                        100, '#f1f075',
                        750, '#f28cb1'
                    ],
                    'circle-radius': [
                    'step',
                        ['get', 'point_count'],
                        10,
                        100, 20,
                        750, 30
                    ]
                }

                });

                map.addLayer({
                    'id': 'vehicles',
                    'type': 'circle',
                    'source': 'Car-locations',
                    'paint': {
                        'circle-radius': 5,
                        'circle-color': '#B42222',
                        'circle-stroke-color': 'white',
                        'circle-stroke-width': [
                            'case', ['boolean', ['feature-state', 'hover'], false], 10, 1
                            ]
                    },
                    // 'filter': ['==', '$type', 'Point']
                    filter: ['!', ['has', 'point_count']]
                });

                // map.addLayer({
                //     'id': 'places',
                //     'type': 'symbol',
                //     'source': 'Car-locations',
                //     'layout': {
                //         'icon-image': '{icon}-15',
                //         'icon-allow-overlap': true
                //     }
                // });

                map.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'Car-locations',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12
                    }
                });

                let result = calc_center_ll(data);
                if (result !== undefined) {
                    map.flyTo({
                        center: result.center_point,
                        // zoom: 6,
                        speed: 0.3,
                        curve: 2,
                        easing(t) {
                            return t
                        }
                    });
                setTimeout(function(){
                    map.fitBounds(result.bounds, {
                        linear: true,
                        maxZoom: 10,
                        speed: 0.1,
                        animate: true,
                        easing(t) {
                            return t
                        }
                    })},
                    2000);
                }

            } else {
                srcCarLocations.setData(data);
                let result = calc_center_ll(data);
                setTimeout(function(){
                    map.fitBounds(result.bounds, {
                        linear: true,
                        maxZoom: 10,
                        speed: 0.1,
                        animate: true,
                        easing(t) {
                            return t
                        }
                    })},
                2000);
            }
        } catch(err) {
            console.log("updating source with vehicle locations: " + err);
            this.getSource('Car-locations').setData(data);
        }
    }

    map.getVehicleLocations = function() {
        debugger

        try {
            // Determine if we need to pass the customer_id as a querystring param
            let qsParts = [];
            if (this.parent.state.Customer_ID) {
                qsParts.push(['Customer_ID', this.parent.state.Customer_ID])
            }
            fetch(getApiUrl('lastlocation', qsParts)) // - new location table
                .then(res => res.json())
                .then(
                    (result) => {
                        // Create / Update the vehicle location layer using the returned data
                        map.updateVehicleLocations(result);
                        if (map.parent.state['mapSlideOutId'] !== '') {
                            result.forEach( function(vehicle) {
                                if (vehicle.vehicle_id === this.state['mapSlideOutId']) {
                                    map.parent.setState({mapSlideOutData: vehicle})
                                }
                            })
                        }
                        map.parent.setState({
                            isLoaded: true,
                            initialMapDataLoaded: true
                        });
                    },
                    // Note: it's important to handle errors here instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log("Failed to fetch data from the lastlocation api")
                    }
                )
                .catch(function(err) {
                    window.alert('(1) Call to vehicle locations web service failed because: ' + err);
                })
        } catch (err) {
            window.alert('(2) Failed to call vehicle locations web service because: ' + err);
        }
    }

    map.on('data', function(e) {
        if (e.dataType == 'source') {
            let vehicle_features = map.isSourceLoaded('Car-locations');
            if (vehicle_features === true) {
                // add markers to map
                let marker_features = map.querySourceFeatures('Car-locations', {sourceLayer: 'vehicles'})
                marker_features.forEach(function (marker) {
                    // create a DOM element for the marker
                    if (!marker.properties.cluster) {
                        let zoom_level = map.getZoom()
                        let el = document.createElement('div');
                        el.className = 'marker';
                        el.style.backgroundImage =
                            'url("./assets/map_markers/Twizy.jpg")';
                        el.style.width = 10+(zoom_level) + 'px';
                        el.style.height = 8+(zoom_level) + 'px';
                        // el.style.width = marker.properties.iconSize[0] + 'px';
                        // el.style.height = marker.properties.iconSize[1] + 'px';
                        el.style.backgroundSize = '100%';

                        // el.addEventListener('click', function () {
                        // window.alert(marker.properties.registration.registration_plate);
                        // });

                        // add marker to map
                        new mapboxgl.Marker(el)
                            .setLngLat(marker.geometry.coordinates)
                            .addTo(map);
                    }
                });
            }
        }
    });

    map.on('load', function () {
        // Move to User's current location if available
        navigator.geolocation.getCurrentPosition(
            function (position) {
                map.flyTo({
                    center: [position.coords.longitude, position.coords.latitude],
                    zoom: 7,
                    speed: 0.3,
                    curve: 2,
                    easing(t) {
                        return t
                    }
                });
                map.parent.setState({'isLoaded': true})
            },
            function (error) {
                console.error("Error Code = " + error.code + " - " + error.message);
                // e.g. Error Code = 1 - Geolocation has been disabled in this document by Feature Policy
            }
        );

        // create the popup at the desired location
        let popup = new mapboxgl.Popup({closeButton: true, closeOnClick: false})

        // When a click event occurs on a feature in the places layer, open a popup at the
        // location of the feature, with description HTML from its properties.
        map.on('mouseenter', 'vehicles', function (event) {
            try {
                debugger
                this.getCanvas().style.cursor = 'pointer';
            } catch(e) {
                //pass
            }
            this.hoveredVehicleId = event.features[0].properties.Vehicle_ID
            debugger
            this.setFeatureState(
                {source: 'Car-locations', Vehicle_ID: this.hoveredVehicleId},
                {hover: true}
            );
            let coordinates = event.features[0].geometry.coordinates.slice();
            // let description = e.features[0].properties.description;
            let fp = event.features[0].properties
            let description =
                "<div>" +
                    "<div className='float-left clearfix'>" +
                        "<h2>" + fp.registration_number + "</h2>" +
                    "</div>" +
                    "<div>" +
                        "<div><h4>" + fp.make + '&nbsp;' + fp.model + "</h4></div>" +
                    "</div>" +
                "</div>"
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            popup.setLngLat(coordinates)
                 .setHTML(description)
                 .addTo(map);
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'vehicles', function (event) {
            try {
                debugger
                this.getCanvas().style.cursor = 'default';
                let hoveredVehicleId = event.features[0].properties.Vehicle_ID;
                if (this.hoveredVehicleId !== null) {
                    this.setFeatureState(
                        {source: 'Car-locations', id: hoveredVehicleId},
                        {hover: false}
                    );
                };
                this.hoveredVehicleId = null;
                setTimeout(
                    function () {
                        popup.remove()
                    },
                    1000);

            } catch(e) {
                console.log("Failed to leave vehicles mouseover popup")
            }

        });

        // inspect a cluster on click
        map.on('click', 'vehicle-clusters', function (e) {
            let features = map.queryRenderedFeatures(e.point, {
                layers: ['vehicle-clusters']
            });
            debugger
            let clusterId = features[0].properties.cluster_id;
            map.getSource('Car-locations').getClusterExpansionZoom(
                clusterId,
                function (err, zoom) {
                    if (err) return;
                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                }
            );
        });

        // Change it back to a pointer when it leaves.
        map.on('click', 'vehicles', function (e) {
            if (e.features.length > 1) {
                console.log('Zooming map as more ' + e.features.length + ' features were returned on click')
            //
                let result = calc_center_ll(e);
                if (result !== undefined) {
                    map.flyTo({
                        center: result.center_point,
                        // zoom: 6,
                        speed: 0.3,
                        curve: 2,
                        easing(t) {
                            return t
                        }
                    });
                setTimeout(function(){
                    map.fitBounds(result.bounds, {
                        linear: true,
                        maxZoom: 10,
                        speed: 0.1,
                        animate: true,
                        easing(t) {
                            return t
                        }
                    })},
                    2000);
                }
            //

            } else {
                show_slideout();
                debugger
                map.parent.props.updateParentHandler({vehicle_id: e.features[0].properties.Vehicle_ID})
                // map.parent.setState({
                //     vehicle_id: e.features[0].properties.vehicle_id,
                //     mapSlideOutData: e.features[0].properties
                // });
                map.parent.forceUpdate();

                console.log(e.features.length)
                console.log(e.features[0])
                console.log(e.features[0].properties.id)
            }
        });
    });

    return map;
}

export default createMap