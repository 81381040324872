import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";

// const URL = require('url'); // nodejs-builtin

class VehicleDetailsExciseDutyCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: {},
        }
    };

    // componentDidMount() {
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    // componentWillUnmount() {
    // }

    render() {
        // Always start with a spinner
        let card_content = <Spinner />
        // Once the Card data has been loaded then show the real content
        debugger

        if(this.state.vehicleDetails.hasOwnProperty('Vehicle_ID') && this.state.vehicleDetails.hasOwnProperty('Vehicle_ID')  !== "") {card_content =
            <table className="table">
                <tr>
                    <td><label>SORN Date:</label></td>
                    <td><b>{this.state.vehicleDetails['excise_duty']['sorn_date']}</b></td>
                </tr>

                <tr>
                    <td><label>V11 Reference:</label></td>
                    <td><b>{this.state.vehicleDetails['excise_duty']['v11_reminder_reference']}</b></td>
                </tr>

                <tr>
                    <td><label>VED Band (current):</label></td>
                    <td><b>{this.state.vehicleDetails['excise_duty']['ved_band_current']}</b></td>
                </tr>

                <tr>
                    <td><label>VED Band (next):</label></td>
                    <td><b>{this.state.vehicleDetails['excise_duty']['ved_band_next']}</b></td>
                </tr>

                <tr>
                    <td><label>VED Start:</label></td>
                    <td><b>{this.state.vehicleDetails['excise_duty']['ved_start_date']}</b></td>
                </tr>

                <tr>
                    <td><label>VED End:</label></td>
                    <td><b>{this.state.vehicleDetails['excise_duty']['ved_end_date']}</b></td>
                </tr>
            </table>
        }
            return (
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Excise Duty</h4>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content}
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleDetailsExciseDutyCard;