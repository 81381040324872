import Axios from "axios";
import getApiUrl from "./get_api_url";
import ParseLocation from "./parse_location";
import getAwsParameter from "../../common/functions/parameter_store_api";
import {w3cwebsocket as W3CWebSocket} from "websocket"; // Make a WebSocket client connection to received vehicleData

let wsClient = null;

function getVehicleTelemetryWs(vehicle_id, onMessageCallback) {
    try {
        // perform a check that we have a usable vehicle id variable
        if (vehicle_id === null || vehicle_id === undefined || String(vehicle_id) === '') {
            return {
                'data': null,
                'error': "vehicle_id was not valid, i.e. null, undefined or empty string"
            }
        }

        // If we have a previous connection then close it before creating a new connection for the current vehicle
        if (wsClient !== null) {
            wsClient.close()
        }

        // Create the new websocket connection for the current vehicle
        // wsClient = new W3CWebSocket('wss://iznxso6zt8.execute-api.eu-west-1.amazonaws.com/dev/?vehicle_id=' + vehicle_id);
        // wsClient = new W3CWebSocket('wss://ml0hipa65j.execute-api.eu-west-1.amazonaws.com/dev/?Vehicle_ID=' + vehicle_id);
        wsClient = new W3CWebSocket(getAwsParameter("WebsocketURL") + "?Vehicle_ID=" + vehicle_id);
        // Add socket api event handler for onopen
        wsClient.onopen = () => {
            while (wsClient.readyState !== 1) {
                setTimeout(console.log("Vehicle Telemetry Websocker connection - in progress"), 100);
            }
            console.log("Vehicle Telemetry Websocket connection - successful")
        };

        // Add socket api event handler for  onmessage
        wsClient.onmessage = (message) => {
            onMessageCallback(JSON.parse(message.data))
        }
    } catch(err) {
        console.log(err);
    }
    return wsClient
}

export default getVehicleTelemetryWs;

