import React from 'react';
import ParseLocation from "../../common/functions/parse_location";
import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";

// const URL = require('url'); // nodejs-builtin

class VehicleDetailsSensorCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: {},
            vehicleForm: {
                sensorsForm: {
                    radar: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'Radar:',
                        value: '',
                        errors: [],
                        onChange: this.updateHandler
                    },
                    battery: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'Battery:',
                        value: '',
                        errors: [],
                    },
                    camera: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'Camera:',
                        value: '',
                        errors: [],
                    },
                    speed: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'Speed:',
                        value: '',
                        errors: [],
                    },
                    steer: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'Steering Angle:',
                        value: '',
                        errors: [],
                    },
                    ultra: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'U/S Proximity:',
                        value: '',
                        errors: [],
                    },
                    imu: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'IMU:',
                        value: '',
                        errors: [],
                    },
                    lidar: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'Lidar:',
                        value: '',
                        errors: [],
                    },
                    gps: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'GPS:',
                        value: '',
                        errors: [],
                    },
                    vehicle_temp: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'Vehicle Temp.:',
                        value: '',
                        errors: [],
                    },
                    wheel_sped: {
                        elementType: 'checkbox',
                        elementConfig: {},
                        validation: {
                        },
                        label: 'Wheel Speed:',
                        value: '',
                        errors: [],
                    }
                }
            }
        }
    };

    // componentDidMount() {
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        const {objType, objId, objAction} = ParseLocation();
        // if(this.state.vehicleDetails !== prevState.vehicleDetails || objAction === 'Add') {
        if(this.state.vehicleDetails !== prevState.vehicleDetails) {
            // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
            let updateForm = { ...this.state.vehicleForm };
            Object.keys(this.state.vehicleForm).forEach(field => {
                debugger
                if (field.slice(-4) != 'Form') {
                    // root level fields
                    if (this.state.vehicleForm.hasOwnProperty(field)) {
                        let updateElement = { ...updateForm[field] };
                        updateElement.value = this.state.vehicleDetails[field];
                        updateForm[field] = { ...updateElement };
                    }

                } else {
                    // nested level fields
                    let node = field.slice(0, field.length - 4);
                    Object.keys(this.state.vehicleForm[field]).forEach(nodeField => {
                        if (this.state.vehicleDetails[node].hasOwnProperty(nodeField)) {
                            let updateElement = { ...updateForm[field][nodeField] };
                            updateElement.value = this.state.vehicleDetails[node][nodeField];
                            updateForm[field][nodeField] = { ...updateElement };
                        }
                    });
                }
            });
            this.setState({vehicleForm: { ...updateForm }})
        }
    }

    // componentWillUnmount() {
    // }

    render() {
        // Always start with a spinner
        let card_content = <Spinner />
        // Once the Card data has been loaded then show the real content
        debugger

        if(this.state.vehicleDetails.hasOwnProperty('Vehicle_ID') && this.state.vehicleDetails.hasOwnProperty('Vehicle_ID')  !== "") {card_content =
            <div className="row">
                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_lidar" active="false" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['lidar'] ? this.state.vehicleDetails['sensors']['lidar'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">Lidar:</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_camera" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['camera'] ? this.state.vehicleDetails['sensors']['camera'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">Camera:</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_wheel_speed" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['wheel_speed'] ? this.state.vehicleDetails['sensors']['wheel_speed'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">Wheel Speed:</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_proximity" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['proximity'] ? this.state.vehicleDetails['sensors']['proximity'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">Proximity:</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_imu" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['imu'] ? this.state.vehicleDetails['sensors']['imu'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">IMU:</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_gps" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['gps'] ? this.state.vehicleDetails['sensors']['gps'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">GPS:</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_battery" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['battery'] ? this.state.vehicleDetails['sensors']['battery'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">Battery (12v):</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_battery_hv" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['battery_hv'] ? this.state.vehicleDetails['sensors']['battery_hv'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">Battery (HV):</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_speed" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['speed'] ? this.state.vehicleDetails['sensors']['speed'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">Speed:</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_temperature_air" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['temperature_air'] ? this.state.vehicleDetails['sensors']['temperature_air'] : false}>
                    </input>
                    <label htmlFor="switchery"
                           className="font-medium-2 text-bold-600 ml-1">Air Temperature:</label>
                </div>

                <div className="form-group col-md-4">
                    <input type="checkbox" id="sensor_steering_angle" className="switchery" readOnly={true}
                        checked={this.state.vehicleDetails['sensors']['steering_angle'] ? this.state.vehicleDetails['sensors']['steering_angle'] : false}>
                    </input>
                    <label htmlFor="switchery" className="font-medium-2 text-bold-600 ml-1">Steering:</label>
                </div>

            </div>

        }


        return (
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Sensor Capabilities</h4>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleDetailsSensorCard;