import React from 'react';

// duplicated from Common.js
let urlCurrent = new URL(document.URL);
let params = new URLSearchParams(urlCurrent.search);
let objType ='';
let objId = '';
if (params.get('route')) {
    let pos = -1;
    let pageTypes = ['List', 'Detail', 'Edit', 'Add'];
    pageTypes.forEach(async function(pageType) {
        if (pos === -1) {
            pos = params.get('route').indexOf(pageType);
            if (pos >= 0) {
                objType = params.get('route').substr(0, pos);
                objId = params.get('current'+objType); // defaults to null if key doesn't exist
            }
        }
    });
}
let objLink;
if (params.get('route')) {
    if (objId) {
        objLink = <li className="breadcrumb-item"><a id={'bcLinkItem'}>{objId}</a></li>
    } else if (params.get('route').indexOf('Add') >= 0) {
        objLink = <li className="breadcrumb-item">{objId}</li>
    }
}

class LanguageDropdown extends React.Component {
    render() {
        return (
            <div>
                <a className="nav-link nav-link-label" id="dropdown-flag" href="#" aria-haspopup="true" aria-expanded="false"
                   data-toggle="dropdown" >
                    <i className="flag-icon flag-icon-gb"></i>
                    <span>English</span>
                    <span className="selected-language"></span>
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdown-flag">
                    <a className="dropdown-item" href="#">
                        <i className="flag-icon flag-icon-gb"></i>
                        <span>English</span>
                    </a>
                    <a className="dropdown-item" href="#">
                        <i className="flag-icon flag-icon-fr"></i>
                        <span>French</span>
                    </a>
                    <a className="dropdown-item" href="#">
                        <i className="flag-icon flag-icon-cn"></i>
                        <span>Chinese</span>
                    </a>
                    <a className="dropdown-item" href="#">
                        <i className="flag-icon flag-icon-de"></i>
                        <span>German</span>
                    </a>
                </div>
            </div>
        );
    }
}

export default LanguageDropdown;