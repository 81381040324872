import React from 'react'

import ParseLocation from "../../common/functions/parse_location";
import getVehicleLiveryData from "../../common/functions/vehicle_livery_api";
import getVehicleTelemetryWs from "../../common/functions/vehicle_telemetry_api";

import Breadcrumbs from "../../common/breadcrumbs";
import EditActionBar from "../../common/EditActionBar";
import VehicleLiveryEditColorsCard from "../cards/vehicleLiveryEditColorsCard";
import VehicleLiveryEditCameraCard from "../cards/vehicleLiveryEditCameraCard";
import Vehicle3dModelCard from "../../vehicle/cards/vehicle3dModelCard";

class VehicleLiveryEdit extends React.Component {
    constructor(props) {
        super(props);
        this.updateHandler = this.updateHandler.bind(this);
        this.update3dModelCard = React.createRef();
        this.updateCameraCard = React.createRef();
        this.updateLiveryCard = React.createRef();
        this.state = {
            vehicleTelemetry: {},
            liveryList: {},
            liveryDetail: null};
    };
    // Called by the child cards to update state across all components on page
    updateHandler(state) {
        this.setState(state);
    }

    componentDidMount() {
        const {objType, objId, objAction} = ParseLocation()
        // Get the saved livery data for this vehicle or template
        getVehicleLiveryData(objId, 'Template',
            (data) => {
                this.setState({liveryDetail: data });
            }
        )

        // Connect to the Vehicle Telemetry WebSocket API,
        try {
            getVehicleTelemetryWs(objId, (data) => {
                this.setState({vehicleTelemetry: data});
            });
        } catch (err) {
            console.log("Vehicle Telemetry Call Failed with Error: " + err);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.liveryDetail !== prevState.liveryDetail) {
            this.updateLiveryCard.current.setState({liveryDetail: this.state.liveryDetail});
            this.updateCameraCard.current.setState({liveryDetail: this.state.liveryDetail});
            this.update3dModelCard.current.setState({vehicleTelemetry: this.state.liveryDetail});
        }
    }

    componentWillUnmount() {
    }

    render() {
        let {objType, objId, objAction} = ParseLocation()
        return (
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2">
                            <span className="content-header-title">
                                <img src="app-assets/images/portrait/Sainsburys.jpg" height="30" width="auto"
                                     alt={"Vehicle icon"}
                                     className="float-left mr-1"/>
                                <h3>{objId}</h3>
                            </span>
                            <Breadcrumbs/>
                            <EditActionBar/>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <VehicleLiveryEditCameraCard {...this.props} ref={this.updateCameraCard} updateHandler={this.updateHandler}/>
                                <VehicleLiveryEditColorsCard {...this.props} ref={this.updateLiveryCard} updateHandler={this.updateHandler}/>
                            </div>

                            <div className="col-md-6 col-12">
                                <Vehicle3dModelCard {...this.props} ref={this.update3dModelCard} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VehicleLiveryEdit;