import { useState, useEffect } from "react";


// ASYNC
export default function useFetch(url) {
    const [data, setData] = useState([]);

    async function getData() {
        const response = await fetch(url);
        const data = await response.json();
        if (data.hasOwnProperty('Items')) {
            // When a list of objects is returned we have Count and Items
            setData(data.Items);
        } else {
            // When a single object is returned we have the field
            setData(data);
        }
    }

    useEffect(() => {
        getData();
    });

    return data;
}

// // SYNCHRONOUS
// export default function useFetch(url) {
//     const [data, setData] = useState([]);
//
//     useEffect(() => {
//         fetch(url)
//             .then(response => response.json())
//             .then(data => setData(data.Items));
//     }, []);
//
//     return data;
// }