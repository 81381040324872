// import React, {Component, Fragment} from 'react';
import React from 'react';
import Unity, { UnityContext } from "react-unity-webgl";
import CardHeaderControls from "../../common/cardHeaderControls";
import ParseLocation from "../../common/functions/parse_location";
import getVehicleLiveryData from "../../common/functions/vehicle_livery_api";
import getAwsParameter from "../../common/functions/parameter_store_api";
import _ from 'lodash';

window.Vehicle_Static_Data = "";

class Vehicle3dModelCard extends React.Component {
    constructor(props) {
        super(props);
        this.speed = 30;
        this.state = {
            degrees: 0,
            message: "-",
            showUnity: true,
        };

        this.unityContext = new UnityContext({
            codeUrl: "/assets/js/WebGL_T.wasm",
            frameworkUrl: "/assets/js/WebGL_T.framework.js",
            dataUrl: "/assets/js/WebGL_T.data",
            loaderUrl: "/assets/js/WebGL_T.loader.js",
            companyName: "NS Web Development",
            productName: "DarwinWebSocket",
            productVersion: "0.2",
            streamingAssetsUrl: "StreamingAssets",
        });
        // this.unityContext = new UnityContext({
        //     codeUrl: "/assets/js/WebGL_T.wasm",
        //     frameworkUrl: "/assets/js/WebGL_T.framework.js",
        //     dataUrl: "/assets/js/WebGL_T.data",
        //     loaderUrl: "/assets/js/WebGL_T.loader.js",
        //     companyName: "NS Web Development",
        //     productName: "DarwinWebSocket",
        //     productVersion: "0.2",
        //     streamingAssetsUrl: "StreamingAssets",
        // });
    }

    componentDidMount() {
        let {objType, objId, objAction} = ParseLocation();
        this.setState({'vehicle_id': objId})

        getVehicleLiveryData(objId, (data) => {
            data.S3_URL = getAwsParameter('S3_Livery_Graphics');
            data.S3_AUTH = getAwsParameter('AUTH');
            window.Vehicle_Static_Data = JSON.stringify(data);
            this.forceUpdate();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // TODO - Update to check timestamp
        debugger
        if (this.state.vehicleTelemetry !== prevState.vehicleTelemetry) {
            let data = _.clone(this.state.vehicleTelemetry); // avoid causing a state change
            data.S3_URL = getAwsParameter('S3_Livery_Graphics');
            data.S3_AUTH = getAwsParameter('AUTH');
            window.Vehicle_Static_Data = JSON.stringify(this.state.vehicleTelemetry);
        }
    }

    componentWillUnmount() {
        window.Vehicle_Static_Data = "";
    }

    render() {
        return (
            <div className="card">
                {/*Card Header*/}
                <div className="card-header">
                    <h4 className="card-title">Live 3D Model</h4>
                    <CardHeaderControls/>
                </div>

                {/* Card Content */}
                <div className="card-content">
                    <div className="card-body">
                        <div className="overflow-auto">
                            Data: { window.Vehicle_Static_Data }
                        </div>

                        <div className="row">
                            <Unity width={"100%"} unityContext={this.unityContext} />
                        </div>
                   </div>
                </div>
            </div>
        );
    }
}

export default Vehicle3dModelCard;
