import Axios from "axios";
import getApiUrl from "./get_api_url";

function getVehicleDetails(vehicle_id, callback) {

    // perform a check that we have a usable vehicle id variable
   if (vehicle_id === null || vehicle_id === undefined || String(vehicle_id) === '') {
       return {
           'data': null,
           'error': "vehicle_id was not valid, i.e. null, undefined or empty string"
       }
   }

    // Call the vehicle WebService
    Axios.get(getApiUrl('vehicle', [['Vehicle_ID', vehicle_id]]))
        .then(response => {
            // Handle a good response
            if (response.data.Vehicle_ID === vehicle_id) {
                console.log("wsClient received vehicle details for selected vehicle.")
                callback(response.data)

            // Handle an empty response (shouldn't be happening)
            } else {
                let err_msg = "Vehicle details webservice returned data for the wrong vehicle"
                console.log(err_msg)
                return {
                    'data': null,
                    'error': err_msg
                }
            }

        })
        .catch(error => {
            if (error) {
                console.log(error)
                return {
                    'data': null,
                    'error': "An error was encountered calling the vehicle details webservice: + error"
                }
            }
        });
}
// TODO - Add Axios post method that accepts the data
export default getVehicleDetails;
