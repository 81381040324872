function ParseLocation() {
    let objType = "";
    let objId = "";
    let objAction = "";

    let urlCurrent = new URL(document.URL);
    let urlParts = urlCurrent.pathname.split('/')
    let offset = urlParts.indexOf('app') === -1 ? 0: 2;
    // let offset = urlParts.includes('app') ? 0: 1;  NOTE: the array includes method isn't supported by MS-Edge < v13

    if (urlParts.length > 1 + offset) {
        objType = urlParts[1 + offset].slice(-1) !== 's' ? urlParts[1 + offset] : urlParts[1 + offset].slice(0, -1);
        // replace index.html found in production build
        objType = objType !== 'index.html' ? objType : '';
    }
    if (urlParts.length > 2 + offset) {
        objAction = urlParts[2 + offset];
    }

    if (urlParts.length > 3 + offset) {
        objId = urlParts[3 + offset];
    }
    return {objType, objId, objAction}
}

export default ParseLocation;