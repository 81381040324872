import React from 'react';

import Breadcrumbs from "../../common/breadcrumbs";
import CustomerListCard from "../cards/customerListCard";

class CustomerList extends React.Component {
    render() {
        return (
            <div className="app-content content">
                <div className="content-wrapper">
                    {/* PAGE CONTENT HEADER */}
                    <div className="content-header row">
                        <div className={"content-header-left col-md-12 col-12"}>
                            <h2 className={"content-header-title"}>Customers</h2>
                        </div>
                        <div className="content-header row">
                            <div className={"content-header-left col-md-12 col-12 mb-1"}>
                                <Breadcrumbs {...this.props}/>
                            </div>
                        </div>
                    </div>
                    {/* PAGE CONTENT BODY */}
                    <div className="content-body col-md-12 col-12">
                        <div className={"container col-md-12 col-12"}>
                            {/*<div className={"row col-12"}>*/}
                            {/*    <div className={"col col-md-12 col-12 content"}>*/}
                            {/*        <TableActionBar {...this.props}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={"row col-12"}>
                                <div className={"col col-md-12 col-12 content"}>
                                    <CustomerListCard/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}




export default CustomerList;