import React from 'react';
import Axios from "axios";

import Spinner from "../../UI/Spinner/Spinner";
import getApiUrl from "../../common/functions/get_api_url";
// import CardHeaderControls from "../../common/cardHeaderControls";
// import TelemetryBatteryLevel from "../parts/telemetryBatteryLevel";
import TelemetrySpeed from '../parts/telemetrySpeed';
// import Telementrysteer from '../parts/telementryStreering';
import getPreSignedUrl from "../../common/functions/get_presigned_url";

// Make a WebSocket client connection to received vehicleData
import {w3cwebsocket as W3CWebSocket} from "websocket";
import {Link} from "react-router-dom";
// import TelemetrySteering from '../parts/telementryStreering';

let wsClient = null;
let wsClientVehicleId = null;

//W3CWebSocket(url, protocols, origin, headers, requestOptions, clientConfig)

class VehicleSlideoutCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: {},
            vehicleTelemetry: {},
            Customer_ID: 'slideout-card-initial',
            Vehicle_ID: ''
        };
        this.updatePageState = React.createRef();
    };

    getVehicleTelemetry(vehicle_id) {

        if (vehicle_id !== undefined && vehicle_id !== '' && vehicle_id !== wsClientVehicleId) {
            // If we have a previous connection then close it before creating a new connection for the current vehicle
            if (wsClient !== null) {
                wsClient.close()
            }

            // Create the new websocket connection for the current vehicle
            wsClient = new W3CWebSocket('wss://iznxso6zt8.execute-api.eu-west-1.amazonaws.com/dev/?vehicle_id=' + vehicle_id);

            // Add socket api event handler for onopen
            wsClient.onopen = () => {
                while (wsClient.readyState !== 1) {
                    setTimeout(console.log("Waiting for connection to be open"), 100);
                }
            };

            // Add socket api event handler for  onmessage
            wsClient.onmessage = (message) => {
                if (message.data !== "") {
                    // Create a javascript object from the json string
                    const data = JSON.parse(message.data)

                    // Create a copy of the existing telemetry for us to update
                    let update = this.state.vehicleTelemetry;
                    // Update each element in turn
                    for (const [key, value] of Object.entries(data)) {
                        update[key] = value
                    }
                    // Update the state with the result of the updates
                    this.setState({vehicleTelemetry: update})

                } else {
                    console.log("Socket message with no data received.");
                }
            };

            // Update the var used to store the vehicle id for comparison.
            wsClientVehicleId = vehicle_id
        }
    }

    getVehicleData(vehicle_id) {
        if (vehicle_id !== undefined && vehicle_id !== '' && vehicle_id !== wsClientVehicleId) {
            if ((JSON.stringify(this.state.vehicleDetails) === "{}") || (this.state.vehicleDetails['Vehicle_ID'] !== vehicle_id)) {

                // Call the vehicle WebService
                Axios.get(getApiUrl('vehicle', [['Vehicle_ID', this.state['vehicle_id']]]))
                    .then(response => {
                        // Handle a good response
                        if (response.data.Vehicle_ID === this.state.vehicle_id) {
                            console.log("wsClient received data for selected vehicle, updating state.")
                            this.setState({vehicleDetails: response.data});

                            // Handle an empty response (shouldn't be happening)
                        } else {
                            console.log("wsClient received data for other vehicle, ignoring.")
                        }

                    })
                    .catch(error => {
                        if (error) {
                            console.log(error)
                        }
                    });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let vehicle_id = this.state['vehicle_id'];
        if (vehicle_id !== undefined) {
            this.getVehicleData(vehicle_id);
            this.getVehicleTelemetry(vehicle_id);
            if (this.updatePageState.hasOwnProperty('current') && this.updatePageState.current !== null) {
                this.updatePageState.current.setState(this.state)
            }
            debugger
            if (JSON.stringify(this.state.vehicleDetails) !== '{}') {
                if(this.state.vehicleDetails['graphic'] !== '') {
                    getPreSignedUrl('slideoutVehicleImage', this.state.vehicleDetails['graphic'], 'darwin-vehicle-graphics')
                } else {
                    getPreSignedUrl('slideoutVehicleImage', 'NotFound.gif', 'darwin-vehicle-graphics')
                }
            }
        }
    }

    componentDidMount() {
        let vehicle_id = this.state['vehicle_id'];
        if (vehicle_id !== undefined) {
            this.getVehicleData(vehicle_id);
            this.getVehicleTelemetry(vehicle_id);
            if (this.updatePageState.hasOwnProperty('current') && this.updatePageState.current !== null) {
                this.updatePageState.current.setState(this.state)
            }
        }
    }

    render() {
        function hide_slideout() {
            let slideout = document.getElementById('vehicle-slideout');
            if (slideout.classList.contains('slideout-show')) {
                slideout.classList.remove('slideout-show');
                slideout.classList.add('slideout-hide');
            }
        }

        let content;
        let customer = <div id="owning_customer">TODO - API Call to get current customer</div>
        // if (this.state.vehicleDetails['Customer_ID'] !== undefined) {
        //     customer =
        //         <div id="owning_customer">
        //             if ({this.state.customerDetails['graphic']} !== undefined) {
        //             <div id={"customer_logo"}>
        //                 <img src={this.state.customerDetails['graphic']} alt={"Customer logo"}/>
        //             </div>
        //         } else {
        //             <div id={"customer_logo"}></div>
        //         }
        //             <div>this.state.customerDetails['customer_name']</div>
        //         </div>
        // }


        // conditions for show and hide (if values not exist) start
        let locationLatLng = '';
        let wheelSpeeds = '';
        let driverPresent = '';
        let gearSelection = '';
        let brakePedal = '';
        let accelPedal = '';
        let proximity1_4 = '';
        let proximity5_8 = '';
        let lights = '';
        let blinkers = '';
        let horn = '';
        let siren = '';
        let wiper = '';
        let proximity = '';
        let statusIcon = '';
        let battery = '';

        // On/Off condition
        if (JSON.stringify(this.state.vehicleDetails) === "{}") {
            statusIcon = <span className="dot"></span>
        } else if (this.state.vehicleTelemetry['RSTVehicleState'] !== undefined) {
            // Vehicle State: [(7, 'unavailable'), (6, 'reserved'), (5, 'Going To Sleep'), (4, 'Traction'), (3, 'Charging'),
            // (2, 'Waking Up'), (1, 'Technical Wake Up'), (0, 'Sleeping')]
            if (this.state.vehicleTelemetry['RSTVehicleState'] === 'Traction') {
                statusIcon = <span className="avatar avatar-online"><i></i></span>
            }

        }
        let onOffState =
            <div className="row">
                <div className="col-md-12 bold">
                    <table className="table borderless" border="0">
                        <tr>
                            <td><label>Car On / Off</label></td>
                            <td> {statusIcon}</td>
                        </tr>
                    </table>

                </div>
            </div>

        if (this.state.vehicleTelemetry['LATITUDE'] || this.state.vehicleTelemetry['LONGITUDE']) {
            locationLatLng =
                <div className="row">
                    <div className="col-md-6" id="dev-lat">
                        <label>Location:</label>
                    </div>
                    <div className="col-md-6 bold">
                       (LAT) {this.state.vehicleTelemetry['LATITUDE']}, (LONG){this.state.vehicleTelemetry['LONGITUDE']}
                    </div>
                </div>
        }

        if(this.state.vehicleTelemetry['BatteryVoltage'] <= 11.31) {
            battery = <img src="/assets/img/battery4.jpg" width="25%" alt="low" />
        } else if(this.state.vehicleTelemetry['BatteryVoltage'] <= 11.75) {
            battery = <img src="/assets/img/battery3.jpg" width="25%" alt="medium" />
        } else if(this.state.vehicleTelemetry['BatteryVoltage'] <= 12.32) {
            battery = <img src="/assets/img/battery2.jpg" width="25%" alt="70%" />
        } else if(this.state.vehicleTelemetry['BatteryVoltage'] > 12.32) {
            battery = <img src="/assets/img/battery1.jpg" width="25%" alt="high" />
        }

        if (this.state.vehicleTelemetry['FL_WheelSpeed_Rpm'] !== undefined ||
            this.state.vehicleTelemetry['FR_WheelSpeed_Rpm'] !== undefined ||
            this.state.vehicleTelemetry['RL_WheelSpeed_Rpm'] !== undefined ||
            this.state.vehicleTelemetry['RR_WheelSpeed_Rpm'] !== undefined) {
            wheelSpeeds =

            <div className="row">
                <div className="col-xl-12">
                    <table className="table-sm borderless">
                        <tr>
                            <th colSpan={2}>Wheel Speeds (rpm)</th>
                            <th></th>
                        </tr>

                        <tr>
                            <td>FL</td>
                            <td className="bold">{Math.round(this.state.vehicleTelemetry['FL_WheelSpeed_Rpm'])}</td>
                            <td>FR</td>
                            <td className="bold">{Math.round(this.state.vehicleTelemetry['FR_WheelSpeed_Rpm'])}</td>
                        </tr>

                        <tr>
                            <td>RL</td>
                            <td className="bold">{Math.round(this.state.vehicleTelemetry['RL_WheelSpeed_Rpm'])}</td>
                            <td>RR</td>
                            <td className="bold">{Math.round(this.state.vehicleTelemetry['RR_WheelSpeed_Rpm'])}</td>
                        </tr>
                    </table>
                </div>
            </div>
        }

        if (this.state.vehicleTelemetry['RST_DriverDetectionOnSeat'] !== undefined) {
            // Possible string values are:-
            //[(3, 'Sensor Open Circuit'), (2, 'Sensor Short Circuit'), (1, 'Driver not detected on seat'), (0, 'Driver detected on seat')]
            driverPresent =
                <div>
                    <div className="col-md-6">
                        <label>Driver Seated:</label>
                    </div>
                    <div className="col-md-6 bold">
                        // TODO - format the string values as icon images
                        // TODO - I imagine a silhouetted driver or solid driver on sean graphic
                        {this.state.vehicleTelemetry['RST_DriverDetectionOnSeat']}
                    </div>
                </div>
        }

        // Conditional content for GEAR
        if (this.state.vehicleTelemetry['RST_Drive_TelltaleState'] !== undefined ||
            this.state.vehicleTelemetry['RST_Reverse_TelltaleState'] !== undefined ||
            this.state.vehicleTelemetry['RST_Neutral_TelltaleState'] !== undefined) {

            // Keeping it simple we need to derive a gear selection from the 3 properties, each of which
            // may have one of the following states: [(0, 'D telltale switched off'), (1, 'D telltale blinking'), (2, 'D telltale switched on'), (3, 'unavailable')])
            // the decoded data contains the string values.
            let derivedGear = 'N'
            if (this.state.vehicleTelemetry['RST_Drive_TelltaleState'].indexOf('switched on') > 0) {
                derivedGear = 'D'
            } else if (this.state.vehicleTelemetry['RST_Reverse_TelltaleState'].indexOf('switched on') > 0) {
                derivedGear = 'R'
            }
            gearSelection =
                <div>
                    <div className="col-md-6">
                        <label>Gear</label>
                    </div>
                    <div className="col-md-6 bold">
                        {derivedGear}
                    </div>
                </div>
        }

        if (this.state.vehicleTelemetry['Pedal_Actual'] != undefined) {
            accelPedal =
                <div>
                    <div className="col-md-6">
                        <label>Accelerator</label>
                    </div>
                    <div className="col-md-10 bold">
                        { Math.round(this.state.vehicleTelemetry['Pedal_Actual']) }%
                    </div>
                </div>
        }

        if (this.state.vehicleTelemetry['Brake_Actual'] !== undefined) {
            brakePedal =
                <div>
                    <div className="col-md-6">
                        <label>Brake</label>
                    </div>
                    <div className="col-md-10 bold">
                        { this.state.vehicleTelemetry['Brake_Actual'] }%
                    </div>
                </div>
        }

        if (this.state.vehicleTelemetry['Sensor_1'] !== undefined ||
            this.state.vehicleTelemetry['Sensor_2'] !== undefined ||
            this.state.vehicleTelemetry['Sensor_3'] !== undefined ||
            this.state.vehicleTelemetry['Sensor_4'] !== undefined) {
            proximity1_4 =
                <tr>
                    <td>1:</td>
                    <td className="bold">{this.state.vehicleTelemetry['Sensor_1']}</td>

                    <td>2:</td>
                    <td className="bold">{this.state.vehicleTelemetry['Sensor_2']}</td>

                    <td>3:</td>
                    <td className="bold">{this.state.vehicleTelemetry['Sensor_3']}</td>

                    <td>4:</td>
                    <td className="bold">{this.state.vehicleTelemetry['Sensor_4']}</td>
                </tr>
        }

        if (this.state.vehicleTelemetry['Sensor_5'] !== undefined ||
            this.state.vehicleTelemetry['Sensor_6'] !== undefined ||
            this.state.vehicleTelemetry['Sensor_7'] !== undefined ||
            this.state.vehicleTelemetry['Sensor_8'] !== undefined) {
            proximity5_8 =
                <tr>
                    <td>5:</td>
                    <td className="bold">{this.state.vehicleTelemetry['Sensor_5']}</td>

                    <td>6:</td>
                    <td className="bold">{this.state.vehicleTelemetry['Sensor_6']}</td>

                    <td>7:</td>
                    <td className="bold">{this.state.vehicleTelemetry['Sensor_7']}</td>

                    <td>8:</td>
                    <td className="bold">{this.state.vehicleTelemetry['Sensor_8']}</td>
                </tr>
        }
        if ((proximity1_4 !== '') || (proximity5_8 !== '')) {
            proximity =
                <div className="col-md-12">
                    <table>
                        <th>
                            <tr>
                                <td colSpan={8}>Proximity (cm to object)</td>
                            </tr>
                        </th>
                        <tbody>
                        {proximity1_4}
                        {proximity5_8}
                        </tbody>
                    </table>
                </div>
        }

        if (this.state.vehicleTelemetry['Dipped_Lights_B'] !== undefined ||
            this.state.vehicleTelemetry['Main_Beam_B'] !== undefined ||
            this.state.vehicleTelemetry['Side_Lights_B'] !== undefined) {
            let lightChoice = 'Side';
            if (this.state.vehicleTelemetry['Dipped_Lights_B'] === 1) {
                lightChoice = 'Dip'
            } else if (this.state.vehicleTelemetry['Main_Bean_B']) {
                lightChoice = 'High'
            }

            lights =
                <div>
                    <div className="col-md-6">
                        <label>Lights</label>
                    </div>
                    <div className="col-md-10 bold">
                       {lightChoice}
                    </div>
                </div>
        }

        if (this.state.vehicleTelemetry['Right_Indicator_B'] !== undefined ||
            this.state.vehicleTelemetry['Left_Indicator_B'] !== undefined) {
            let blinkerChoice = 'off';
            if (this.state.vehicleTelemetry['Right_Indicator_B'] === 1) {
                blinkerChoice = 'Right'
            } else if (this.state.vehicleTelemetry['Left_Indicator_B'] === 1) {
                blinkerChoice = 'Left'
            }
            blinkers =
                <div>
                    <div className="col-md-6">
                        <label>Indicators:</label>
                    </div>
                    <div className="col-md-6 bold">
                        {blinkerChoice}
                    </div>
                </div>
        }

        if (this.state.vehicleTelemetry['Horn_B'] !== undefined) {
            horn =
                <div>
                    <div className="col-6">
                        <label>Horn</label>
                    </div>
                    <div className="col-10 bold">
                        {this.state.vehicleTelemetry['Horn_B'] === 1 ? 'Beep beep' : ''}
                    </div>
                </div>
        }

        // if (this.state.vehicleTelemetry['siren'] != undefined) {
        //     siren = <label>Siren</label>
        // }

        // if (this.state.vehicleTelemetry['wiper'] != undefined) {
        //     wiper = <label>Windscreen Wipers</label>
        // }

        if (this.state.vehicleDetails['Vehicle_ID'] !== undefined) {
            debugger
            content =
                <div className={"vehicle-slideout slideout-fixed vehicle-slideout-content col-md-12"}>

                    <div id='btnCloseSlideout'>
                        <a onClick={hide_slideout}>
                            <i className="ft-x"></i>
                        </a>
                    </div>
                    <div className="row">

                        <div id='reg_num' className="float-left" className="col-md-12">
                            <div className={"float-left mr-2"}>
                                <strong>{this.state.vehicleDetails['registration_number']}</strong>
                            </div>
                        </div>
                        <div id='make_model' className="float-right" className="col-md-12">
                            <strong>
                                <span className={"mr-1"}>{this.state.vehicleDetails['make']}</span>
                                <span className={"mr-1"}>{this.state.vehicleDetails['model']}</span>
                            </strong>
                        </div>

                        <div className="col-md-12"><p></p></div>
                        <div className="col-md-12">
                            <Link to={'Vehicle/Data/' + this.state.vehicle_id} className={'threeD'}>
                                <img src="/assets/img/3d icon.png" width="50%" alt="Icon link to telemetry" />
                            </Link>
                            <img src="" id={"slideoutVehicleImage"} alt="Vehicle icon" width="90%"/>
                        </div>

                        <div className="col-md-12">{customer}</div>

                        <div className="col-md-12"><p></p></div>

                        { onOffState }

                        <div className="col-md-6 guage bold">
                            <center><label>Speed</label></center>
                                <TelemetrySpeed ref={this.updatePageState}/>
                        </div>

                        <div className="col-md-6 guage bold">
                            <center><label>Battery</label>
                            <p></p>
                        {battery} </center>
                        </div>


                       {/* <div className="col-md-6">

                            <label>Steering</label>

                            <TelemetrySteering />

                        </div>*/}
                    </div>

                    <div className="row ">
                        {locationLatLng}
                    </div>

                    {wheelSpeeds}
                    <div className="row ">
                        {driverPresent}
                        {gearSelection}
                        {accelPedal}
                        {brakePedal}
                    </div>

                    <div className="row ">
                        {proximity}
                    </div>

                    <div className="row ">
                        {lights}
                        {blinkers}
                        {horn}
                    </div>

                    <footer>
                        <div className="col-md-12"></div>
                    </footer>
                </div>

        } else {
            content = <Spinner/>
        }

        return (
            // CARD CONTAINER - This one isn't really a card
            // <div className="vehicle-slideout-content slideout-hide ps-container ps-theme-dark">
            <div className="ps-container ps-theme-dark">
                <div className="card ">
                    {/* CARD CONTENT HEADER */}
                    <div className="card-header">
                        <h4 className="card-title">{"Vehicle Id: " + this.state['vehicle_id']}</h4>
                    </div>

                    {/* CARD CONTENT BODY */}
                    <div className="card-content show">
                        <div className="card-body">
                            content
                            { content }
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default VehicleSlideoutCard;
