import React from 'react';


import CardHeaderControls from "../../common/cardHeaderControls";
import VehicleListTable2 from "../parts/vehicleListTable2";

class DashboardVehicleListCard extends React.Component {
    constructor(props) {
        super(props);
        // this.childStateHandler = this.childStateHandler.bind(this)
        this.state = {
            Customer_ID: '', // customer
            Vehicle_ID: '', // vehicle id, set when map feature is clicked on
            vehicleDetails: '', // Details from the vehicles table
            vehicleTelemetry: '', // Telemetry -> Last Known Location
        }
    };
    // childStateHandler() {
    //     this.setState({
    //         someVar: 'some value'
    //     })
    // }
    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    render() {
        return (
            // CARD CONTAINER
            <div className="card overflow-auto ">

                {/* CARD CONTENT HEADER */}
                <div className="card-header">
                    <h4 className="card-title">Fleet Vehicles</h4>
                    <CardHeaderControls/>
                </div>

                {/* CARD CONTENT BODY */}
                <div className="card-content show">
                    <div className="card-body">
                        <VehicleListTable2 {...this.props}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardVehicleListCard;