import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";
import ParseLocation from "../../common/functions/parse_location";
import checkValidity from "../../common/functions/check_validity";
import Input from "../../UI/Input/Input"

const {objType, objId, objAction} = ParseLocation()

// const panelDefinition = [
//     {'name': 'Front number plate', 'color': 'Color11', 'image': 'Image11'},
//     {'name': 'Windscreen bottom', 'color': 'Color08', 'image': 'Image08'},
//     {'name': 'Windscreen top', 'color': 'Color07', 'image': 'Image07'},
//     {'name': 'Front Panel', 'color': 'Color09', 'image': 'Image09'},
//     {'name': 'Side Mirrors', 'color': 'Color10', 'image': ''},
//     {'name': 'Left door', 'color': '', 'image': 'Image03L'},
//     {'name': 'Right door', 'color': '', 'image': 'Image03R'},
//     {'name': 'Doors - lower edge', 'color': 'Color05', 'image': ''},
//     {'name': 'Doors - top panel', 'color': 'Color03', 'image': ''},
//     {'name': 'Main body', 'color': 'Color06', 'image': ''},
//     {'name': 'Left quarter panel', 'color': '', 'image': 'Image02L'},
//     {'name': 'Right quarter panel', 'color': '', 'image': 'Image02R'},
//     {'name': 'Back door', 'color': 'Color01', 'image': 'Image01'},
//     {'name': 'Rear number plate', 'color': 'Color12', 'image': 'Image12'}
// ]

class VehicleLiveryEditColorsCard extends React.Component {
    constructor(props) {
        super(props);
        this.updateHandler = this.updateHandler.bind(this)
        this.state = {
            vehicleDetail: null,
            liveryDetail: null,
            formIsValid: true,
            loading: true,
            liveryForm: {
                Vehicle_ID: {
                    elementType: 'hidden',
                    elementConfig: {
                        type: 'hidden',
                    },
                    label: 'Livery ID:',
                    value: '',
                    valid: true,
                    errors: [],
                },
                Color01: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Back door:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Color03: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Doors - top panel:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Color05: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Right quarter panel:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Color06: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Main body:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Color07: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Windscreen top:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Color09: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Front panel:',
                    value: '',
                    valid: true,
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    errors: [],
                },
                Color10: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Side mirrors:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Color11: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Front number plate:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Color12: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Rear number plate:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Color13: {
                    elementType: 'colorpicker1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'DEMO:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Image01: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Back door:',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
                Image02L: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Left door upper panel:',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
                Image02R: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Right door upper panel:',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
                Image03L: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Left quarter panel:',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
                Image03R: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Right quarter panel:',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
                Image07: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Windscreen top:',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
                Image08: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Windscreen bottom (8):',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
                Image09: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Front panel (9):',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
                Image11: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Front number plate (11):',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
                Image12: {
                    elementType: 'fileupload1',
                    elementConfig: {
                        changed: this.updateHandler
                    },
                    label: 'Rear number plate (12):',
                    value: '',
                    folder: objId,
                    validation: {
                        required: false,
                    },
                    valid: true,
                    errors: [],
                },
            }
        }
    }

    updateHandler = (fieldName, value) => {
        // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
        let updatedForm = { ...this.state.liveryForm };
        let updatedFormElement = {...updatedForm[fieldName]};
        updatedFormElement.value = value;
        checkValidity(updatedFormElement);
        updatedFormElement.touched = true;
        updatedForm[fieldName] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({liveryForm: updatedForm });
    }


    // componentDidMount() {
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.liveryDetail !== prevState.liveryDetail) {
            // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
            let updateForm = { ...this.state.liveryForm };
            Object.keys(this.state.liveryDetail).forEach(field => {
                if (this.state.liveryForm.hasOwnProperty(field)) {
                    let updateElement = { ...updateForm[field] };
                    updateElement.value = this.state.liveryDetail[field];
                    updateForm[field] = { ...updateElement };
                }
            });
            this.setState({liveryForm: { ...updateForm }})
        }
    }

    // componentWillUnmount() {
    // }

    render() {
        // const {objType, objId, objAction} = ParseLocation();

        // Always start with a spinner
        let card_content = <Spinner />

        // Once the Card data has been loaded then show the real content
        // if (this.state.liveryDetail !== null) {
        //     card_content = processRows(this.state.liveryDetail)
        // }
        if (this.state.liveryDetail !== null && this.state.liveryDetail.Vehicle_ID === this.state.liveryForm.Vehicle_ID.value) {
            card_content = []
            Object.keys(this.state.liveryForm).forEach((key, index) => {
                let input_content = <Input {...this.state.liveryForm[key]} field={key} />
                card_content.push(input_content);
            })
        }

        return (
            <div className="card">
                <div className="card-header">
                    {/*<div><h4 className="card-title">Registration</h4></div>*/}
                    <div>Panel Colours & Images - Edit</div>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleLiveryEditColorsCard;