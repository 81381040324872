import React, {Component, Fragment} from "react";
// import React, {useMemo, useState, useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { instanceOf } from 'prop-types';
import { Cookies } from "react-cookie";
import './index.css'
import './App.css'

// import { LastLocationProvider } from 'react-router-last-location'
// import HeaderView from "./components/common/debug";

// import logo from './logo.svg';
// import 'App.css';

// Common Elements on most pages
import Footer from './components/common/footer';
import Header from './components/common/header/header';
import Menu from './components/common/menu';
// import MenuItem from './components/menu';

// Landing Page
import Dashboard from './components/dashboard/pages/dashboard';

// Customer Related Pages
import CustomerAdd from './components/customer/pages/customerAdd';
import CustomerDetail from "./components/customer/pages/customerDetail";
import CustomerEdit from './components/customer/pages/customerEdit';
import CustomerList from "./components/customer/pages/customerList";
// import CustomerData from "./components/customer/pages/customerData";

// Vehicle Related Pages
import VehicleAdd from './components/vehicle/pages/vehicleAdd';
import VehicleDetail from './components/vehicle/pages/vehicleDetail';
import VehicleData from './components/vehicle/pages/vehicleData';
import VehicleEdit from './components/vehicle/pages/vehicleEdit';
import VehicleList from './components/vehicle/pages/vehicleList';

import PageNotFound from "./components/common/pageNotFound";
import UnderConstruction from "./components/common/underConstruction";
// import Register from './components/Register';
import VehicleSlideOut from "./components/telemetryView/parts/vehicleSlideOut";

// Vehicle Livery Pages
import VehicleLiveryAdd from './components/vehicleLivery/pages/vehicleLiveryAdd';
import VehicleLiveryDetail from './components/vehicleLivery/pages/vehicleLiveryDetail';
import VehicleLiveryEdit from './components/vehicleLivery/pages/vehicleLiveryEdit';
import VehicleLiveryList from "./components/vehicleLivery/pages/vehicleLiveryList";
export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});
const urlCurrent = new URL(document.URL);
const params = new URLSearchParams(urlCurrent.search);

class DebugRouter extends Router {
  constructor(props){
    super();
    console.log('initial history is: ', JSON.stringify(this.history, null,2))
    this.history.listen((location, action)=>{
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}
class App extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        // Always call super(props) in a constructor
        super(props);

        // TOP Level State, used to ripple down selections to menu, slide-out's etc
        this.state = {
            auth: {},
            Customer_ID: '' ,
            Vehicle_ID: ''
        };
        if (params) {
            this.state.auth = {
                access_token: params.get('access_token'),
                id_token: params.get('id_token'),
            }
        }

        // Bind the update method to this component so that 'this' can be referenced
        this.updateTopLevelState = this.updateTopLevelState.bind(this);
        this.updateLocalState = this.updateLocalState.bind(this);

        // Create a reference that is passed to child components to enable their state to be updated.
        this.updatePageState=React.createRef()
        this.updateSliderState=React.createRef()
    }
    updateLocalState(state) {
        this.setState(state)
    }

    updateTopLevelState(state) {
        console.log("App's Top Level State updated")
        this.setState(state);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("App Did Update, updating children")
        this.updateSliderState.current.setState(this.state)
        this.updatePageState.current.setState(this.state)
    }
    componentDidMount() {
        console.log("App Did Mount, updating children")
        this.updateSliderState.current.setState(this.state);

        // When page loading, for first time I think, there is no current state
        if (this.updateLocalState['current'] !== undefined) {
            this.updatePageState.current.setState(this.state);
        }
    }

    render() {
        // Determine the routing base name for local dev / production server
        // let base_name = document.URL.indexOf('localhost') >= 1 ? "." : "/app/private"
        let base_name = ".";

        return (
            <div className="App">
                <DebugRouter basename={ base_name }>
                    <div className="app-content content">
                        <div className="content-wrapper">
                            {/*<HeaderView /> /!* FOR DEBUGGING PATHS*!/*/}

                            <Header/>
                            <Menu/>

                            {/*/!*State Communication debugging - 5 lines, comment out when happy*!/*/}
                            {/*<div className={"float-right"}>*/}
                            {/*    <div>Customer ID: {this.state.Customer_ID}</div>*/}
                            {/*    <div>Vehicle ID: {this.state.vehicle_id}</div>*/}
                            {/*    <button onClick={() => this.updateLocalState({Customer_ID: 'App-cid'})} > App - Set Customer </button>*/}
                            {/*</div>*/}

                            <VehicleSlideOut { ...this.props } ref={this.updateSliderState} updateParentHandler={this.updateTopLevelState} />
                            {/*<LastLocationProvider>*/}
                            <Switch>
                                {/* HOME PAGES */}
                                <Route path='/' exact render={() => <Dashboard ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} /> } />
                                <Route path='/index.html'  render={() => <Dashboard ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} /> } />
                                <Route path='/dashboard' render={() => <Dashboard ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} /> } />

                                {/*/!* CUSTOMER PAGES *!/*/}
                                {/*<Route path={'${process.env.PUBLIC_URL}/Customers'} component={CustomerList} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />*/}
                                <Route path='/Customers' component={CustomerList} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/Customer/List' component={CustomerList} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/Customer/Add' component={CustomerAdd} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/Customer/Detail' component={CustomerDetail} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/Customer/Edit' component={CustomerEdit} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />

                                {/* VEHICLE PAGES */}
                                <Route path='/Vehicles' exact component={VehicleList} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/Vehicle/List' component={VehicleList} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/Vehicle/Data' component={VehicleData} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/Vehicle/Add' component={VehicleAdd} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/Vehicle/Detail' component={VehicleDetail} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/Vehicle/Edit' component={VehicleEdit} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                {/*<Route path='/Vehicle/Telemetry' component={VehicleTelemetry} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />*/}

                                {/* VEHICLE LIVERY PAGES */}
                                <Route path='/VehicleLivery' exact component={VehicleLiveryList} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/VehicleLiverys' component={VehicleLiveryList} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/VehicleLiveries' component={VehicleLiveryList} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/VehicleLivery/List' component={VehicleLiveryList} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/VehicleLivery/Add' component={VehicleLiveryAdd} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/VehicleLivery/Detail' component={VehicleLiveryDetail} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />
                                <Route path='/VehicleLivery/Edit' component={VehicleLiveryEdit} ref={this.updatePageState} updateParentHandler={this.updateTopLevelState} />

                                {/* PAGES AWAITING DEVELOPMENT */}
                                <Route path='/Reports' component={UnderConstruction}/>

                                {/* ERROR PAGES */}
                                <Route path="*" status={404} component={PageNotFound}/>
                            </Switch>
                            {/*</LastLocationProvider>*/}
                            <Footer/>
                        </div>
                    </div>
                </DebugRouter>
            </div>
        );

    }
}

export default App;
