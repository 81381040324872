// import React, { useState, useEffect } from "react";
import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
import TableActionBar from "../../common/tableActionBar";
// import TableSearchBar from "../../common/tableSearchBar";

import useFetch from "../../common/useFetch";
import getApiUrl from "../../common/functions/get_api_url";
import ParseLocation from "../../common/functions/parse_location";
import addRowLinkUrl from "../../common/functions/add_row_link_url";
import getPreSignedUrl from "../../common/functions/get_presigned_url";


function VehicleListTable() {

    let {objType, objId, objAction} = ParseLocation();
    let data = useFetch(getApiUrl(objType));

    if (data.length > 0) {
        data = data.map(row => addRowLinkUrl(row, 'Vehicle_ID'));
        data.map(row => getPreSignedUrl(
            'img_' + row.Vehicle_ID,
            row.graphic,
            'darwin-vehicle-graphics'))
    }
    const columns = React.useMemo(
        () => [
            {
                Header: 'Vehicles',
                columns: [
                    { Header: <input type="checkbox" className="input-chk" id="chkAll" name="select_cb"/>,
                        accessor: 'row_select', Cell: ({ row }) => (
                            <input type="checkbox" className="input-chk" name="row_select_cb"></input>)
                    },
                    { Header: '', accessor: 'graphic',  Cell: ({ row }) => (
                            <Link to={ row.original.target_url }>
                                <img src="" id={"img_" + row.original.Vehicle_ID}
                                     height="30" width="auto"
                                     alt="Vehicle icon" />
                            </Link>)},
                    { Header: 'Registration', accessor: 'registration_number', Cell: ({ row }) => (
                            <Link to={ row.original.target_url }>{row.original.registration_number}</Link>),
                    },
                    { Header: 'Make', accessor: 'make', },
                    { Header: 'Model', accessor: 'model', },
                    { Header: 'Customer', accessor: 'customer', },
                    { Header: 'AWS', accessor: 'aws_connected', },
                ],
            },
        ],
        []
    );
    return (
        <div className="card-body">
            {/*<TableSearchBar />*/}
            <TableActionBar />
            <Table columns={columns} data={data} tableName={"vehicles"} />
        </div>
    );
};

export default VehicleListTable