import React from 'react';

import ParseLocation from "../../common/functions/parse_location";
import getVehicleDetails from "../../common/functions/vehicle_details_api";

import VehicleDetailsExciseDutyCard from "../cards/vehicleDetailsExciseDutyCard"
import VehicleDetailsInsuranceCard from "../cards/vehicleDetailsInsuranceCard";
import VehicleDetailsMOTCard from "../cards/vehicleDetailsMOTCard";
import VehicleDetailsRegistrationCard from "../cards/vehicleDetailsRegistrationCard";
import VehicleDetailsSensorCard from "../cards/vehicleDetailsSensorSummaryCard";
import ViewActionBar from "../../common/viewActionBar";
import Breadcrumbs from "../../common/breadcrumbs";
import getPreSignedUrl from "../../common/functions/get_presigned_url";


class VehicleDetail extends React.Component {
    constructor(props) {
        super(props);
        this.updateRegistrationCard = React.createRef()
        this.updateInsuranceCard = React.createRef()
        this.updateExciseCard = React.createRef()
        this.updateMOTCard = React.createRef()
        this.updateSensorCard = React.createRef()

        this.state = {
            vehicleDetails: {},
        }
    };
    componentDidMount() {
        let {objType, objId, objAction} = ParseLocation()
        if(objId !== "") {
            getVehicleDetails(objId,
                (data) => {
                    debugger;
                    this.setState({vehicleDetails: data });
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateRegistrationCard.current.setState({vehicleDetails: this.state.vehicleDetails})
        this.updateInsuranceCard.current.setState({vehicleDetails: this.state.vehicleDetails})
        this.updateExciseCard.current.setState({vehicleDetails: this.state.vehicleDetails})
        this.updateMOTCard.current.setState({vehicleDetails: this.state.vehicleDetails})
        this.updateSensorCard.current.setState({vehicleDetails: this.state.vehicleDetails})
        getPreSignedUrl('img_logo', this.state.vehicleDetails.graphic, 'darwin-vehicle-graphics');
    }

    // componentWillUnmount() {
    // }

    render() {
        let {objType, objId, objAction} = ParseLocation()
        return (
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2">
                            <span className="content-header-title">
                                <img src="" id="img_logo"
                                     height="30" width="auto"
                                     alt={"Vehicle icon"}
                                     className="float-left mr-1"/>
                                <h3>{objId}</h3>
                            </span>
                            <Breadcrumbs/>
                            <ViewActionBar/>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <VehicleDetailsRegistrationCard {...this.props} ref={this.updateRegistrationCard}/>
                            </div>
                    
                            <div className="col-md-6 col-12">
                                <VehicleDetailsInsuranceCard {...this.props} ref={this.updateInsuranceCard}/>
                            </div>
                    
                            <div className="col-md-6 col-12">
                                <VehicleDetailsExciseDutyCard {...this.props} ref={this.updateExciseCard}/>
                            </div>
                    
                            <div className="col-md-6 col-12">
                                <VehicleDetailsMOTCard {...this.props} ref={this.updateMOTCard}/>
                            </div>
                    
                            <div className="col-md-12 col-12">
                                <VehicleDetailsSensorCard {...this.props} ref={this.updateSensorCard}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VehicleDetail;
