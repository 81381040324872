import ParseLocation from "./parse_location";

const urlCurrent = new URL(document.URL);
const params = new URLSearchParams(urlCurrent.search);
const qs_auth = params.get('access_token') !== null ? '?access_token=' + params.get('access_token') + '&id_token=' + params.get('access_token') : '';

function addRowLinkUrl(row, id_attr_name) {
    let { objType, objId, objAction } = ParseLocation();
    // Adds the target_url attribute to each row so that <Link /> will work, can't compute it during row rendering.
    row.target_url = "/" + objType + "/Detail/" + row[id_attr_name] + qs_auth;
    return row
}

export default addRowLinkUrl;