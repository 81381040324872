import React from 'react';


import Breadcrumbs from "../../common/breadcrumbs";
import ViewActionBar from "../../common/viewActionBar";
import CustomerDetailCard from "../../customer/cards/customerDetailCard";


// class CustomerDetail extends React.Component {
class CustomerDetail extends React.Component {
    // Standard constructor, with state for 'customer', defaulting to null
    constructor(props) {
        super(props);
    }

    render() {
        return (
           <div className="app-content content">
                <div className="content-wrapper">

                    {/* PAGE CONTENT HEADER */}
                    <div className="content-header row">
                        <div className={"content-header-left col-md-12 col-12"}>
                            <h2 className={"content-header-title"}>Customer</h2>
                        </div>
                        <div className="content-header row">
                            <div className={"content-header-left col-md-12 col-12 mb-1"}>
                                <Breadcrumbs/>
                            </div>
                        </div>
                    </div>

                    {/* PAGE CONTENT BODY */}
                    <div className="content-body col-md-12 col-12">
                        <div className={"container col-md-12 col-12"}>
                            <div className={"row col-12"}>
                                <div className={"col col-md-12 col-12 content"}>
                                    <ViewActionBar />
                                </div>
                            </div>

                            <div className={"row col-12"}>
                                <div className={"col col-md-12 col-12 content"}>
                                    <CustomerDetailCard/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CustomerDetail;
