import React from "react";

import { Link } from "react-router-dom";
import Table from "../../common/table";

import useFetch from "../../common/useFetch";
import getApiUrl from "../../common/functions/get_api_url";
import ParseLocation from "../../common/functions/parse_location";
import addRowLinkUrl from "../../common/functions/add_row_link_url";
import TableActionBar from "../../common/tableActionBar";
import getPreSignedUrl from "../../common/functions/get_presigned_url";

function CustomerListTable() {
    let { objType, objId, objAction } = ParseLocation();
    let data = useFetch(getApiUrl(objType));
    // data = data.map(function(row) {return addRowLinkUrl(row, index, arr, objType, objId, objAction) });
    data = data.map(row => addRowLinkUrl(row, 'Customer_ID'));
    data.map(row => getPreSignedUrl(
        'img_' + row.Customer_ID,
        row.graphic,
        'darwin-customer-graphics'));

    const columns = React.useMemo(
        () => [
            {
                Header: 'Customers',
                columns: [

                    { Header: <input type="checkbox" className="input-chk" id="chkAll" name="select_cb"/>,
                        accessor: 'row_select', Cell: ({ row }) => (
                            <input type="checkbox" className="input-chk" name="row_select_cb"></input>)
                    },
                    { Header: 'Logo', accessor: 'graphic',  Cell: ({ row }) => (
                        <Link to={ row.original.target_url }>
                            <img src=""  id={"img_" + row.original.Customer_ID}
                                 alt={row.original.customer_name + ' logo icon'}
                                 height="30" width="auto"/>
                        </Link>)
                    },
                    { Header: 'Customer Name', accessor: 'customer_name', Cell: ({ row }) => (
                            <Link to={ row.original.target_url }>{row.original.customer_name}</Link>),
                    },
                    { Header: 'Contract', accessor: 'contract', },
                    { Header: 'No. of Vehicles', accessor: 'vehicles', },
                    { Header: 'Notes', accessor: 'notes', },
                ],
            },
        ],
        []
    );
        return (
            <div className="card-body">
                {/*<TableSearchBar />*/}
                <TableActionBar />
                <Table columns={columns} data={data} />
            </div>
        );
};

export default CustomerListTable
