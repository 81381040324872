import React from 'react';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

const urlCurrent = new URL(document.URL);
const params = new URLSearchParams(urlCurrent.search);

function getApiUrl(objType, qsParts= []) {

    try {
        let UrlApi;
        if (urlCurrent.hostname.indexOf('localhost') === 0 || urlCurrent.hostname.indexOf('127.0.0.1') === 0) {
            // If running locally, then assume our web service calls are also local
            UrlApi = String(urlCurrent.protocol) + '//127.0.0.1:8080/' + objType.toLowerCase()

        } else {
            // If not running locally, i.e. on the server
            UrlApi = urlCurrent.origin + '/Dev/' + objType.toLowerCase()
        }

        // // if (params.has('access_token')) {
        // if (params.get('access_token')) {
        //     qsParts.push(["access_token", params.get('access_token')])
        // }
        // // }
        // // if (params.has('id_token')) {
        // if (params.get('id_token')) {
        //     qsParts.push(["id_token", params.get('id_token')])
        // }
        // }
        let qs = ''
        qsParts.forEach(part => {
            qs += part[0] + '=' + part[1] + '&';

        })

        UrlApi += (qs ? '?' + qs.slice(0, -1): "");
        return UrlApi;

    } catch (err) {
        window.alert('getApiUrl crashed because: ' + err);
    }
}

export default getApiUrl;