import React from 'react';
// import Axios from "axios";
import Chart from "react-google-charts";
import Spinner from "../../UI/Spinner/Spinner";
// import getApiUrl from "../../common/functions/get_api_url";
// import {w3cwebsocket as W3CWebSocket} from "websocket"; // Make a WebSocket client connection to received vehicleData
import CardHeaderControls from "../../common/cardHeaderControls";
import ParseLocation from "../../common/functions/parse_location";

let wsClient = null;
let wsClientVehicleId = null;
let {objType, objId, objAction} = ParseLocation();
//W3CWebSocket(url, protocols, origin, headers, requestOptions, clientConfig)

class VehicleDataChartCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: {},
            vehicleTelemetry: {},
            vehicleHistory: [[
            ]],

            Customer_ID: '',
            Vehicle_ID: objId,
        };
    };

    // componentDidMount() {
    // }
    //
    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }


    render() {
        // let num_cols = 2;
        let tbody =
            // <tbody className="width-100-per overflow-auto">
                <tr>
                    <td colSpan="2"><Spinner/></td>
                </tr>
            // </tbody>;

        let chart = <Spinner />
        if (this.state.vehicleHistory.length > 0) {

            // let history = [
            //     ["timestamp", "VehicleSpeed", "Speed_Actual"],
            //     ["a", 1, 1],
            //     ["b", 2, 4],
            //     ["c", 3, 9],
            //     [d, 4, 16],
            //     ["e", 5, 25],
            // ]
            // let rows;
            let array_column = (arr, n) => arr.map(e => e[n]);
            let history = this.state.vehicleHistory.slice()

            // Field names that we want for this graph, convert to indices from the main vehicleHistory
            let chart_fields = ['dt_mqtt', 'VehicleSpeed'];
            let history_fields = history.slice(0)[0]
            history.shift()
            let history_indices = [];
            chart_fields.forEach( (field) => {
                let index = history_fields.indexOf(field)
                if(index >= 0) {
                    history_indices.push(index)
                }
            })

            let chart_history = []; // will hold data for just the columns we want
            history.forEach((row) => {
                console.log(row)
                let new_row = [];
                for (let [i, col] of row.entries()) {
                    if (history_indices.indexOf(i) >= 0) {
                        new_row.push(col);
                    }
                }
                chart_history.push(new_row);
            }) //forEach row loop

            chart_history.unshift(chart_fields)
            if (chart_history.length > 1) {
                chart =
                    <Chart
                        width={'600px'}
                        height={'400px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={ chart_history }
                        options={
                            {
                                hAxis: {
                                    title: 'Time',
                                },
                                vAxis: {
                                    title: 'Speed (km/h)',
                                },
                                series: {
                                    1: {curveType: 'function'},
                                },
                            }
                        }
                        rootProps={{'data-testid': '1'}}
                    />
            }
            // history.unshift(fields)
        }

        return (
            
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Telemetry Chart</h4>
                    <CardHeaderControls {...this.props}/>
                </div>

                <div className="card-content">
                    <div className="card-body">
                        <div className="row">
                       
                        <div className="col-md-6">
                        { chart }
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>
       

            
        );
    }
}

export default VehicleDataChartCard;
