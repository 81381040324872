import React from 'react';
import axios from "axios";
import $ from 'jquery';
import CardHeaderControls from "../../common/cardHeaderControls";
import getApiUrl from "../../common/functions/get_api_url";
import checkValidity from "../../common/functions/check_validity";
import Button from "../../UI/Button/Button"
import FileUploadControl from "../../common/fileUploader"
import Input from "../../UI/Input/Input";


class CustomerAddCard extends React.Component {
    constructor(props) {
        super(props);
        this.updateGraphicHandler = this.updateGraphicHandler.bind(this)
        this.state = {
            formIsValid: false,
            formIsDirty: false,
            loading: true,    // used to block other actions
            // sendData: false,  // set by parent card to trigger sending of card data
            customerForm: {
                // Customer_ID: {
                //     elementType: 'hidden',
                //     elementConfig: {
                //         type: 'hidden',
                //     },
                //     label: 'Customer ID:',
                //     value: '',
                //     valid: true,
                //     errors: [],
                // },
                customer_name: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Registered Company Name'
                    },
                    label: 'Company Name',
                    value: '',
                    validation: {
                        required: true,
                        minLength: 3,
                        maxLength: 50

                    },
                    valid: false,
                    errors: [],
                    touched: false
                },
                contract: {
                    elementType: 'select',
                    elementConfig: {
                        options: [
                            {value: '', displayValue: '-- please select --'},
                            {value: 'Fixed', displayValue: 'Fixed'},
                            {value: 'Monthly', displayValue: 'Monthly'},
                            {value: 'Quarterly', displayValue: 'Quarterly'},
                            {value: 'Temporary', displayValue: 'Temporary'},
                            {value: 'Limited Contract', displayValue: 'Limited Contract'}
                        ]
                    },
                    label: 'Contract:',
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    errors: [],
                    touched: false,
                },

                graphic: {
                    elementType: 'fileupload',
                    elementConfig: {
                        type: 'text',
                        placeholder: ''
                    },
                    value: '',
                    field: 'graphic',
                    validation: {
                        required: false
                    },
                    valid: true,
                    errors: [],
                    touched: false
                },

                notes: {
                    elementType: 'textarea',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Notes:-'
                    },
                    label: 'Notes:-',
                    value: '',
                    validation: {
                        required: false
                    },
                    valid: true,
                    errors: [],
                    touched: false
                },
                vehicles: {
                    elementType: 'text',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Number of vehicles'
                    },
                    label: 'Vehicles:',
                    value: '',
                    validation: {
                        required: false,
                        isNumeric: true,
                        min: 0,
                        max: 99
                    },
                    valid: false,
                    errors: [],
                    touched: false
                },

            },
            customerDetails: {}
        }
    };

    componentDidMount() {
        // $('#btnSave').click(function () {
        //     window.alert("Save Clicked")
        // });
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    updateGraphicHandler = (fileName) => {
        debugger
        const updatedCustomerForm = {
            ...this.state.customerForm
        };
        const updatedFormElement = {
            ...updatedCustomerForm['graphic']
        };
        updatedFormElement.value = fileName;
        // updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        checkValidity(updatedFormElement)
        updatedFormElement.touched = true;
        updatedCustomerForm['graphic'] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedCustomerForm) {
            formIsValid = updatedCustomerForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({customerForm: updatedCustomerForm, formIsValid: formIsValid});
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedCustomerForm = {
            ...this.state.customerForm
        };
        const updatedFormElement = {
            ...updatedCustomerForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        // updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        checkValidity(updatedFormElement)
        updatedFormElement.touched = true;
        updatedCustomerForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedCustomerForm) {
            formIsValid = updatedCustomerForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({customerForm: updatedCustomerForm,formIsValid: formIsValid, formIsDirty: true});
    }

    getCardData(node= null) {
        let state = {};
        let form = node === null ? this.state.customerForm : this.state.customerForm[node];
        Object.keys(form).forEach((key, index) => {
            state[key] = this.state.customerForm[key].value;
        })
        if (node === null) {
            // this.setState({customerDetails: state});
            state = {customerDetails: state};
            return [node, state];
        } else {
            state = {customerDetails: {node: state}};
            return [node, state];
        }
    }

    render() {
        // Conditionally create content
        let logo_img = '';
        if (!this.state.loading && this.state.customerForm['graphic'] !== '') {
            logo_img = <img src={this.state.customerForm['graphic']} height="30" width="auto"
                            alt="Customer logo"
                            className="float-left"/>
        }

        let card_header = '';
        if (!this.state.loading) { card_header =
            <span>
                {/*{logo_img}*/}
                {/*{this.state.customerForm['customer_name'].value} Details:*/}
            </span>
        }
        let card_content;
        if (false !== true ) { card_content =
            <div>
                <form className="form form-horizontal">
                    {/* FORM SECTION */}
                    <div className="form-body">
                        <h4 className="form-section"><i className="ft-user"></i>Customer Details</h4>

                        <div className="form-group row">
                            <label className="col-md-3 label-control" htmlFor="customer_name">Customer Name</label>
                            <div className="col-md-9">
                                <input id="customer_name" type="text"
                                       className="form-control" placeholder="Customer name" name="customer_name"
                                       value={this.state.customerForm.customer_name.value}
                                       invalid={this.state.customerForm['customer_name'].elementConfig.valid}
                                       shouldValidate={this.state.customerForm['customer_name'].elementConfig.validation}
                                       touched={this.state.customerForm['customer_name'].elementConfig.touched}
                                       onChange={(event) => this.inputChangedHandler(event,'customer_name')}
                                ></input>
                                <div id="customer_name_error" className="error">{this.state.customerForm.customer_name.errors.join('<br />')}</div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-3 label-control" htmlFor="contract">Contract</label>
                            <div className="col-md-9">
                                <select  id="contract" className="form-control" name="contract"
                                         value={this.state.customerForm['contract'].value}
                                         invalid={this.state.customerForm['contract'].elementConfig.valid}
                                         shouldValidate={this.state.customerForm['contract'].elementConfig.validation}
                                         touched={this.state.customerForm['contract'].elementConfig.touched}
                                         onChange={(event) => this.inputChangedHandler(event,'contract')}
                                >
                                    <option value="" selected> -- please select --</option>
                                    <option value="Fixed">Fixed</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Temporary">Temporary</option>
                                    <option value="Limited Contract">Limited Contract</option>
                                </select>
                                <div id="contract_error" className="error">{this.state.customerForm.contract.errors}</div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-3 label-control" htmlFor="vehicles">Vehicles</label>
                            <div className="col-md-9">
                                <input type="text" id="vehicles" className="form-control" placeholder="No. of vehicles" name="vehicles"
                                       value={this.state.customerForm['vehicles'].value}
                                       invalid={this.state.customerForm['vehicles'].elementConfig.valid}
                                       shouldValidate={this.state.customerForm['vehicles'].elementConfig.validation}
                                       touched={this.state.customerForm['vehicles'].elementConfig.touched}
                                       onChange={(event) => this.inputChangedHandler(event,'vehicles')}
                                />
                                <div id="vehicles_error" className="error">{this.state.customerForm.vehicles.errors}</div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-3 label-control" htmlFor="notes">Notes:-</label>
                            <div className="col-md-9">
                                <textarea id="notes" className="form-control" placeholder="Notes ..." name="notes" cols={"40"} rows={"10"}
                                          value={this.state.customerForm['notes'].value}
                                          invalid={this.state.customerForm['notes'].elementConfig.valid}
                                          shouldValidate={this.state.customerForm['notes'].elementConfig.validation}
                                          touched={this.state.customerForm['notes'].elementConfig.touched}
                                          onChange={(event) => this.inputChangedHandler(event,'notes')}
                                />
                                <div id="notes_error" className="error">{this.state.customerForm.notes.errors}</div>
                            </div>
                        </div>

                        <div className={"form-group row"}>
                            <label className="col-md-3 label-control" htmlFor="notes">Logo Image:-<br/>
                                <span style={{fontSize: '0.8em'}}>(small)</span></label>
                            <div className={'col'}>
                                <FileUploadControl {...this.props} field={'graphic'} updateGraphicHandler={this.updateGraphicHandler} />
                            </div>
                        </div>

                        <div className="float-md-right">
                            {/* JQuery should be used to validate and conditionally navigate */}
                            <Button btnType="Success"
                                    disabled={!this.state.formIsValid}
                                    clicked={this.customerHandler}
                            >Save</Button>
                        </div>

                        {/*<FileUploader />*/}
                    </div>
                </form>
            </div>
        }

        // if (this.state.loading === false) { card_content =
        //     <Spinner />
        // }
        return (
            // CARD CONTAINER
            <div className="card overflow-auto ">

                {/* CARD CONTENT HEADER */}
                <div className="card-header">
                    <h4 className="card-title">{ card_header }</h4>
                    <CardHeaderControls/>
                </div>

                {/* CARD CONTENT BODY */}
                <div className="card-content show">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>

        );
    }
}

export default CustomerAddCard;