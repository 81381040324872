import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import checkValidity from "../../common/functions/check_validity";
import ParseLocation from "../../common/functions/parse_location";
import Spinner from "../../UI/Spinner/Spinner";
import Input from "../../UI/Input/Input";

// const URL = require('url'); // nodejs-builtin

class VehicleEditExciseDutyCard extends React.Component {
    constructor(props) {
        super(props);
        this.updateHandler = this.updateHandler.bind(this)
        this.state = {
            formIsValid: false,
            formIsDirty: false,
            loading: true,
            vehicleDetails: {},
            vehicleForm: {
                excise_dutyForm: {
                    sorn_date: {
                        elementType: 'date',
                        elementConfig: {},
                        validation: {
                            required: true,
                            isDate: true,
                        },
                        label: 'SORN Date:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    v11_reminder_reference: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            country: 'uk'
                        },
                        validation: {
                            required: true,
                            minLength: 16,
                            maxLength: 20
                        },
                        label: 'V11 document ref.:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    ved_start_date: {
                        elementType: 'date',
                        elementConfig: {},
                        validation: {
                            required: true,
                            isDate: true,
                        },
                        label: 'VED start date:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    ved_end_date: {
                        elementType: 'date',
                        elementConfig: {},
                        validation: {
                            required: true,
                            isDate: true,
                        },
                        label: 'VED end date:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    ved_band_current: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            country: 'uk'
                        },
                        validation: {
                            required: true,
                            minLength: 1,
                            maxLength: 4
                        },
                        label: 'VED band (current):',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    ved_band_next: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            country: 'uk'
                        },
                        validation: {
                            required: true,
                            minLength: 1,
                            maxLength: 4
                        },
                        label: 'VED band (next):',
                        value: '',
                        valid: false,
                        errors: [],
                    },

                }
            }
        }
    }

    updateHandler = (fieldName, value) => {
        // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
        let updatedForm = { ...this.state.vehicleForm };
        let updatedFormElement = {...updatedForm[fieldName]};
        updatedFormElement.value = value;
        checkValidity(updatedFormElement);
        updatedFormElement.touched = true;
        updatedForm[fieldName] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({vehicleForm: updatedForm });
    }


    // componentDidMount() {
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        if(this.state.vehicleDetails !== prevState.vehicleDetails) {
            // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
            let updateForm = { ...this.state.vehicleForm };
            // Object.keys(this.state.vehicleDetails).forEach(field => {
            //     debugger
            //     if (this.state.vehicleForm.hasOwnProperty(field)) {
            //         let updateElement = { ...updateForm[field] };
            //         if (updateElement.childre)
            //
            //         updateElement.value = this.state.vehicleDetails[field];
            //         updateForm[field] = { ...updateElement };
            //     }
            // });
            // this.setState({vehicleForm: { ...updateForm }})
            //
            Object.keys(this.state.vehicleForm).forEach(field => {
                debugger
                if (field.slice(-4) != 'Form') {
                    // root level fields
                    if (this.state.vehicleForm.hasOwnProperty(field)) {
                        let updateElement = { ...updateForm[field] };
                        updateElement.value = this.state.vehicleDetails[field];
                        updateForm[field] = { ...updateElement };
                    }

                } else {
                    // nested level fields
                    let node = field.slice(0, field.length - 4);
                    Object.keys(this.state.vehicleForm[field]).forEach(nodeField => {
                        if (this.state.vehicleDetails[node].hasOwnProperty(nodeField)) {
                            let updateElement = { ...updateForm[field][nodeField] };
                            updateElement.value = this.state.vehicleDetails[node][nodeField];
                            updateForm[field][nodeField] = { ...updateElement };
                        }
                    });
                }
            });
            this.setState({vehicleForm: { ...updateForm }})
        }
    }

    // componentWillUnmount() {
    // }


    render() {
        const {objType, objId, objAction} = ParseLocation();

        // Always start with a spinner
        let card_content = <Spinner />

        if (JSON.stringify(this.state.vehicleDetails) !== "{}" || objAction === 'Add') {
            card_content = []
            Object.keys(this.state.vehicleForm).forEach((key, index) => {
                if (key.slice(-4) !== 'Form') {
                    let input_content = <Input {...this.state.vehicleForm[key]} field={key} />
                    card_content.push(input_content);
                } else {
                    let node = key.slice(0, key.length -4);
                    Object.keys(this.state.vehicleForm[key]).forEach((nodeKey, index) => {
                        let input_content = <Input {...this.state.vehicleForm[key][nodeKey]} field={nodeKey} />
                        card_content.push(input_content);
                    });
                }
            });
        }

        return (
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Excise Duty</h4>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleEditExciseDutyCard;