import React from 'react';
import { Link } from "react-router-dom";
import ParseLocation from "./functions/parse_location";

class ViewActionBar extends React.Component {
    render() {
        let { objType, objId, objAction } = ParseLocation()
        return (
                <div className="float-md-right">
                    {/*TODO - This should pick up the return location from location history really */}
                    <Link to={"/" + objType + "s"}>
                        <button id="btnCancel" className="btn btn-primary btn-sm mr-1">
                            <span><i className="ft-x"></i>&nbsp;Cancel</span>
                        </button>
                    </Link>

                    <Link to={"/" + objType + "/Edit/" + objId}>
                        <button id="btnEdit" className="btn btn-primary btn-sm">
                            <span><i className="ft-edit white"></i>&nbsp;Edit</span>
                        </button>
                    </Link>
                </div>
        )
    }
}

export default ViewActionBar;