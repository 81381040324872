import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";

// const URL = require('url'); // nodejs-builtin

class VehicleDetailsMOTCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: {},
        }
    };

    // componentDidMount() {
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    // componentWillUnmount() {
    // }

    render() {
        // Always start with a spinner
        let card_content = <Spinner/>
        // Once the Card data has been loaded then show the real content
        debugger

        let advisories = "No Advisories on last test";
        // if (this.state.vehicleDetails.hasOwnProperty('Vehicle_ID') && this.state.vehicleDetails.hasOwnProperty('Vehicle_ID') !== "") {
        //     if (this.state.vehicleDetails['mot'] != undefined && this.state.vehicleDetails['mot']['advisories'] != undefined && this.state.vehicleDetails['mot']['advisories'].length >= 1) {
        //         let advisory_rows = [];
        //             this.state.vehicleDetails['mot']['advisories'].forEach(advisory => {
        //                 debugger
        //                 let row = <tr>
        //                     <td>{advisory.description}</td>
        //                     <td>{advisory.action_required}</td>
        //                     <td>{advisory.action_taken_date}</td>
        //                 </tr>
        //
        //                 advisory_rows.push(row);
        //             });
        //
        //         advisories = <table>
        //             <th>
        //                 <tr>
        //                     <td colSpan={3}>
        //                         <strong>Advisories</strong>
        //                     </td>
        //                 </tr>
        //                 <tr>
        //                     <td>Description</td>
        //                     <td>Action</td>
        //                     <td>Date</td>
        //                 </tr>
        //             </th>
        //             <tbody>
        //             { advisory_rows }
        //             </tbody>
        //         </table>
        //
        //     }
        // }

        if(this.state.vehicleDetails.hasOwnProperty('Vehicle_ID') && this.state.vehicleDetails.hasOwnProperty('Vehicle_ID')  !== "") {card_content =
            <table className="table">
                <tr>
                    <td><label>Test Date:</label></td>
                    <td><b>{this.state.vehicleDetails['mot']['test_date']}</b></td>
                </tr>

                <tr>
                    <td><label>Test Status:</label></td>
                    <td><b>{this.state.vehicleDetails['mot']['test_status']}</b></td>
                </tr>

                <tr>
                    <td><label>Certificate Ref. #:</label></td>
                    <td><b>{this.state.vehicleDetails['mot']['certificate_ref']}</b></td>
                </tr>

                {/*<tr>*/}
                {/*    <td width={"100%"} colSpan={2}>*/}
                {/*        { advisories }*/}
                {/*    </td>*/}
                {/*</tr>*/}


            </table>
        }

        return (
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">MOT</h4>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleDetailsMOTCard;