import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";
import ParseLocation from "../../common/functions/parse_location";
import getAwsParameter from "../../common/functions/parameter_store_api";

const panelDefinition = [
    {'name': 'Front number plate', 'color': 'Color11', 'image': 'Image11'},
    {'name': 'Windscreen bottom', 'color': 'Color08', 'image': 'Image08'},
    {'name': 'Windscreen top', 'color': 'Color07', 'image': 'Image07'},
    {'name': 'Front Panel', 'color': 'Color09', 'image': 'Image09'},
    {'name': 'Side Mirrors', 'color': 'Color10', 'image': ''},
    {'name': 'Left door', 'color': '', 'image': 'Image03L'},
    {'name': 'Right door', 'color': '', 'image': 'Image03R'},
    {'name': 'Doors - lower edge', 'color': 'Color05', 'image': ''},
    {'name': 'Doors - top panel', 'color': 'Color03', 'image': ''},
    {'name': 'Main body', 'color': 'Color06', 'image': ''},
    {'name': 'Left quarter panel', 'color': '', 'image': 'Image02L'},
    {'name': 'Right quarter panel', 'color': '', 'image': 'Image02R'},
    {'name': 'Back door', 'color': 'Color01', 'image': 'Image01'},
    {'name': 'Rear number plate', 'color': 'Color12', 'image': 'Image12'}
]

class VehicleLiveryDetailColorsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetail: null,
            liveryDetail: null,
        }
    };

    // componentDidMount() {
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    // componentWillUnmount() {
    // }

    render() {
        const {objType, objId, objAction} = ParseLocation();

        let fmtImagePath = (path) => {
            if(path.search(objId) === 0) {
                return path.slice(objId.length+1);
            } else {
                return path;
            }
        };

        let processCols = (row, liveryDetail) => {
            const btnColour = {
                width: 80,
                background: liveryDetail[row.color]
            }
            const imgThumb = {
                width: 50,
                height: 50
            }

            let cols = []
            cols.push(<div className="col">{row.name}</div>)
            cols.push(
                <div className="col">
                    <button style={btnColour} active={false}>{liveryDetail.hasOwnProperty(row.color) ? liveryDetail[row.color]: '---'}</button>
                </div>
            )

            let img_panel = '';
            if (liveryDetail.hasOwnProperty([row.image])) {
                img_panel = <img src={getAwsParameter('S3_Livery_Graphics')+'/'+ liveryDetail[row.image]} id={'img_' + row.id}
                     height="30" width="auto"
                     alt={'Livery panel image for ' + row.name} />
            };

            cols.push(
                <div className="col">
                    <span className={"mr-2"}>
                        {liveryDetail.hasOwnProperty(row.image) ? fmtImagePath(liveryDetail[row.image]): '---'}
                    </span>
                    <span>
                        { img_panel }
                    </span>
                </div>
            )
            return cols
        };

        let processRows = (liveryDetail) => {
            const rows = [[
                    <div className="row">
                        <div className="col col-md-2"><b>Panel description</b></div>
                        <div className="col col-md-1"><b>Colour</b></div>
                        <div className="col col-md-3"><b>Image</b></div>
                    </div>
                ]]

            panelDefinition.forEach( (row) => {
                let row_content =
                    <div className="row border-bottom-blue-grey">
                        { processCols(row, liveryDetail) }
                    </div>
                rows.push(row_content);
            });
            return rows
        };

        // Always start with a spinner
        let card_content = <Spinner />

        // Once the Card data has been loaded then show the real content
        if (this.state.liveryDetail !== null) {
            debugger
            card_content = processRows(this.state.liveryDetail)
        }

        return (
            <div className="card">
                <div className="card-header">
                    <div>Panel Colours & Images</div>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleLiveryDetailColorsCard;