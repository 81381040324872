import React from 'react';
import Chart from "react-google-charts";
// https://react-google-charts.com/gauge-chart
// https://developers.google.com/chart/interactive/docs/gallery/gauge


class TelemetrySpeed extends React.Component {
    constructor(props) {
        super(props);
        // this.childStateHandler = this.childStateHandler.bind(this)
        this.state = {
            vehicleTelemetry: {
                name: 'default',
                Speed_Actual: 50
            }
        }
    };


    render() {

        // Have to be careful with datatypes as once the chart fails it doesn't recover
        let sSpeed = this.state.vehicleTelemetry['VehicleSpeed']
        let iSpeed = !isNaN(sSpeed) ? parseInt(sSpeed) * 0.621371192: 0; /* convert kph to mph */

        return (
            <Chart
                width={200}

                chartType="Gauge"
                loader={<div>Loading Speedo</div>}
                data={[
                    ['Label', 'Value'],
                    ['mph', iSpeed],
                ]}
                options={{
                    width: 100,
                    height: 100,
                    fontSize: 10,
                    max: 80,
                    redFrom: 60,
                    redTo: 70,
                    yellowFrom:50,
                    yellowTo: 60,
                    greenFrom: 5,
                    greenTo: 50,
                    majorTicks: ['0', '10', '20', '30', '40', '50', '60', '70', '80'],
                    minorTicks: 5,
                    chartArea: {
                    left: '5%',
                    width: '90%',
                    height: 150
                }
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        )
    }
}

export default TelemetrySpeed

// // Resize chart
// // ------------------------------
//
// $(function () {
//
//     // Resize chart on menu width change and window resize
//     $(window).on('resize', resize);
//     $(".menu-toggle").on('click', resize);
//
//     // Resize function
//     function resize() {
//         drawGuage();
//     }
// });