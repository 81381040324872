import Axios from "axios";
import getApiUrl from "./get_api_url";

function getVehicleLiveryData(livery_id, livery_type, callback)
{
    // perform a check that we have a usable vehicle id variable
    // if (vehicle_id === null || vehicle_id === undefined || String(vehicle_id) === '') {
    //     return {
    //         'data': null,
    //         'error': "vehicle_id was not valid, i.e. null, undefined or empty string"
    //     }
    // }
    let qsParts = [];
    if (livery_id !== null && livery_id !== undefined && String(livery_id) !== '') {
        qsParts.push(['Vehicle_ID', livery_id]);
    }
    if (livery_type !== null && livery_type !== undefined && String(livery_type) !== '') {
        qsParts.push(['Livery_Type', livery_type]);
    }

    // Call the vehicle WebService
    console.log("Calling vehicle_static_api: "+ getApiUrl('vehicle_static', qsParts))
    Axios.get(getApiUrl('vehicle_static', qsParts))
        .then(response => {
            // Handle a good response
            console.log("wsClient received livery data.")
            callback(response.data)
        })
        .catch(error => {
            if (error) {
                console.log(error)
                return {
                    'data': null,
                    'error': "An error was encountered calling the vehicle livery webservice"
                }
            }
        });
}

export default getVehicleLiveryData