import React from 'react';
import LanguageDropdown from "./language_dropdown";
import NotificationsDropdown from "./notifications_dropdown";
import MessagesDropdown from "./messages_dropdown";
import AccountDropdown from "./account_dropdown";


function Header() {
    return (
        <nav className="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-dark bg-primary navbar-shadow navbar-brand-center">
            <div className="navbar-wrapper">
                <div className="navbar-header">
                    <ul className="nav navbar-nav flex-row">
                        <li className="nav-item mobile-menu d-md-none mr-auto">
                            <a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                                <i className="ft-menu font-large-1"></i>
                            </a>
                        </li>

                        <li className="nav-item">
                            <a className="navbar-brand" href="">
                                <img className="brand-logo" alt="" src="" />
                                <h3 className="brand-text">Darwin CAV</h3>
                            </a>
                        </li>

                        <li className="nav-item d-md-none">
                            <a className="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile">
                                <i className="fa fa-ellipsis-v"></i></a>
                        </li>
                    </ul>
                </div>

                <div className="navbar-container content">
                    <div className="collapse navbar-collapse" id="navbar-mobile">
                        <ul className="nav navbar-nav mr-auto float-left">
                            <li className="nav-item d-none d-md-block">
                                <a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                                    <i className="ft-menu"> </i>
                                </a>
                            </li>

                            <li className="nav-item d-none d-md-block">
                                <a className="nav-link nav-link-expand" href="#">
                                    <i className="ficon ft-maximize"></i>
                                </a>
                            </li>

                            <li className="nav-item nav-search">
                                <a className="nav-link nav-link-search" href="#">
                                    <i className="ficon ft-search"></i>
                                </a>
                                <div className="search-input">
                                    <input className="input" type="text" placeholder="Explore Darwin..."/>
                                </div>
                            </li>
                        </ul>

                        <ul className="nav navbar-nav float-right">
                            <li className="dropdown dropdown-language nav-item">
                                <LanguageDropdown />
                            </li>
                            <li className="dropdown dropdown-notification nav-item">
                                <NotificationsDropdown />
                            </li>
                            <li className="dropdown dropdown-message nav-item">
                                <MessagesDropdown />
                            </li>
                            <li className="dropdown dropdown-user nav-item">
                                <AccountDropdown />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

    );

}

export default Header;