import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";
import {Link} from "react-router-dom";

class VehicleDetailsRegistrationCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: {},
        }
    };

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    // componentWillUnmount() {
    // }

    render() {
        // Always start with a spinner
        let card_content = <Spinner />
        // Once the Card data has been loaded then show the real content
        debugger

        let livery_id = (!this.state.vehicleDetails.hasOwnProperty('livery_id') || this.state.livery_id === "") ?
            '_default_template' : this.state.vehicleDetails['livery_id']
        let livery = <tr></tr>
        if(this.state.vehicleDetails.hasOwnProperty('Vehicle_ID') && this.state.vehicleDetails.hasOwnProperty('Vehicle_ID')  !== "") { livery =
            <tr>
                <td className={'mr-1'}><label>Livery:</label></td>
                <td>
                    <b><span className={"mr-2"}>{livery_id === '_default_template' ? 'default' : livery_id}</span></b>
                    <Link className={"mr-2"} to={'/VehicleLivery/Detail/' + livery_id}><i className="fa fa-eye"></i></Link>
                    {livery_id === '_default_template' ? '' :
                        <Link to={'/VehicleLivery/Edit/' + livery_id}><i className="fa fa-gears"></i></Link>}
                </td>
            </tr>
        }

        if(this.state.vehicleDetails.hasOwnProperty('Vehicle_ID') && this.state.vehicleDetails.hasOwnProperty('Vehicle_ID')  !== "") { card_content =
            <table className="table">
                <tbody>
                    <tr>
                        <td className={'mr-1'}><label>Registration plate.</label></td>
                        <td><b><span id="AV_rgno">{this.state.vehicleDetails['registration']['registration_plate']}</span></b></td>
                    </tr>

                    <tr>
                        <td className={'mr-1'}><label>V5 reference:</label></td>
                        <td><b>{this.state.vehicleDetails['registration']['document_ref']}</b></td>
                    </tr>

                    <tr>
                        <td className={'mr-1'}><label>First registered:</label></td>
                        <td id="AV_firstreg"><b>{this.state.vehicleDetails['registration']['first_registered']}</b></td>
                    </tr>

                    <tr>
                        <td className={'mr-1'}><label> Purchase date:</label></td>
                        <td id="AV_purchasedate"><b>{this.state.vehicleDetails['purchase_date']}</b></td>
                    </tr>

                    <tr>
                        <td className={'mr-1'}><label>Country of registration:</label></td>
                        <td id="AV_countryrg"><b>{this.state.vehicleDetails['registration']['country']}</b></td>
                    </tr>

                    {livery}
                </tbody>
            </table>
        }
        return (
            <div className="card">
                <div className="card-header">
                    <div><h4 className="card-title">Registration</h4></div>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>

                </div>
            </div>
        );
    }
}

export default VehicleDetailsRegistrationCard;