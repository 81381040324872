import React from 'react';

// import classes from './Button.css';

const Button = (props) => (
    <button
        disabled={props.disabled}
        className="btn btn-primary btn-sm"
        onClick={props.clicked}>{props.children}</button>
);

// const Button = (props) => (
//     <button
//         disabled={props.disabled}
//         className={[classes.Button, classes[props.btnType]].join(' ')}
//         onClick={props.clicked}>{props.children}</button>
// );

export default Button;