import React from 'react';

import getPreSignedUrl from "../../common/functions/get_presigned_url";
import ParseLocation from "../../common/functions/parse_location";
import getApiUrl from "../../common/functions/get_api_url";

import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";

import ViewActionBar from "../../common/viewActionBar";


class CustomerDetailCard extends React.Component {
    // Standard constructor, with state for 'customer', defaulting to null
    constructor(props) {
        super(props);
        this.state = {loading: true}
    }

    // React Component Lifecycle events
    componentDidMount() {
        let {objType, objId, objAction} = ParseLocation()
        fetch(getApiUrl(objType, [["Customer_ID", objId],]))
            .then(res => res.json())
            .then((data) => {
                if (Array.isArray(data)) {
                    let msg = "Multiple Customers returned for id: " + objId + ". Using first returned row";
                    console.log(msg);
                    window.alert(msg);
                    data = data[0]
                }
                this.setState({ customer: data })
                this.setState({loading: false})
                getPreSignedUrl('img_logo', data.graphic, 'darwin-customer-graphics');
            })
            .catch(function (e) {
                console.log('Nothing returned' + e)
                this.setState({loading: false})
                this.setState({ error: {id: objId, message: 'Web Service returned: data'+ e }})
            })

    }

    render() {
        // Conditionally create content
        let logo_img = '';
        if (!this.state.loading && this.state.customer.graphic !== '' && this.state.customer.graphic !== undefined) {
            logo_img = <img id="img_logo" src="" height="30" width="auto"
                            alt="Customer logo"
                            className="float-left mr-1"
            />
        }

        let page_header = '';
        if (!this.state.loading) {
            page_header =
                <div className="content-header-left col-md-12 col-12 mb-2">
                    <div className="content-header-title"><h3>{this.state.customer.customer_name}</h3></div>
                    <div>
                        <ViewActionBar/>
                    </div>
                </div>
        }

        let card_header = '';
        if (!this.state.loading) { card_header =
            <span>
                {logo_img}
                {this.state.customer.customer_name} Details:
                </span>
        }

        let card_content = '';
        if (!this.state.loading) {
            card_content =
                <form className="form">
                    <div className="form-body">
                        <div className="form-group clearfix">
                            <div className="float-left col-md-3">Customer Name:</div>
                            <div className="float-left col-md-3" id="customer_name">{this.state.customer.customer_name}</div>
                        </div>
                        <div className="form-group clearfix">
                            <div className="float-left col-md-3">Contract:</div>
                            <div className="float-left col-md-3" id="contract">{this.state.customer.contract}</div>
                        </div>
                        <div className="form-group clearfix">
                            <div className="float-left col-md-3">Num of Vehicles:</div>
                            <div className="float-left col-md-3" id="num_vehicles">{this.state.customer.vehicles}</div>
                        </div>
                        <div className="form-group clearfix">
                            <div className="clearfix col-md-3">Notes:</div>
                            <div className="col-md-6" id="customer_name">{this.state.customer.notes}</div>
                        </div>

                    </div>
                </form>
        }

        if (this.state.loading) { card_content =
            <Spinner />
        }
        return (
            // CARD CONTAINER
            <div className="card overflow-auto ">

                {/* CARD CONTENT HEADER */}
                <div className="card-header">
                    <h4 className="card-title">{ card_header }</h4>
                    <CardHeaderControls/>
                </div>

                {/* CARD CONTENT BODY */}
                <div className="card-content show">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerDetailCard;