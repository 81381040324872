let moment = require('moment');

function checkValidity(updatedFormElement) {
    let value = updatedFormElement.value;
    let rules = updatedFormElement.validation;
    let errors = [];
    if (!rules) {
        return true;
    }
    if (rules.required) {
        let isValid = (value.trim() !== '');
        if (!isValid) {
            errors.push('This field is required and cannot be left empty.');
        }
    }
    if (rules.minLength) {
        let isValid = (value.length >= rules.minLength);
        if (!isValid) {
            errors.push('At least ' + rules.minLength + ' must be entered.');
        }
    }
    if (rules.maxLength) {
        let isValid = (value.length <= rules.maxLength);
        if (!isValid) {
            errors.push('Your entry exceeds the maximum length of  ' + rules.maxLength + ' by ' + (value.length - rules.maxLength) + ' characters.');
        }
    }
    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let isValid = pattern.test(value);
        if (!isValid) {
            errors.push('Please enter a valid email address.');
        }
    }
    if (rules.isNumeric) {
        let pattern = /^[-+]?\d*?[.]?\d*$/
        let isValid = value.trim() === '' ? true:  pattern.test(value);
        if (!isValid) {
            errors.push('Numeric field: Invalid characters detected, only numbers may be entered');
        }
    }
    if (rules.isDate) {
        try {
            let isValid = true;
            let date_pattern = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
            if (value.trim() !== '') {
                isValid = date_pattern.test(value);
                if (isValid) {
                    isValid = moment(value, moment.ISO_8601, true).isValid()
                    if (!isValid) {
                        errors.push("D")
                    }
                }
            }
        } catch(err) {
            errors.push('Date field: the date format could not be understood')
        }
    }
    if (rules.isDecimal) {
        const pattern = /^\d+$/;
        let isValid = value.trim() === '' ? true:  pattern.test(value);
        if (!isValid) {
            errors.push('Decimal field: Invalid characters detected, only integer numbers may be entered');
        }
    }
    if (rules.isColourCodeHex) {
        const pattern = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
        let isValid = value.trim() === '' ? true:  pattern.test(value);
        if (!isValid) {
            errors.push('Hex colour code field: Invalid format or characters detected should be like "#FFFFFF".');
        }
    }
    if (rules.min !== undefined) {
        let isValid = value.trim() === '' ? true: parseFloat(value) >= rules.min;
        if (!isValid) {
            errors.push('The entered number is too small.');
        }
    }
    if (rules.max !== undefined) {
        let isValid = value.trim() === '' ? true: parseFloat(value) <= rules.max;
        if (!isValid) {
            errors.push('The entered number is too large.');
        }
    }
    updatedFormElement.valid = errors.length === 0;
    updatedFormElement.errors = errors;
    return updatedFormElement.valid;
};

export default checkValidity;