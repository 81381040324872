import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";

class VehicleLiveryDetailColorsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: null,
            liveryDetail: null
        }
    }

    componentDidMount() {
        // getVehicleLiveryData(null, 'Template',
        //     (data) => {this.setState({liveryList: data })})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
    }

    // componentWillUnmount() {
    // }

    render() {
        debugger
        // Always start with a spinner
        let card_content = <Spinner />
        // Once the Card data has been loaded then show the real content
        if (this.state.liveryDetail !== null) {
            card_content = []
                card_content.push(
                    <div className="row bm-20">
                        <label htmlFor="camera">Zoom:</label>
                        <input className="custom-range" type="range" id="camera" min="20" max="90" step="1" value={this.state.liveryDetail.Camera_Zoom} />
                    </div>
                )

                card_content.push(
                    <div className="row bm-20">
                        <label htmlFor="alpha">Angle Alpha</label>
                        <input className="custom-range" type="range" id="alpha" min="0" max="100" step="1" value={this.state.liveryDetail.Camera_Up_Down} />
                    </div>
                )

                card_content.push(
                    <div className="row bm-20">
                        <label htmlFor="beta">Beta</label>
                        <input className="custom-range float-right"
                                type="range" id="beta" min="-180" max="180" step="1"value={this.state.liveryDetail.Camera_Rotation} />
                    </div>
                )
        }

        return (
            <div className="card">
                <div className="card-header">
                    {/*<div><h4 className="card-title">Registration</h4></div>*/}
                    <div>Camera Viewpoint and Zoom</div>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>

                </div>
            </div>
        );
    }
}

export default VehicleLiveryDetailColorsCard;