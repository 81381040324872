import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer footer-static footer-transparent">
                <p className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
                    <span className="float-md-left d-block d-md-inline-block">Copyright  &copy; 2020
                        <a className="text-bold-800 grey darken-2 ml-1 mr-1"
                           href="https://Darwincav.com"
                           target="_blank" rel="noopener noreferrer">Darwin CAV,</a>
                        All rights reserved.
                    </span>
                    {/*<span className="float-md-right d-block d-md-inline-blockd-none d-lg-block">*/}
                    {/*    <i className="ft-heart pink"></i>*/}
                    {/*</span>*/}
                </p>
            </footer>
        );
    }
}

export default Footer;