import React from 'react';
import $ from 'jquery'

import Breadcrumbs from "../../common/breadcrumbs";
import EditActionBar from "../../common/EditActionBar";
import CustomerAddCard from "../../customer/cards/customerAddCard";
import axios from "axios";
import getApiUrl from "../../common/functions/get_api_url";
import DashboardMapCard from "../../dashboard/cards/dashboardMapCard";

// To get the data from each of the cards we
// use the updatePageState_n to set the send data state attribute
// the card will then execute the componentDidUpdate function which will
// see that state.sendData is true and then call the page's updatePageHandler and reset it's sendData to false
// when all cards have sent their state then the webservice is called to update the server.


class CustomerAdd extends React.Component {
    constructor(props) {
        super(props);
        // Called by Save button on the Edit Action Bar
        this.formSaveHandler = this.formSaveHandler.bind(this)
        this.updatePageHandler = this.updatePageHandler.bind(this)
        this.updateCardState_1 = React.createRef()
        this.state = {
            customerDetails: {}
        };
    };

    updatePageHandler(data, node=null) {
        if (node === null) {
            // Update entire data node
            this.setState({customerDetails: data})
        } else {
            // Update sub-node
            let updateDetails = this.state.customerDetails;
            updateDetails[node] = data
            this.setState({customerDetails: updateDetails});
        }
    }

    saveFormData(data) {
        debugger
        this.setState( { loading: true } );
        let url = getApiUrl('customer')
        let bodydata = data['customerDetails']
        const res = axios.post(url, bodydata,
            {
                "headers": {"x-api-key": "PayIA1Z1eh5QHesZKzjob5qfKsWA3OmM1LY4tzfq"}
            }
            )
            .then( response => {
                this.setState( { loading: false } );
                // this.props.history.push( '/' );
                this.props.history.goBack();
                if(res) {
                    // console.log(res.data.json)
                }
            } )
            .catch( error => {
                if(res) {
                    // console.log(res.data.json)
                }
                this.setState( { loading: false } );
            })
    };

    formSaveHandler = ( event ) => {
        debugger
        event.preventDefault();
        this.setState( { loading: true } );
        // this.updateCardState_1.current.setState({sendData: true})
        let cardRefs = [this.updateCardState_1];
        let dirtyCards = 0;
        let validCards = 0;
        cardRefs.forEach(card => {
            let cardIsValid = card.current.state.formIsValid;
            let cardIsDirty = card.current.state.formIsDirty;
            if (cardIsDirty) {
                dirtyCards += 1;
            }
            if (cardIsValid) {
                validCards += 1;
            }
        });
        if (dirtyCards === 0) {
            console.log("No cards were modified, navigating to previous screen");
            this.props.history.push('/Customers')
            this.props.history.goBack();

        } else if (validCards < dirtyCards) {
            console.log("Some modified cards were not valid, prompting user")
            window.alert("Please address the reported errors")

        } else {
            let customerDetails = this.state.customerDetails;
            cardRefs.forEach(card => {
                debugger
                let [node, cardData] = card.current.getCardData();
                if (node === null) {
                    customerDetails = cardData;
                } else {
                    customerDetails[node] = cardData
                }
                // this.updatePageHandler(customerDetails);
            });
            this.saveFormData(customerDetails);
            this.setState({customerDetails: customerDetails, loading: false})
        }
    };

    render() {
        return (
            <div className="app-content content">
                <div className="content-wrapper">

                    {/* PAGE CONTENT HEADER */}
                    <div className="content-header row">
                        <div className={"content-header-left col-md-12 col-12"}>
                            <h2 className={"content-header-title"}>Customer</h2>
                        </div>
                        <div className="content-header row">
                            <div className={"content-header-left col-md-12 col-12 mb-1"}>
                                <Breadcrumbs/>
                            </div>
                        </div>
                    </div>

                    {/* PAGE CONTENT BODY */}
                    <div className="content-body col-md-12 col-12">
                        <div className={"container col-md-12 col-12"}>
                            <div className={"row col-12"}>
                                <div className={"col col-md-12 col-12 content"}>
                                    <EditActionBar {...this.props} saveFormHandler={this.formSaveHandler}/>
                                </div>
                            </div>

                            <div className={"row col-12"}>
                                <div className={"col col-md-12 col-12 content"}>
                                    <CustomerAddCard {...this.props} ref={this.updateCardState_1} updatePageHandler={this.updatePageHandler}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerAdd;