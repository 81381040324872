import React from 'react';

// duplicated from Common.js
let urlCurrent = new URL(document.URL);
let params = new URLSearchParams(urlCurrent.search);
let objType ='';
let objId = '';
if (params.get('route')) {
    let pos = -1;
    let pageTypes = ['List', 'Detail', 'Edit', 'Add'];
    pageTypes.forEach(async function(pageType) {
        if (pos === -1) {
            pos = params.get('route').indexOf(pageType);
            if (pos >= 0) {
                objType = params.get('route').substr(0, pos);
                objId = params.get('current'+objType); // defaults to null if key doesn't exist
            }
        }
    });
}
let objLink;
if (params.get('route')) {
    if (objId) {
        objLink = <li className="breadcrumb-item"><a id={'bcLinkItem'}>{objId}</a></li>
    } else if (params.get('route').indexOf('Add') >= 0) {
        objLink = <li className="breadcrumb-item">{objId}</li>
    }
}

class MessagesDropdown extends React.Component {
    render() {
        return (
            <span>
                <a className="nav-link nav-link-label" href="#" data-toggle="dropdown">
                    <i className="ficon ft-mail"></i>
                    <span
                        className="badge badge-pill badge-default badge-info badge-default badge-up">0 </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                    <li className="dropdown-menu-header">
                        <h6 className="dropdown-header m-0">
                            <span className="grey darken-2">Messages</span>
                        </h6>
                        <span
                            className="notification-tag badge badge-default badge-warning float-right m-0">0 New</span>
                    </li>
                    <li className="scrollable-container media-list w-100">
                        <a href="javascript:void(0)">
                            <div className="media">
                                <div className="media-left">
                                    <span className="avatar avatar-sm avatar-online rounded-circle">
                                        <img
                                            // src="app-assets/images/portrait/small/avatar-s-19.png"
                                            src="app-assets/images/portrait/small/avatar-s-19.png"
                                            alt="avatar"/>
                                        <i></i>
                                    </span>
                                </div>
                                <div className="media-body">
                                    <h6 className="media-heading">Margaret Govan</h6>
                                    <p className="notification-text font-small-3 text-muted">I like your
                                        portfolio, let's start.</p>
                                    <small>
                                        <time className="media-meta text-muted"
                                              dateTime="2015-06-11T18:29:20+08:00">Today
                                        </time>
                                    </small>
                                </div>
                            </div>
                        </a>
                        <a href="javascript:void(0)">
                            <div className="media">
                                <div className="media-left">
                                    <span className="avatar avatar-sm avatar-away rounded-circle">
                                        <img
                                            src="app-assets/images/portrait/small/avatar-s-6.png"
                                            alt="avatar"/>
                                         <i></i>
                                    </span>
                                </div>
                                <div className="media-body">
                                    <h6 className="media-heading">Eric Alsobrook</h6>
                                    <p className="notification-text font-small-3 text-muted">We have project
                                        party this saturday.</p>
                                    <small>
                                        <time className="media-meta text-muted"
                                              dateTime="2015-06-11T18:29:20+08:00">last month
                                        </time>
                                    </small>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="dropdown-menu-footer">
                        <a className="dropdown-item text-muted text-center"
                           ref="javascript:void(0)">Read all messages</a>
                    </li>
                </ul>
            </span>
        );
    }
}

export default MessagesDropdown;