import React from 'react';
import { NavLink } from "react-router-dom"
import ParseLocation from "./functions/parse_location";


class Breadcrumbs extends React.Component {
    render() {
        let { objType, objId, objAction } = ParseLocation()
        let linkHome = "";
        let linkType = "";
        let linkObj = "";

        if(objType !=="") {
            linkHome = <li className="breadcrumb-item"><NavLink to="/" className="breadcrumb-item">Home</NavLink></li>
        } else {
            linkHome = <li className="breadcrumb-item"><NavLink to="/" className="breadcrumb-item active">Home</NavLink></li>
        }


        if(objType !=="") {
            if(objId !=="") {
                linkType = <li className="breadcrumb-item"><NavLink to={'/' + objType + 's'} className="breadcrumb-item">{objType}</NavLink></li>
            } else {
                linkType = <li className="breadcrumb-item"><NavLink to={'/' + objType + 's'} className="breadcrumb-item active">{objType}</NavLink></li>
            }
        }

        if(objId !=="") {
            linkObj = <li className="breadcrumb-item"><NavLink to={'/' + objType + '/Detail/' + objId} className="breadcrumb-item active">{objId}</NavLink></li>
        }

        return (
            <div className="content-header-title breadcrumbs-top">
                <div className="breadcrumb-wrapper col-12">
                    <ol className="breadcrumb" id="breadcrumbs">
                        {linkHome}
                        {linkType}
                        {linkObj}
                    </ol>
                </div>
            </div>
        );
    }
}

export default Breadcrumbs;