import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";
import checkValidity from "../../common/functions/check_validity";
import Input from "../../UI/Input/Input";

class VehicleLiveryEditCameraCard extends React.Component {
    constructor(props) {
        super(props);
        this.updateHandler = this.updateHandler.bind(this)
        this.state = {
            vehicleDetail: null,
            liveryDetail: null,
            formIsValid: true,
            loading: true,
            cameraForm: {
                Vehicle_ID: {
                    elementType: 'hidden',
                    elementConfig: {
                        type: 'hidden',
                    },
                    label: 'Livery ID:',
                    value: '',
                    valid: true,
                    errors: [],
                },
                Camera_Zoom: {
                    elementType: 'rangeslider',
                    elementConfig: {
                        onChange: this.updateHandler,
                        min: "-180",
                        max: "180",
                        step: "1"
                    },
                    label: 'Camera Zoom:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Camera_Up_Down: {
                    elementType: 'rangeslider',
                    elementConfig: {
                        onChange:  this.updateHandler,
                        min: "20",
                        max: "90",
                        step: "1"
                    },
                    label: 'Camera Up/Down:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                },
                Camera_Rotation: {
                    elementType: 'rangeslider',
                    elementConfig: {
                        changed: this.updateHandler,
                        min: "0",
                        max: "100",
                        step: "1"
                    },
                    label: 'Camera Rotation:',
                    value: '',
                    validation: {
                        required: true,
                        isColourCodeHex: true
                    },
                    valid: true,
                    errors: [],
                }
            }
        }
    };

    updateHandler = (event) => {
        debugger
        let fieldName = event.currentTarget.field;
        let value = event.currentTarget.value;
        // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
        let updatedForm = { ...this.state.cameraForm };
        let updatedFormElement = {...updatedForm[fieldName]};
        updatedFormElement.value = value;
        checkValidity(updatedFormElement);
        updatedFormElement.touched = true;
        updatedForm[fieldName] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({cameraForm: updatedForm });
    }

    // componentDidMount() {
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.liveryDetail !== prevState.liveryDetail) {
            debugger
            // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
            let updateForm = { ...this.state.cameraForm };
            Object.keys(this.state.liveryDetail).forEach(field => {
                if (this.state.cameraForm.hasOwnProperty(field)) {
                    let updateElement = { ...updateForm[field] };
                    updateElement.value = this.state.liveryDetail[field];
                    updateForm[field] = { ...updateElement };
                }
            });
            this.setState({cameraForm: { ...updateForm }})
        }
    };


    // componentWillUnmount() {
    // }

    render() {

        // Always start with a spinner
        let card_content = <Spinner />

        // Once the Card data has been loaded then show the real content
        // if (this.state.liveryDetail !== null) {
        //     card_content = processRows(this.state.liveryDetail)
        // }
        if (this.state.liveryDetail !== null && this.state.liveryDetail.Vehicle_ID === this.state.cameraForm.Vehicle_ID.value) {
            card_content = []
            debugger
            Object.keys(this.state.cameraForm).forEach((key, index) => {
                let input_content = <Input {...this.state.cameraForm[key]} field={key} />
                card_content.push(input_content);
            })
        }

        // Once the Card data has been loaded then show the real content
        // if (this.state.liveryDetail !== null) {
        //     card_content = []
        //         card_content.push(
        //             <div className="row bm-20">
        //                 <label htmlFor="camera">Zoom:</label>
        //                 <input className="custom-range" type="range" id="camera" min="20" max="90" step="1"
        //                        value={this.state.liveryDetail.Camera_Zoom}
        //                        onChange={this.updateHandler}
        //                 />
        //             </div>
        //         )
        //
        //         card_content.push(
        //             <div className="row bm-20">
        //                 <label htmlFor="alpha">Angle Alpha</label>
        //                 <input className="custom-range" type="range" id="alpha" min="0" max="100" step="1"
        //                        value={this.state.liveryDetail.Camera_Up_Down}
        //                        onChange={this.updateHandler}
        //                 />
        //             </div>
        //         )
        //
        //         card_content.push(
        //             <div className="row bm-20">
        //                 <label htmlFor="beta">Beta</label>
        //                 <input className="custom-range float-right"
        //                        type="range" id="beta" min="-180" max="180" step="1"
        //                        value={this.state.liveryDetail.Camera_Rotation}
        //                        onChange={this.updateHandler}
        //                 />
        //             </div>
        //         )
        // }

        return (
            <div className="card">
                <div className="card-header">
                    {/*<div><h4 className="card-title">Registration</h4></div>*/}
                    <div>Camera Viewpoint and Zoom</div>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>

                </div>
            </div>
        );
    }
}

export default VehicleLiveryEditCameraCard;