import React from 'react';

// duplicated from Common.js
let urlCurrent = new URL(document.URL);
let params = new URLSearchParams(urlCurrent.search);
let objType ='';
let objId = '';
if (params.get('route')) {
    let pos = -1;
    let pageTypes = ['List', 'Detail', 'Edit', 'Add'];
    pageTypes.forEach(async function(pageType) {
        if (pos === -1) {
            pos = params.get('route').indexOf(pageType);
            if (pos >= 0) {
                objType = params.get('route').substr(0, pos);
                objId = params.get('current'+objType); // defaults to null if key doesn't exist
            }
        }
    });
}
let objLink;
if (params.get('route')) {
    if (objId) {
        objLink = <li className="breadcrumb-item"><a id={'bcLinkItem'}>{objId}</a></li>
    } else if (params.get('route').indexOf('Add') >= 0) {
        objLink = <li className="breadcrumb-item">{objId}</li>
    }
}

class NotificationsDropdown extends React.Component {
    render() {
        return (
            <span>
                <a className="nav-link nav-link-label" href="#" data-toggle="dropdown">
                    <i className="ficon ft-bell"></i>
                    <span className="badge badge-pill badge-default badge-danger badge-default badge-up">0</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                    <li className="dropdown-menu-header">
                        <h6 className="dropdown-header m-0"><span
                            className="grey darken-2">Notifications</span>
                        </h6>
                        <span
                            className="notification-tag badge badge-default badge-danger float-right m-0">0 New</span>
                    </li>
                    <li className="scrollable-container media-list w-100">
                        <a href="javascript:void(0)">
                            <div className="media">
                                <div className="media-left align-self-center"><i
                                    className="ft-plus-square icon-bg-circle bg-cyan"></i>
                                </div>
                                <div className="media-body">
                                    <h6 className="media-heading">New customer addedd!</h6>
                                    <p className="notification-text font-small-3 text-muted">Lorem ipsum
                                        dolor sit amet, consectetuer
                                        elit.</p>
                                    <small>
                                        <time className="media-meta text-muted"
                                              dateTime="2015-06-11T18:29:20+08:00">30 minutes ago
                                        </time>
                                    </small>
                                </div>
                            </div>
                        </a>

                        <a href="javascript:void(0)">
                            <div className="media">
                                <div className="media-left align-self-center">
                                    <i className="ft-file icon-bg-circle bg-teal"></i>
                                </div>
                                <div className="media-body">
                                    <h6 className="media-heading">Generate monthly report</h6>
                                    <small>
                                        <time className="media-meta text-muted"
                                              dateTime="2015-06-11T18:29:20+08:00">Last month
                                        </time>
                                    </small>
                                </div>
                            </div>
                        </a>
                    </li>

                    <li className="dropdown-menu-footer">
                        <a className="dropdown-item text-muted text-center"
                           href="javascript:void(0)">
                            <span>Read all notifications</span>
                        </a>
                    </li>
                </ul>

            </span>
        );
    }
}

export default NotificationsDropdown;