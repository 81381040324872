import React from 'react';
import $ from 'jquery'
import '../../../'

import Breadcrumbs from "../../common/breadcrumbs";
import VehicleEditRegistrationCard from "../cards/vehicleEditRegistrationCard";
import VehicleEditInsuranceCard from "../cards/vehicleEditInsuranceCard";
import VehicleEditExciseDutyCard from "../cards/vehicleEditExciseDutyCard";
import VehicleEditMOTCard from "../cards/vehicleEditMOTCard";
import VehicleEditSensorCard from "../cards/vehicleEditSensorSummaryCard";
import ParseLocation from "../../common/functions/parse_location";
import getVehicleDetails from "../../common/functions/vehicle_details_api";
import EditActionBar from "../../common/EditActionBar";


class VehicleEdit extends React.Component {
    constructor(props) {
        super(props);
        this.formSaveHandler = this.formSaveHandler.bind(this)
        this.updatePageHandler = this.updatePageHandler.bind(this)
        this.updateRegistrationCard = React.createRef()
        this.updateInsuranceCard = React.createRef()
        this.updateExciseCard = React.createRef()
        this.updateMOTCard = React.createRef()
        this.updateSensorCard = React.createRef()

        this.state = {
            vehicleDetails: {},
        }
    };
    componentDidMount() {
        let {objType, objId, objAction} = ParseLocation()
        if(objId !== "") {
            getVehicleDetails(objId,
                (data) => {
                    debugger;
                    this.setState({vehicleDetails: data });
                }
            )
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateRegistrationCard.current.setState({vehicleDetails: this.state.vehicleDetails})
        this.updateInsuranceCard.current.setState({vehicleDetails: this.state.vehicleDetails})
        this.updateExciseCard.current.setState({vehicleDetails: this.state.vehicleDetails})
        this.updateMOTCard.current.setState({vehicleDetails: this.state.vehicleDetails})
        this.updateSensorCard.current.setState({vehicleDetails: this.state.vehicleDetails})
    };

    // componentWillUnmount() {
    // };

    updatePageHandler(data, node=null) {
        if (node === null) {
            // Update entire data node
            this.setState({customerDetails: data})
        } else {
            // Update sub-node
            let updateDetails = this.state.customerDetails;
            updateDetails[node] = data
            this.setState({customerDetails: updateDetails});
        }
    };

    formSaveHandler = ( event ) => {
        debugger
        event.preventDefault();
        this.setState( { loading: true } );
        // this.updateCardState_1.current.setState({sendData: true})
        let cardRefs = [
            this.updateExciseCard,
            this.updateInsuranceCard,
            this.updateMOTCard,
            this.updateRegistrationCard,
            this.updateSensorCard];
        let dirtyCards = 0;
        let validCards = 0;
        cardRefs.forEach(card => {
            let cardIsValid = card.current.state.formIsValid;
            let cardIsDirty = card.current.state.formIsDirty;
            if (cardIsDirty) {
                dirtyCards += 1;
            }
            if (cardIsValid) {
                validCards += 1;
            }
        });
        if (dirtyCards === 0) {
            console.log("No cards were modified, navigating to previous screen");
            this.props.history.push('/Customers')
            this.props.history.goBack();

        } else if (validCards < dirtyCards) {
            console.log("Some modified cards were not valid, prompting user")
            window.alert("Please address the reported errors")

        } else {
            let customerDetails = this.state.customerDetails;
            cardRefs.forEach(card => {
                debugger
                let [node, cardData] = card.current.getCardData();
                if (node === null) {
                    customerDetails = cardData;
                } else {
                    customerDetails[node] = cardData
                }
                // this.updatePageHandler(customerDetails);
            });
            this.saveFormData(customerDetails);
            this.setState({customerDetails: customerDetails, loading: false})
        }
    };

    render() {
        return (
            <div className="app-content content">
                <div className="content-wrapper">

                    {/* PAGE CONTENT HEADER */}
                    <div className="content-header row">
                        <div className={"content-header-left col-md-12 col-12"}>
                            <h2 className={"content-header-title"}>Edit Vehicle</h2>
                        </div>
                        <div className="content-header row">
                            <div className={"content-header-left col-md-12 col-12 mb-1"}>
                                <Breadcrumbs/>
                                <EditActionBar />
                            </div>
                        </div>
                    </div>

                    {/* PAGE CONTENT BODY */}
                    <div className="content-body">
                        <div className={"container"}>
                            <div className="row">
                                <div className=" col-md-6 col-12">
                                    <VehicleEditRegistrationCard {...this.props} ref={this.updateRegistrationCard}/>
                                </div>

                                <div className=" col-md-6 col-12">
                                    <VehicleEditInsuranceCard {...this.props} ref={this.updateInsuranceCard}/>
                                </div>

                                <div className=" col-md-6 col-12">
                                    <VehicleEditExciseDutyCard {...this.props} ref={this.updateExciseCard}/>
                                </div>

                                <div className=" col-md-6 col-12">
                                    <VehicleEditMOTCard {...this.props} ref={this.updateMOTCard}/>
                                </div>

                                <div className=" col-md-12 col-12">
                                    <VehicleEditSensorCard {...this.props} ref={this.updateSensorCard}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VehicleEdit;