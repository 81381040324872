import React from 'react';
import '../../';
// import CardHeaderControls from "../common/cardHeaderControls";
// import Breadcrumbs from "../common/breadcrumbs";
// import ViewActionBar from "../common/ViewActionBar";

// let urlCurrent = new URL(document.URL);
// let params = new URLSearchParams(urlCurrent.search);
// let objType = (params.get('route')) ? params.get('route').slice(0, params.get('route').indexOf('Detail')): '';
// let objName = params.get('current'+objType);

class UnderConstruction extends React.Component {
    render() {
        return (
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2">

                        </div>
                    </div>
                    <div className="content-body">
                        <section className="card">
                            <div id="invoice-template" className="card-body">
                                <div id="invoice-company-details" className="row">
                                    <div className="col-md-6 col-sm-12 text-center text-md-left">
                                        Under Construction
                                        <br />
                                        Please try again after the updates
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        )
    }
}

export default UnderConstruction;
