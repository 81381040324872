import React from 'react';
import mapboxgl from "mapbox-gl";
import createMap from "./map";

// Richard's 'Development' Token
mapboxgl.accessToken = 'pk.eyJ1Ijoicmx0aG9tYXNlc3EiLCJhIjoiY2tuaG4wOW42MjMwazJ3bWl5ZmNxc2RiaiJ9.-kQ_QGyLAmtq7p_f0d3wtA';

class MapContainer extends React.Component {
    // map box code id here
    constructor(props) {
        super(props);
        this.childStateHandler = this.childStateHandler.bind(this);
        this.state = {
            isLoaded: false,
            initialMapDataLoaded: false,
            mapSlideOutId: '',
            mapSlideOutData: {},
            lat: 48.373,
            lng: 12.803,
            zoom: 2
        };
    };
    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }
    childStateHandler() {
        // window.alert('child state handler - map container')
        this.setState({
            someVar: 'some value'
        })
    }

    // https://www.pluralsight.com/guides/how-to-use-geolocation-call-in-reactjs
    componentDidMount() {
        // default to europe-wide to begin with
        // this.setState({
        //     lat: 48.373,
        //     lng: 12.803,
        //     zoom: 2})
        // this.state.lat = 48.373;
        // this.state.lng = 12.803;
        // this.state.zoom = 2;
        this.map = createMap(this);
        setTimeout(() => {
            this.map.getVehicleLocations();
            let dt_now = new Date()
            console.log("Getting Vehicle Locations: " + dt_now.toLocaleTimeString())
        }, 100)

        // Create a timer to periodically get vehicle positions
        // this.timerID = setInterval(
        //     () => this.map.getVehicleLocations(),
        //     10000
        // );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.props.updateParentHandler({vehicle_id: this.state.vehicle_id})
    }

    componentWillUnmount() {
        // dispose of the timer
        clearInterval(this.timerID);
    }

    render() {
        return (
            <div>
                {/*<div>Customer ID: {this.state.Customer_ID}</div>*/}
                {/*<div>Vehicle ID: {this.state.vehicle_id}</div>*/}
                {/*<button onClick={() => this.props.updateParentHandler({Customer_ID: 'MapContainer-cid'})} > MapContainer - Set Customer </button>*/}
                <div ref={el => this.mapContainer = el} className='mapContainer'/>
                    <div className='sidebarStyle'>
                        <div>Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom: {this.state.zoom}</div>
                    </div>
                <p></p>
            </div>
        )
    }
}

export default MapContainer;