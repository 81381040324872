import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
// import ParseLocation from "../../common/functions/parse_location";
import checkValidity from "../../common/functions/check_validity";
import Input from "../../UI/Input/Input"

class VehicleLiveryHeaderCard extends React.Component {
    constructor(props) {
        super(props);
        this.updateHandler = this.updateHandler.bind(this)
        this.state = {
            liveryDetail: null,
            formIsValid: true,
            loading: true,
            liveryForm: {
                Vehicle_ID: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        maxLength: 30
                    },
                    label: 'Livery ID:',
                    value: '',
                    valid: true,
                    errors: [],
                },
                Description: {
                    elementType: 'textarea',
                    elementConfig: {
                        rows: 5
                    },
                    label: 'Description:',
                    value: '',
                    valid: true,
                    errors: [],
                },
                isTemplate: {
                    elementType: 'hidden',
                    elementConfig: {
                        type: 'hidden',
                    },
                    label: 'Is a Template:',
                    value: true,
                    valid: true,
                    errors: [],
                },
            }
        }
    }

    updateHandler = (fieldName, value) => {
        // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
        let updatedForm = { ...this.state.liveryForm };
        let updatedFormElement = {...updatedForm[fieldName]};
        updatedFormElement.value = value;
        checkValidity(updatedFormElement);
        updatedFormElement.touched = true;
        updatedForm[fieldName] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({liveryForm: updatedForm });
    }


    // componentDidMount() {
    //     const {objType, objId, objAction} = ParseLocation()
    //     if(objId !== null) {
    //         this.updateHandler('Vehicle_ID', objId)
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        // if vehicle_id is set then this livery is for a vehicle and not is not a template
        if(this.state.vehicle_id !== prevState.vehicle_id) {
            this.updateHandler('isTemplate', false)
        }
        if(this.state.liveryDetail !== prevState.liveryDetail) {
            // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
            let updateForm = { ...this.state.liveryForm };
            Object.keys(this.state.liveryDetail).forEach(field => {
                if (this.state.liveryForm.hasOwnProperty(field)) {
                    let updateElement = { ...updateForm[field] };
                    updateElement.value = this.state.liveryDetail[field];
                    updateForm[field] = { ...updateElement };
                }
            });
            this.setState({liveryForm: { ...updateForm }})
        }
    }

    // componentWillUnmount() {
    // }

    render() {
        // const {objType, objId, objAction} = ParseLocation();

        // // Always start with a spinner
        // let card_content = <Spinner />

        // Once the Card data has been loaded then show the real content
        // if (this.state.liveryDetail !== null) {
        //     card_content = processRows(this.state.liveryDetail)
        // }
        // if (this.state.liveryDetail !== null && this.state.liveryDetail.Vehicle_ID === this.state.liveryForm.Vehicle_ID.value) {
        let card_content = []
        Object.keys(this.state.liveryForm).forEach((key, index) => {
            let input_content = <Input {...this.state.liveryForm[key]} field={key} />
            card_content.push(input_content);
            })
        // }

        return (
            <div className="card">
                <div className="card-header">
                    {/*<div><h4 className="card-title">Registration</h4></div>*/}
                    <div></div>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleLiveryHeaderCard;