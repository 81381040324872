import React from 'react';
import CardHeaderControls from "../../common/cardHeaderControls";
import Spinner from "../../UI/Spinner/Spinner";

class VehicleDetailsInsuranceCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetails: {},
        }
    };

    // componentDidMount() {
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    // componentWillUnmount() {
    // }

    render() {
        // Always start with a spinner
        let card_content = <Spinner />
        // Once the Card data has been loaded then show the real content
        debugger

        if(this.state.vehicleDetails.hasOwnProperty('Vehicle_ID') && this.state.vehicleDetails.hasOwnProperty('Vehicle_ID')  !== "") {card_content =
            <table className="table">
                <tr>
                    <td><label>Insurance Provider </label></td>
                    <td id="AV_insurancecomp"><b>{this.state.vehicleDetails['insurance']['insurer']}</b></td>
                </tr>

                <tr>
                    <td><label>Policy Number:</label></td>
                    <td id="AV_policynumber"><b>{this.state.vehicleDetails['insurance']['policy_number']}</b></td>
                </tr>

                <tr>
                    <td><label>Policy Start</label></td>
                    <td><b>{this.state.vehicleDetails['insurance']['policy_start_date']}0</b></td>
                </tr>

                <tr>
                    <td><label>Policy Expire:</label></td>
                    <td id="AV_expire"><b>{this.state.vehicleDetails['insurance']['policy_end_date']}</b></td>
                </tr>

                {/*<tr>*/}
                {/*    <td><label>Claims:</label></td>*/}
                {/*    <td id="AV_claims"><b>{this.state.vehicleDetails['insurance']['claims']}</b></td>*/}
                {/*</tr>*/}

            </table>
        }
            return (
            <div className="card">
                <div className="card-header">
                    <div><h4 className="card-title">Insurance</h4></div>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleDetailsInsuranceCard;