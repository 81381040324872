import React from 'react';
import { Link } from "react-router-dom"
// import { Cookies } from 'react-cookie';

// const urlCurrent = new URL(document.URL);
class SideMenu extends React.Component {


    render() {

        return (
            <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow menu-border"
                 data-scroll-to-active="true">
                <div className="main-menu-content">
                    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">

                        <li className="nav-item" route='/'>
                            <Link to={"/"}>
                                <i className="icon-home"></i>
                                <span className="menu-title" data-i18n="nav.dash.main">Home</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to={"/Customers"}>
                                <i className="icon-check"></i>
                                <span className="menu-title" data-i18n="nav.scrumboard.main">Customers</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to={"/Vehicles"}>
                                <i className="icon-check"></i>
                                <span className="menu-title" data-i18n="nav.scrumboard.main">Vehicles</span>
                            </Link>
                        </li>

                        {/*<li className="nav-item" route='Telemetry'>
                            <Link to={"/Vehicle/Telemetry" + qs_auth} >
                                <i className="icon-home"></i>
                                <span className="menu-title" data-i18n="nav.dash.main">Telemetry</span>
                            </Link>
                        </li>*/}

                        {/*<li className="nav-item" route='FleetStatus'>*/}
                        {/*    <Link to={"/VehicleList"}>*/}
                        {/*        <i className="icon-screen-tablet"></i>*/}
                        {/*        <span className="menu-title" data-i18n="nav.templates.main">Fleet Status</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        <li className="nav-item" route='Reports'>
                            <Link to={"/Reports"}>
                                <i className="icon-docs"></i>
                                <span className="menu-title" data-i18n="nav.changelog.main">Reports</span>
                            </Link>
                        </li>

                        <li className="navigation-header">
                            <span data-i18n="nav.category.pages">More</span>
                            <i className="ft-more-horizontal ft-minus" data-toggle="tooltip" data-placement="right" data-original-title="Pages"></i>
                        </li>

                        <li className="nav-item" route='ControlPanel'>
                            <Link to="/ControlPanel">
                                <i className="icon-list"></i>
                                <span className="menu-title" data-i18n="nav.page-checkout">Control Panel</span>
                            </Link>
                        </li>

                        `<li className="nav-item">
                            <Link to="/VehicleLivery">
                                <i className="icon-list"></i>
                                <span className="menu-title" data-i18n="nav.page-checkout">Vehicle Livery</span>
                            </Link>
                        </li>

                        <li className="navigation-header">
                            <span data-i18n="nav.category.support">Support</span>
                            <i className="ft-more-horizontal ft-minus" data-toggle="tooltip" data-placement="right" data-original-title="Support"></i>
                        </li>

                        <li className="nav-item">
                            <a href="http://darwincav.com/">
                                <i className="icon-support"></i>
                                <span className="menu-title" data-i18n="nav.support_raise_support.main">Contact Support</span>
                            </a>
                        </li>

                        <li className="nav-item">
                            <a href="http://darwincav.com/">
                                <i className="icon-user"></i>
                                <span className="menu-title" data-i18n="nav.support_raise_support.main">Chat Support</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://darwincav.com">
                                <i className="icon-notebook"></i>
                                <span className="menu-title" data-i18n="nav.support_documentation.main">Documentation</span>
                            </a>
                        </li>
                    </ul>

                </div>
                {/*main-menu-content*/}
            </div>  // main-menu
        );
    }
}

export default SideMenu;