import React from 'react';

import DashboardMapCard from "../cards/dashboardMapCard";
import DashboardVehicleListCard from "../cards/dashboardVehicleListCard";
import Breadcrumbs from "../../common/breadcrumbs";
// import Unity, {UnityContext} from "react-unity-webgl";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        // this.childStateHandler = this.childStateHandler.bind(this)
        this.state = {
            Customer_ID: '', // customer
            Vehicle_ID: '', // vehicle id, set when map feature is clicked on
            vehicleDetails: '', // Details from the vehicles table
            vehicleTelemetry: '', // Telemetry -> Last Known Location
        }
    };

    render() {
        return (
            <div className="app-content content">
                <div className="content-wrapper">
                    {/*<div className="row mb-3 mt-2">*/}
                        {/*    <div className="offset-md-3 col-md-6 col-sm-12">*/}

                        {/*        <div className="browser">*/}
                        {/*            <iframe data-theme="default"></iframe>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    {/*</div>*/}

                    {/*<TelemetrySpeed />*/}
                    {/* PAGE CONTENT HEADER */}
                    <div className="content-header row">
                        <div className={"content-header-left col-md-12 col-12"}>
                            <h2 className={"content-header-title"}>Dashboard</h2>
                        </div>
                        <div className="content-header row">
                            <div className={"content-header-left col-md-12 col-12 mb-1"}>
                                <Breadcrumbs />
                            </div>
                        </div>
                    </div>
                    {/* PAGE CONTENT BODY */}
                    <div className="content-body col-md-12 col-12">
                        {/*<TelemetryBatteryLevel />*/}
                        <div className={"container col-md-12 col-12"}>

                            <div className={"row col-12"}>
                                <div className={"col col-md-6 col-12" }>
                                    <DashboardVehicleListCard {...this.props} ref={this.updateChildState} updateParentHandler={this.props.updateParentHandler}/>

                                </div>
                                <div className={"col col-md-6 col-12"}>
                                    {/*<div>Dashboard Customer ID:: {this.state.Customer_ID}</div>*/}
                                    {/*<div><button onClick={() => this.props.updateParentHandler({Customer_ID: 'Dashboard'})} > Dashboard - Set Customer </button></div>*/}

                                    <DashboardMapCard {...this.props} ref={this.updateChildState} updateParentHandler={this.props.updateParentHandler}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;