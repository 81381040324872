import React from 'react'

import ParseLocation from "../../common/functions/parse_location";
import getVehicleLiveryData from "../../common/functions/vehicle_livery_api";

import Breadcrumbs from "../../common/breadcrumbs";
import ViewActionBar from "../../common/viewActionBar";
import VehicleLiveryDetailColorsCard from "../cards/vehicleLiveryDetailColorsCard";
import VehicleLiveryDetailCameraCard from "../cards/vehicleLiveryDetailCameraCard";
import Vehicle3dModelCard from "../../vehicle/cards/vehicle3dModelCard";
import getVehicleTelemetryWs from "../../common/functions/vehicle_telemetry_api";
// import updateTelemetryHistory from "../../vehicle/functions/vehicle_history";

// const {objType, objId, objAction} = ParseLocation()
// TODO - implement Axios Cancel
// TODO - https://stackoverflow.com/questions/38329209/how-to-cancel-abort-ajax-request-in-axios

class VehicleLiveryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleTelemetry: {},
            liveryList: {},
            liveryDetail: null};
        this.updateHandler = this.updateHandler.bind(this);
        this.update3dModelCard = React.createRef();
        this.updateCameraCard = React.createRef();
        this.updateLiveryCard = React.createRef();
    };

    // Called by the child cards to update state across all components on page
    updateHandler(state) {
        this.setState(state);
    }

    componentDidMount() {
        const {objType, objId, objAction} = ParseLocation()
        // Get the saved livery data for this vehicle or template
        getVehicleLiveryData(objId, 'Template',
            (data) => {
                debugger;
                this.setState({liveryDetail: data });
            }
        )

        // Connect to the Vehicle Telemetry WebSocket API,
        try {
            getVehicleTelemetryWs(objId, (data) => {
                this.setState({vehicleTelemetry: data});
            });
        } catch (err) {
            console.log("Vehicle Telemetry Call Failed with Error: " + err);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        if(this.state.liveryDetail !== prevState.liveryDetail) {
            this.updateLiveryCard.current.setState({liveryDetail: this.state.liveryDetail});
            this.updateCameraCard.current.setState({liveryDetail: this.state.liveryDetail});
            this.update3dModelCard.current.setState({vehicleTelemetry: this.state.liveryDetail});
        }
    }

    // componentWillUnmount() {
    // }

    render() {
        let heading = this.state.liveryList.hasOwnProperty('Livery_Type') || this.state.liveryList.Livery_Type !== 'Template'
            ? 'Vehicle Livery'
            : 'Livery Template'

        return (
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2">
                            <span className="content-header-title">
                                { heading }
                            </span>
                            <Breadcrumbs/>
                            <ViewActionBar/>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <VehicleLiveryDetailColorsCard {...this.props} ref={this.updateLiveryCard} updateParentHander={this.updateHandler} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <VehicleLiveryDetailCameraCard {...this.props}  ref={this.updateCameraCard} updateParentHander={this.updateHandler} />
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <Vehicle3dModelCard {...this.props} ref={this.update3dModelCard} updateParentHander={this.updateHandler} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VehicleLiveryDetail;