import React from 'react';

// duplicated from Common.js
let urlCurrent = new URL(document.URL);
let params = new URLSearchParams(urlCurrent.search);
let objType ='';
let objId = '';
if (params.get('route')) {
    let pos = -1;
    let pageTypes = ['List', 'Detail', 'Edit', 'Add'];
    pageTypes.forEach(async function(pageType) {
        if (pos === -1) {
            pos = params.get('route').indexOf(pageType);
            if (pos >= 0) {
                objType = params.get('route').substr(0, pos);
                objId = params.get('current'+objType); // defaults to null if key doesn't exist
            }
        }
    });
}
let objLink;
if (params.get('route')) {
    if (objId) {
        objLink = <li className="breadcrumb-item"><a id={'bcLinkItem'}>{objId}</a></li>
    } else if (params.get('route').indexOf('Add') >= 0) {
        objLink = <li className="breadcrumb-item">{objId}</li>
    }
}

class AccountDropdown extends React.Component {
    render() {
        return (
            <span>
                <a className="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                            <span className="avatar avatar-online">
                                                <img src="/assets/img/2.jpg" id="userimage" alt="avatar"/>
                                                <i></i>
                                            </span>
                    <span className="user-name" id="txtusername">Milos</span>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="">
                        <i className="ft-user"></i>
                        <span>Edit Profile</span>
                    </a>
                    <a className="dropdown-item" href="user-cards.html">
                        <i className="ft-check-square"></i>
                        <span>Tasks</span>
                    </a>
                    <a className="dropdown-item" href="">
                        <i className="ft-message-square"></i>
                        <span>Chats</span>
                    </a>

                    <div className="dropdown-divider"></div>

                    <a className="dropdown-item"
                       href="javascript:window.location.replace('https://darwin.auth.eu-west-1.amazoncognito.com/logout?' +'client_id=1ji1o5fh67qv2ha62ujt04t0ve' +'&logout_uri=https://dev.cavcloud.com/index.html');"
                       onClick="javascript: signOut(); return false;">
                        <i className="ft-power"></i>
                        <span>Logout</span>
                    </a>
                </div>
            </span>
        );
    }
}

export default AccountDropdown;