import React from 'react';
import '../../../'

import Breadcrumbs from "../../common/breadcrumbs";
import EditActionBar from "../../common/EditActionBar";
import CustomerEditCard from "../../customer/cards/customerEditCard";

class CustomerEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <div className="app-content content">
                <div className="content-wrapper">

                    {/* PAGE CONTENT HEADER */}
                    <div className="content-header row">
                        <div className={"content-header-left col-md-12 col-12"}>
                            <h2 className={"content-header-title"}>Customer</h2>
                        </div>
                        <div className="content-header row">
                            <div className={"content-header-left col-md-12 col-12 mb-1"}>
                                <Breadcrumbs/>
                            </div>
                        </div>
                    </div>

                    {/* PAGE CONTENT BODY */}
                    <div className="content-body col-md-12 col-12">
                        <div className={"container col-md-12 col-12"}>
                            <div className={"row col-12"}>
                                <div className={"col col-md-12 col-12 content"}>
                                    <EditActionBar />
                                </div>
                            </div>

                            <div className={"row col-12"}>
                                <div className={"col col-md-12 col-12 content"}>
                                    <CustomerEditCard {...this.props}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CustomerEdit;