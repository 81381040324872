import React from "react";
import {Link} from "react-router-dom"
import AWS from 'aws-sdk';

// let {objType, objId, objAction} = ParseLocation()
const urlCurrent = new URL(document.URL);
const params = new URLSearchParams(urlCurrent.search);
let s3 = null;

function getCookie(name) {
    debugger
    let clist = document.cookie.split(';')
    let cookies = clist.map((centry) => {return centry.split('=')});
    let cookie_val;
    cookies.forEach((cookie) => {
        if(cookie[0].trim() === name) {
            cookie_val = cookie[1].trim()}
    })
    return cookie_val;
}

class FileUploadControl extends React.Component {
    constructor(props) {
        super(props);
        this.updateProgress = this.updateProgress.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.state = {
            selectedFile: this.props.value,
            originalFile: this.props.value,
            s3Folder: this.props.folder,
            progress: '',
            aws_config: {
                bucketName: 'darwin-customer-graphics',
                bucketRegion: 'eu-west-1',
                IdentityPoolId: 'eu-west-1_tlT13GL9D',
            // var RoleArn = 'arn:aws:iam::780833200582:role/Cognito_CMHC_IdentityPoolAuth_Role';
            }}
    };

    // On file select (from the pop up)
    onFileChange(event) {
        // Update the state
        if(event.target.files.length > 0){
            this.setState({ selectedFile: event.target.files[0].name});
            this.setState({progress: 0});
        }
    };

    s3AuthenticateAndUpload(fileName, file, updateCallback) {
        debugger
        let accessToken = getCookie('access_token');
        let idToken = getCookie('id_token');

        try {
            // Initialize the Amazon Cognito credentials provider
            AWS.config.region = 'eu-west-1';
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: "eu-west-1:7b843f90-ba62-4960-af3a-3cab6bc6918d",
                Logins: {
                    "cognito-idp.eu-west-1.amazonaws.com/eu-west-1_1RYH1hN91": idToken
                },
            });
            AWS.config.credentials.clearCachedId();
            try {
                AWS.config.credentials.get(function (err) {
                    if (err) {
                        console.log(err);
                    } else {
                        let authParams = {
                            AccessToken: accessToken
                        };
                        let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
                        cognitoidentityserviceprovider.getUser(authParams, function (err, data) {
                            if (err) {
                                // an error occurred
                                console.log(err, err.stack);
                            } else {
                                let s3 = new AWS.S3({
                                    apiVersion: '2006-03-01',
                                    params: {Bucket: 'darwin-customer-graphics'}
                                });
                                // console.log(s3);
                                // return s3

                                s3.upload({
                                    Key: fileName,
                                    Body: file
                                }, function (err, data) {
                                    if (err) {
                                        s3.reject('error');
                                        // } else {
                                        //     $('#onshow').css({'display': 'block', 'opacity': '1', "background": "rgb(256,256,256, 0.7)"})
                                    }
                                }).on('httpUploadProgress', function (progress) {
                                    console.log('httpUpload')
                                    debugger
                                    let uploaded = parseInt((progress.loaded * 100) / progress.total);
                                    // $("progress").attr('value', uploaded);
                                    updateCallback(uploaded);
                                });
                            }
                        });
                    }

                });
            } catch(err) {
                debugger  // credentials.get
                console.log(err)
                return null
            }

        } catch(err) {
            debugger  // CognitoIdentityCredentials
            console.log(err);
            return err
        }
    }

    updateProgress(progress) {
        debugger
        this.setState({'progress': progress})
        if (progress >= 100) {
            this.props.updateGraphicHandler(this.state.selectedFile)
        }
    }

    s3Upload(event) {
        event.preventDefault();
        let fieldName = event.currentTarget.id.split("_")[0];
        let files = document.getElementById(fieldName).files;
        if (files) {
            let file = files[0];
            let fileName = this.props.folder === undefined || this.props.folder === '' ? file.name: this.props.folder + "/" + file.name;
            // let fileUrl = "https://" + this.state.aws_config['bucketRegion'] + ".amazonaws.com" + fileName};
            let s3;
            this.s3AuthenticateAndUpload(fileName, file, this.updateProgress)
        }
    };

    render() {
        let displaySelectedFile = 'Select a file';
        if(this.state.selectedFile !== undefined && this.state.selectedFile !== '') {
            // displaySelectedFile = this.state.selectedFile.name
            displaySelectedFile = this.state.selectedFile
        }

        let uploadProgress = '';
        let upload = <div className={"col col-md-2"}></div>;
        if(this.state.originalFile !== this.state.selectedFile) {
            if (this.state.progress == 100) {
                uploadProgress = <img src={"./assets/img/green_tick.png"} height={'25 px'} width={'auto'} />
            } else {
                uploadProgress = <progress max="100" value="0">{this.state.progress}</progress>
            }

            upload =
            <div>
                <button onClick={event => this.s3Upload(event)} className="btn btn-primary start mr-1" id={this.props.field + "_upload_btn"}>Upload</button>
                { uploadProgress }
            </div>
        }
        let label = displaySelectedFile;
        if(displaySelectedFile.indexOf('/') !== -1) {
            label = displaySelectedFile.split('/')[displaySelectedFile.split('/').length -1]
        }
        return (
            <div>
                {/*<div>{displaySelectedFile}</div>*/}
                {/*<div>{this.state.selectedFile}</div>*/}
                {/*<div>{this.state.originalFile}</div>*/}
                {/*<div>{this.state.folder}</div>*/}
                <div className="custom-file">

                    <input type="file"
                           className="form-control custom-file-input"
                           id={this.props.field}
                           onChange={event => { this.onFileChange(event) }}>
                    </input>

                    <label className="custom-file-label" htmlFor={this.props.field} placeholder={displaySelectedFile}>
                        { label }
                    </label>
                    { upload }
                </div>


            </div>
        )
    }
}
export default FileUploadControl;