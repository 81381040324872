import _ from "lodash";

function dtStringToDate(datetime) {
    if (Object.prototype.toString.call(datetime) === '[object String]') {
        let arrTime;
        let date;
        if (datetime.length < 17) {
            date = new Date()
            arrTime = datetime.split(':');
            arrTime.push(arrTime[2].split('.')[1]);
            arrTime[2] = arrTime[2].split('.')[0];

        } else {
            date = new Date(datetime.slice(0, 10))
            arrTime = datetime.slice(11).split(':');
        }
        date.setHours(arrTime[0]);
        date.setMinutes(arrTime[1]);
        date.setSeconds(arrTime[2]);
        date.setMilliseconds(arrTime[3]);
        return date;
    }
    return null;
}

function getFormattedTime(datetime) {
    // instanceof method can give false positives so using this method instead
    if (Object.prototype.toString.call(datetime) === '[object String]') {
        let arrTime;
        if (datetime.length <17) {
            arrTime = datetime.split(':');
            arrTime.push(arrTime[2].split('.')[1]);
            arrTime[2] = arrTime[2].split('.')[0];
        } else {
            arrTime = datetime.slice(11).split(':');
        }

        return arrTime[0] + ':' + arrTime[1] + ':' + arrTime[2] + '.' + arrTime[3].slice(0,2)
    }

    return datetime.getHours() + ':' + datetime.getMinutes() + ':' + datetime.getSeconds() + '.' +  datetime.getMilliseconds()
}

function updateTelemetryHistory(data, history) {
    // Create a date object with time set and rounded to 10ths of a second,
    // this will be used to compare & group incoming messages to add to the history
    if (data === "" || data === undefined || Object.keys(data).length <= 6 || Object.keys(data).indexOf('Color01') !== -1) {
        // This is a blank message or message of the wrong type so just return the current history
        return history
    } else if (Object.keys(data).indexOf('dt_mqtt') === -1 ||
                Object.keys(data).indexOf('dt_iot') === -1  ||
                Object.keys(data).indexOf('dt_streamproc') === -1  ||
                Object.keys(data).indexOf('lambda_time') === -1) {
        // missing required time_stamps
        console.log("Missing expected timestamps in the message");
        return history;
    }

    // Initial Checks passed - get the lambda_time as a Date/time object
    let timestamp = data['lambda_time']
    let dt_timestamp = new Date();
    dt_timestamp.setHours(timestamp.slice(0, 2));
    dt_timestamp.setMinutes(timestamp.slice(3, 5));
    dt_timestamp.setSeconds(timestamp.slice(6, 8));
    // milliseconds / 10,0000 -> 10ths of a second
    dt_timestamp.setMilliseconds(Math.floor(timestamp.slice(9, 15) / 100000));

    // Calculate time deltas
    let dt_2 = dtStringToDate(data['dt_iot'])
    let dt_3 = dtStringToDate(data['dt_streamproc'])
    let delta_1 = (dt_2 - dtStringToDate(data['dt_mqtt']))
    let delta_2 = (dt_3 - dt_2)
    let delta_3 = (dtStringToDate(data['lambda_time']) - dt_3)

    let fields = history[0];
    // drop row 0 which contains the headings
    history.shift()
    // drop first few rows if there are more than 200 rows of data
    history = history.slice(-Math.min(200, history.length))

    let values;
    let last_rec;
    if (history.length > 0) {
        // get the dt_can
        last_rec = history.slice(-1)[0] // slice still return a 2d array, its just only 1 record though, so get that record
        let last_timestamp = new Date(last_rec[0]); // timestamp field, which is a Date object, but create new Date so code editor can know.

        // getTime returns number of seconds since epoch, as comparing Date objects directly fails even if the have same date time etc
        if (last_timestamp.getTime() === dt_timestamp.getTime() ) {
            values = history.pop() // get last row array in history, removing it at same time
        } else {
            // create a new array of values, based on previous record so that sporadic values are carried forward
            values = new Array(_.cloneDeep(history.slice(-1)[0]));
        }
    }
    if (values === undefined) {
        values = new Array(fields.length)
    }

    for (const [i, key] of fields.entries()) {
        // use the new value if available, else use the old value (should be null by default)
        if (i === 0) { // timestamp - created in this code, not passed as a data attribute
            // values[i] = values[i] !== undefined ? values[i]: dt_timestamp
            // values[i] = values[i] !== undefined ? values[i]: dt_timestamp.getHours() + ':' + dt_timestamp.getMinutes() + ':' + dt_timestamp.getSeconds() + '.' +  dt_timestamp.getMilliseconds()
            values[i] = getFormattedTime(dt_timestamp)
        } else {
            if (data.hasOwnProperty(key)) {
                if (key.slice(0, 2) === 'dt' || key === 'lambda_time') {
                    try {
                        values[i] = getFormattedTime(data[key])
                    } catch(err) {
                        console.log("Failed to format datetime:" + key + ": "+err)
                    }

                } else {
                    try {
                        let numValue = Number(data[key]);
                        if (!isNaN(numValue) ) {
                            values[i] = Number(numValue.toFixed(1))
                        }
                    } catch(err) {
                        console.log(err)
                    }
                }
            } else if (key === 'delta_1') {
                values[i] = delta_1;

            } else if (key === 'delta_2') {
                values[i] = delta_2;

            } else if (key === 'delta_3') {
                values[i] = delta_3;

            } else {
                values[i] = last_rec !== undefined? last_rec[i] : null;
            }
        }
    }
    // TODO - Remove rows that are earlier than our start timestamp horizon

    // Update the state with the result of the updates
    // this.setState({vehicleHistory: update})
    history.unshift(fields)  // pushes field labels back to start of array
    history.push(values) // adds values to end of array

    return history;

}
export default updateTelemetryHistory;