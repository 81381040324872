import React from 'react';
import axios from "axios";


import CardHeaderControls from "../../common/cardHeaderControls";
import ParseLocation from "../../common/functions/parse_location";
import getApiUrl from "../../common/functions/get_api_url";
// import Spinner from "../../UI/Spinner/Spinner";
// import {Link} from "react-router-dom";
import Button from "../../UI/Button/Button"
import getPreSignedUrl from "../../common/functions/get_presigned_url";
// import FileUploadControl from "../../common/fileUploader"

class CustomerEditCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formIsValid: true,
            loading: true,
            customerForm: {
                Customer_ID: {
                    elementType: 'hidden',
                    elementConfig: {
                        type: 'hidden',
                    },
                    label: 'Customer ID:',
                    value: '',
                    valid: true,
                    errors: [],
                },
                customer_name: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Registered Company Name'
                    },
                    label: 'Company Name',
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: true,
                    errors: [],
                    touched: false
                },
                contract: {
                    elementType: 'select',
                    elementConfig: {
                        options: [
                            {value: '', displayValue: '-- please select --'},
                            {value: 'Fixed', displayValue: 'Fixed'},
                            {value: 'Monthly', displayValue: 'Monthly'},
                            {value: 'Quarterly', displayValue: 'Quarterly'},
                            {value: 'Temporary', displayValue: 'Temporary'},
                            {value: 'Limited Contract', displayValue: 'Limited Contract'}
                        ]
                    },
                    label: 'Contract:',
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: true,
                    errors: [],
                    touched: false,
                },

                graphic: {
                    elementType: 'fileupload',
                    elementConfig: {
                        type: 'text',
                        placeholder: ''
                    },
                    value: '',
                    validation: {
                        required: false
                    },
                    valid: true,
                    errors: [],
                    touched: false
                },

                notes: {
                    elementType: 'textarea',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Notes:-'
                    },
                    label: 'Notes:-',
                    value: '',
                    validation: {
                        required: false
                    },
                    valid: true,
                    errors: [],
                    touched: false
                },
                vehicles: {
                    elementType: 'text',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Number of vehicles'
                    },
                    label: 'Vehicles:',
                    value: '',
                    validation: {
                        required: false,
                        isNumeric: true,
                        min: 0,
                        max: 99
                    },
                    valid: true,
                    errors: [],
                    touched: false
                },

            },
        }
    };

    customerHandler = ( event ) => {
        event.preventDefault();
        this.setState( { loading: true } );
        const formData = {};
        for (let formElementKey in this.state.customerForm) {
            formData[formElementKey] = this.state.customerForm[formElementKey].value;
        }

        // const res = axios.put("https://d54915c6-2292-40f7-b255-db4b75956bf8.mock.pstmn.io/customer",
        const res = axios.put(getApiUrl('customer'), formData,
            {
                "headers": {"x-api-key": "PayIA1Z1eh5QHesZKzjob5qfKsWA3OmM1LY4tzfq"}
            }
            )
            .then( response => {
                this.setState( { loading: false } );
                this.props.history.push( '../Detail/' + this.state.customerForm.Customer_ID.value );
                // this.props.history.goBack();
                if(res) {
                    // console.log(res.data.json)
                }
            } )
            .catch( error => {
                if(res) {
                    // console.log(res.data.json)
                }
                this.setState( { loading: false } );
            } );
    };

    checkValidity(updatedFormElement) {
        let value = updatedFormElement.value;
        let rules = updatedFormElement.validation;
        let errors = [];
        if (!rules) {
            return true;
        }
        if (rules.required) {
            let isValid = (value.trim() !== '');
            if (!isValid) {
                errors.push('This field is required and cannot be left empty.');
            }
        }
        if (rules.minLength) {
            let isValid = (value.length >= rules.minLength);
            if (!isValid) {
                errors.push('At least ' + rules.minLength + ' must be entered.');
            }
        }
        if (rules.maxLength) {
            let isValid = (value.length <= rules.maxLength);
            if (!isValid) {
                errors.push('Your entry exceeds the maximum length of  ' + rules.maxLength + ' by ' + (value.length - rules.maxLength) + ' characters.');
            }
        }
        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            let isValid = pattern.test(value);
            if (!isValid) {
                errors.push('Please enter a valid email address.');
            }
        }
        if (rules.isNumeric) {
            let pattern = /^[-+]?\d*?[.]?\d*$/
            let isValid = value.trim() === '' ? true:  pattern.test(value);
            if (!isValid) {
                errors.push('Numeric field: Invalid characters detected, only numbers may be entered');
            }
        }
        if (rules.isDecimal) {
            const pattern = /^\d+$/;
            let isValid = value.trim() === '' ? true:  pattern.test(value);
            if (!isValid) {
                errors.push('Decimal field: Invalid characters detected, only integer numbers may be entered');
            }
        }
        if (rules.min !== undefined) {
            let isValid = value.trim() === '' ? true: parseFloat(value) >= rules.min;
            if (!isValid) {
                errors.push('The entered number is too small.');
            }
        }
        if (rules.max !== undefined) {
            let isValid = value.trim() === '' ? true: parseFloat(value) <= rules.max;
            if (!isValid) {
                errors.push('The entered number is too large.');
            }
        }
        updatedFormElement.valid = errors.length === 0;
        updatedFormElement.errors = errors;
        return updatedFormElement.valid;
    };

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedCustomerForm = {
            ...this.state.customerForm
        };
        const updatedFormElement = {
            ...updatedCustomerForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        // updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        this.checkValidity(updatedFormElement)
        updatedFormElement.touched = true;
        updatedCustomerForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedCustomerForm) {
            formIsValid = updatedCustomerForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({customerForm: updatedCustomerForm, formIsValid: formIsValid});
    }

    // React Component Lifecycle events
    componentDidMount() {
        // TODO - This should receive the state from the details screen where possible
        this.setState({ loading: true });
        let {objType, objId, objAction} = ParseLocation()
        fetch(getApiUrl(objType, [["Customer_ID", objId],]))
            .then(res => res.json())
            .then((data) => {
                // Update the component state, setting the value of each customerForm Element from the received data
                const updatedCustomerForm = {
                    ...this.state.customerForm
                };
                for (let formElementKey in this.state.customerForm) {
                    updatedCustomerForm[formElementKey].value = data[formElementKey];
                }
                this.setState({customerForm: updatedCustomerForm, loading: false});
                getPreSignedUrl('img_logo', data.graphic, 'darwin-customer-graphics');
                // this.forceUpdate()
            })
            .catch(function(e){
                console.log(e);
            })
        // this.setState({ loading: false });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.customerForm != prevState.customerForm) {
            getPreSignedUrl('img_logo', this.state.customerForm.graphic.value, 'darwin-customer-graphics');
        }
    }

    render() {
        // Conditionally create content
        let logo_img = '';
        if (!this.state.loading && this.state.customerForm['graphic'] !== undefined && this.state.customerForm['graphic'] !== '') {
            logo_img = <img src="" id="img_logo"
                            height="30" width="auto"
                            alt="Customer logo"
                            className="float-left"/>
        }

        let card_header = '';
        if (!this.state.loading) { card_header =
            <span>
                {logo_img}
                {this.state.customerForm['customer_name'].value} Details:
            </span>
        }
        let card_content;
        if (false !== true ) { card_content =
            <div>
                <form className="form form-horizontal">
                    {/* FORM SECTION */}
                    <div className="form-body">
                        <h4 className="form-section"><i className="ft-user"></i>Customer Details</h4>

                        <div className="form-group row">
                            <label className="col-md-3 label-control" htmlFor="customer_name">Customer Name</label>
                            <div className="col-md-9">
                                <input id="customer_name" type="text"
                                       className="form-control" placeholder="Customer name" name="customer_name"
                                       value={this.state.customerForm.customer_name.value}
                                       invalid={this.state.customerForm['customer_name'].elementConfig.valid}
                                       shouldValidate={this.state.customerForm['customer_name'].elementConfig.validation}
                                       touched={this.state.customerForm['customer_name'].elementConfig.touched}
                                       onChange={(event) => this.inputChangedHandler(event,'customer_name')}
                                ></input>
                                <div id="customer_name_error" className="error">{this.state.customerForm.customer_name.errors.join('<br />')}</div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-3 label-control" htmlFor="contract">Contract</label>
                            <div className="col-md-9">
                                <select  id="contract" className="form-control" name="contract"
                                         value={this.state.customerForm['contract'].value}
                                         invalid={this.state.customerForm['contract'].elementConfig.valid}
                                         shouldValidate={this.state.customerForm['contract'].elementConfig.validation}
                                         touched={this.state.customerForm['contract'].elementConfig.touched}
                                         onChange={(event) => this.inputChangedHandler(event,'contract')}
                                >
                                    <option value="Fixed">Fixed</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Temporary">Temporary</option>
                                    <option value="Limited Contract">Limited Contract</option>
                                </select>
                                <div id="contract_error" className="error">{this.state.customerForm.contract.errors}</div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-3 label-control" htmlFor="vehicles">Vehicles</label>
                            <div className="col-md-9">
                                <input type="text" id="vehicles" className="form-control" placeholder="No. of vehicles" name="vehicles"
                                       value={this.state.customerForm['vehicles'].value}
                                       invalid={this.state.customerForm['vehicles'].elementConfig.valid}
                                       shouldValidate={this.state.customerForm['vehicles'].elementConfig.validation}
                                       touched={this.state.customerForm['vehicles'].elementConfig.touched}
                                       onChange={(event) => this.inputChangedHandler(event,'vehicles')}
                                />
                                <div id="vehicles_error" className="error">{this.state.customerForm.vehicles.errors}</div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-3 label-control" htmlFor="notes">Notes:-</label>
                            <div className="col-md-9">
                                <textarea id="notes" className="form-control" placeholder="Notes ..." name="notes" cols={"40"} rows={"10"}
                                          value={this.state.customerForm['notes'].value}
                                          invalid={this.state.customerForm['notes'].elementConfig.valid}
                                          shouldValidate={this.state.customerForm['notes'].elementConfig.validation}
                                          touched={this.state.customerForm['notes'].elementConfig.touched}
                                          onChange={(event) => this.inputChangedHandler(event,'notes')}
                                />
                                <div id="notes_error" className="error">{this.state.customerForm.notes.errors}</div>
                            </div>
                        </div>

                        <div className="float-md-right">
                            {/* JQuery should be used to validate and conditionally navigate */}
                            <Button btnType="Success"
                                    disabled={!this.state.formIsValid}
                                    clicked={this.customerHandler}
                            >Save</Button>
                        </div>

                        {/*<FileUploader />*/}
                    </div>
                </form>
            </div>
        }

        // if (this.state.loading === false) { card_content =
        //     <Spinner />
        // }
        return (
            // CARD CONTAINER
            <div className="card overflow-auto ">

                {/* CARD CONTENT HEADER */}
                <div className="card-header">
                    <h4 className="card-title">{ card_header }</h4>
                    <CardHeaderControls/>
                </div>

                {/* CARD CONTENT BODY */}
                <div className="card-content show">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>

        );
    }
}

export default CustomerEditCard;