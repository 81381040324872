import React from 'react';

import updateTelemetryHistory from "../functions/vehicle_history";

import ParseLocation from "../../common/functions/parse_location";
import getVehicleDetails from "../../common/functions/vehicle_details_api";
import getVehicleTelemetryWs from "../../common/functions/vehicle_telemetry_api";


import VehicleDataCard from "../cards/vehicleDataCard";
import VehicleDataChartCard from "../cards/vehicleDataChartCard";
import Vehicle3dModelCard from "../cards/vehicle3dModelCard";
import ViewActionBar from "../../common/viewActionBar";
import Breadcrumbs from "../../common/breadcrumbs";
import VehicleDetailsRegistrationCard from "../cards/vehicleDetailsRegistrationCard";
import getPreSignedUrl from "../../common/functions/get_presigned_url";


class VehicleData extends React.Component {
    constructor(props) {
        let {objType, objId, ObjAction} = ParseLocation()
        super(props);
        this.state = {
            user_id: null,
            Customer_ID: null,
            Vehicle_ID: objId,
            vehicleDetails: null,
            vehicleTelemetry: null,
            vehicleHistory: [[  // Array of fleshed-out telemetry data over time.
                'timestamp',
                // 'dt_canbus',
                // 'dt_filter',
                'dt_mqtt',
                'delta_1',
                'dt_iot',
                'delta_2',
                'lambda_time',
                'delta_3',
                'dt_streamproc',
                'BatteryVoltage',
                'AvailableEnergy',
                'VehicleSpeed',
                'Speed_Actual',
                'Pedal_Actual',
                'Right_Indicator_B',
                'Left_Indicator_B',
                'Steer_Actual',
                'Horn_B',
                'Main_Beam_B',
                'Dipped_Lights_B',
                'Side_Lights_B',
                'Reverse_Light_B',
                'Brake_Light_B',
                'Brake_Actual'
                // 'LATITUDE',
                // 'LONGITUDE'
            ]],
        }
        this.updateDetailsCard = React.createRef();
        this.updateTelemetryTableCard = React.createRef();
        this.updateTelemetryChartCard = React.createRef();
        this.update3DModelCard = React.createRef();
        this.wsClient = null;
    };

    componentDidMount() {
        // If we have a Vehicle_ID but not vehicleDetails then get vehicleDetails
        if (this.state.Vehicle_ID !== null && this.state.Vehicle_ID !== undefined && this.state.vehicleDetails == null) {
            try {
                getVehicleDetails(this.state.Vehicle_ID, (data) => {
                    this.setState({'vehicleDetails': data})
                });
            } catch(err) {
                console.log("Vehicle Details Call Failed with Error: " + err);
            }

            // Connect to the Vehicle Telemetry WebSocket API,
            try {
                getVehicleTelemetryWs(this.state.Vehicle_ID, (data) => {
                    this.setState({vehicleTelemetry: data});
                    this.setState({vehicleHistory: updateTelemetryHistory(data, this.state.vehicleHistory)});
                });
            } catch (err) {
                console.log("Vehicle Telemetry Call Failed with Error: " + err);
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        getPreSignedUrl('img_logo', this.state.vehicleDetails.graphic, 'darwin-vehicle-graphics');

        if (this.state.vehicleDetails !== prevState.vehicleDetails) {
            this.updateDetailsCard.current.setState({vehicleDetails: this.state.vehicleDetails});
        }

        if (this.state.vehicleTelemetry !== prevState.vehicleTelemetry) {
            this.updateTelemetryTableCard.current.setState({vehicleHistory: this.state.vehicleHistory});
            this.updateTelemetryChartCard.current.setState({vehicleHistory: this.state.vehicleHistory});
            this.update3DModelCard.current.setState({vehicleTelemetry: this.state.vehicleTelemetry})
        }
    };

    render() {
        let {objType, objId, objAction} = ParseLocation()
        return (
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2">
                            <span className="content-header-title">
                                <img src="" id="img_logo" height="30" width="auto"
                                     alt={"Vehicle icon"}
                                     className="float-left mr-1"/>
                                <h3>{objId}</h3>
                            </span>
                            <Breadcrumbs/>
                            <ViewActionBar/>
                        </div>
                    </div>
                    <div className="content-body">
                        {/*<div className="row">*/}
                        {/*    <div className="col-md-12 col-12">*/}
                        {/*        {JSON.stringify(this.state.vehicleDetails)}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <VehicleDetailsRegistrationCard {...this.props} ref={this.updateDetailsCard} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12">
                                <VehicleDataCard {...this.props} ref={this.updateTelemetryTableCard}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12">
                                <VehicleDataChartCard {...this.props} ref={this.updateTelemetryChartCard}/>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12 col-12">
                                <Vehicle3dModelCard {...this.props} ref={this.update3DModelCard}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default VehicleData;
