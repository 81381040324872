import React from 'react';
import { Link } from "react-router-dom"

import ParseLocation from "./functions/parse_location";

const urlCurrent = new URL(document.URL);
const params = new URLSearchParams(urlCurrent.search);
const qs_auth = params.get('access_token') !== null ? '?access_token=' + params.get('access_token') + '&id_token=' + params.get('id_token') : '';


class TableActionBar extends React.Component {
    render() {
        let { objType, objId, objAction } = ParseLocation()
        return (
            <div className="card-header">
                <div className="float-left">
                    <span className="dropdown">
                        <button id="btnSearchDrop2" type="button" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" className="btn btn-info dropdown-toggle">
                            <i className="fa fa-cog"></i>
                        </button>
                        <span aria-labelledby="btnSearchDrop2" className="dropdown-menu mt-1 dropdown-menu-right">
                            <button type="button" id="btnDelete" className="btn dropdown-item">
                                <span className="dropdown-item"><i className="ft-trash"></i>Delete</span>
                            </button>
                        </span>
                    </span>
                </div>
                <div className="float-md-right">
                    <button id="btnAdd" className="btn btn-primary btn-sm">
                        {/*Link drops the 's' associated with list e.g. Customer[s]*/}
                        <Link to={"/" + objType + '/Add' + qs_auth}>
                            <span><i className="ft-plus white"> </i>New</span>
                        </Link>
                    </button>
                </div>
            </div>
        )
    }
}

export default TableActionBar;