import Axios from "axios";
import getApiUrl from "./get_api_url";
// const AWS = require('aws-sdk/dist/aws-sdk-react-native'); // Everything in sdk for react-native
const AWS = require('aws-sdk/clients/ssm'); // only the AWS Systems Manager
// const AWS = require('aws-sdk');  // Everything in sdk

function getAwsParameter(param_name) {
    switch(param_name) {
        case 'S3_Livery_Graphics_Secure':
            return 'https://darwin-vehicle-graphics.s3-eu-west-1.amazonaws.com';
            break;
        case 'S3_Livery_Graphics':
            return 'https://twizzy-graphics.s3-eu-west-1.amazonaws.com';
            break;

        case 'AUTH':
            // this should return the bearer from the browser authentication
            return "";
            break;

        case 'WebsocketURL':
            return 'wss://qc2pwheuvh.execute-api.eu-west-1.amazonaws.com/dev/'
            break;

        default:
            AWS.config.update({
                apiVersion: '2006-03-01',
                region: 'eu-west-01',
                credentials: {}
            });

            let ssm = new AWS.SSM();
            ssm.getParameter({
                Name: param_name,
                WithDecryption: true
            }, function(err, data) {
                if (err) {
                    console.log("Parameter: '" + param_name + "' was not retrieved from the store. Because: "+ err );
                } else {
                    console.log("Parameter: '" + param_name + "' has value: " + data);
                    return data
                }
            });
    }




}

export default getAwsParameter;