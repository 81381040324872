import React from 'react'

import ParseLocation from "../../common/functions/parse_location";
// import getVehicleLiveryData from "../../common/functions/vehicle_livery_api";
import getVehicleTelemetryWs from "../../common/functions/vehicle_telemetry_api";

import Breadcrumbs from "../../common/breadcrumbs";
import EditActionBar from "../../common/EditActionBar";
import VehicleLiveryHeaderCard from "../cards/vehicleLiveryHeaderCard"
// import VehicleLiveryEditColorsCard from "../cards/vehicleLiveryEditColorsCard";
// import VehicleLiveryEditCameraCard from "../cards/vehicleLiveryEditCameraCard";
import Vehicle3dModelCard from "../../vehicle/cards/vehicle3dModelCard";

class VehicleLiveryAdd extends React.Component {
    constructor(props) {
        super(props);
        this.updateHandler = this.updateHandler.bind(this);
        this.updateHeaderCard = React.createRef();
        this.update3dModelCard = React.createRef();
        this.updateCameraCard = React.createRef();
        this.updateLiveryCard = React.createRef();
        this.state = {
            vehicle_id: null,
            liveryDetail: {
                "Vehicle_ID": "_template_twizy",
                "isTemplate": true,
                "Color01": "#FFFFFF",
                "Color03": "#FFFFFF",
                "Color05": "#FFFFFF",
                "Color06": "#FFFFFF",
                "Color07": "#FFFFFF",
                "Color09": "#FFFFFF",
                "Color10": "#FFFFFF",
                "Color11": "#FFFFFF",
                "Color12": "#FFFFFF",
                "Image01": "",
                "Image02L": "",
                "Image02R": "",
                "Image03L": "",
                "Image03R": "",
                "Image07": "",
                "Image08": "",
                "Image09": "",
                "Image11": "",
                "Image12": "",
                "Camera_In_Out": "0",
                "Camera_Rotation": "0",
                "Camera_Up_Down": "0",
                "Camera_Zoom": "0",
                "Door_Back": "0",
                "Door_Left": "0",
                "Door_Right": "0",
                "Wiper": "0"
            }
        }
    };

    // Called by the child cards to update state across all components on page
    updateHandler(state) {
        this.setState(state);
    }

    componentDidMount() {
        debugger
        // If the url indicates that we are creating a 'custom vehicle livery' instead of a template livery
        // i.e. a vehicle id was passed.
        const {objType, objId, objAction} = ParseLocation()
        if(objId !== null) {
            this.setState({vehicle_id: objId})

            // Connect to the Vehicle Telemetry WebSocket API,
            try {
                getVehicleTelemetryWs(objId, (data) => {
                    this.setState({vehicleTelemetry: data});
                });
            } catch (err) {
                console.log("Vehicle Telemetry Call Failed with Error: " + err);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        if(this.state.liveryDetail !== prevState.liveryDetail) {
            this.updateLiveryCard.current.setState({liveryDetail: this.state.liveryDetail});
            // this.updateCameraCard.current.setState({liveryDetail: this.state.liveryDetail});
            // this.update3dModelCard.current.setState({vehicleTelemetry: this.state.liveryDetail});
        }
        // vehicle_id was updated [from url], so push to header to enable change from editable id to fixed id and livery type
        if(this.state.vehicle_id !== prevState.vehicle_id) {
            this.updateHeaderCard.current.setState({vehicle_id: this.state.vehicle_id})
        }
    }

    componentWillUnmount() {
        debugger
    }

    render() {
        debugger
        return (
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2">
                            <span className="content-header-title">
                                <h3>{this.state.vehicle_id !== "" ? "New Custom Livery for: " + this.state.vehicle_id : "New Livery Template"}</h3>
                            </span>
                            <Breadcrumbs/>
                            <EditActionBar/>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <VehicleLiveryHeaderCard {...this.props} ref={this.updateHeaderCard} updateHandler={this.updateHandler}/>
                                {/*<VehicleLiveryEditCameraCard {...this.props} ref={this.updateCameraCard} updateHandler={this.updateHandler}/>*/}
                                {/*<VehicleLiveryEditColorsCard {...this.props} ref={this.updateLiveryCard} updateHandler={this.updateHandler}/>*/}
                            </div>

                            <div className="col-md-6 col-12">
                                <Vehicle3dModelCard {...this.props} ref={this.update3dModelCard} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VehicleLiveryAdd;