import React from 'react';

import CardHeaderControls from "../../common/cardHeaderControls";
import checkValidity from "../../common/functions/check_validity";
import ParseLocation from "../../common/functions/parse_location";
import Spinner from "../../UI/Spinner/Spinner";
import Input from "../../UI/Input/Input";

// const URL = require('url'); // nodejs-builtin

class VehicleEditRegistrationCard extends React.Component {
    constructor(props) {
        super(props);
        this.updateHandler = this.updateHandler.bind(this)
        this.state = {
            formIsValid: true,
            formIsDirty: false,
            loading: true,
            vehicleDetails: {},
            vehicleForm: {
                Vehicle_ID: {
                    elementType: 'hidden',
                    elementConfig: {
                        type: 'hidden',
                    },
                    label: 'Vehicle ID:',
                    value: '',
                    valid: true,
                    errors: [],
                },
                purchase_date: {
                    elementType: 'date',
                    elementConfig: {
                    },
                    validation: {
                        required: true,
                        isDate: true,
                    },
                    label: 'Date purchased:',
                    value: '',
                    valid: false,
                    errors: [],
                },
                registrationForm: {
                    vin_number: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            country: 'uk',
                        },
                        validation: {
                            required: true,
                            maxLength: 8
                        },
                        label: 'VIN Number:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    registration_plate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            country: 'uk',
                        },
                        validation: {
                            required: true,
                            maxLength: 8
                        },
                        label: 'Registration plate:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    make: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                        },
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 30
                        },
                        label: 'Make:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    model: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                        },
                        validation: {
                            required: true,
                            minLength: 2,
                            maxLength: 30
                        },
                        label: 'Model:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    document_ref: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                        },
                        validation: {
                            required: true,
                            minLength: 10,
                            maxLength: 20
                        },
                        label: 'V5 Reference:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    first_registered: {
                        elementType: 'date',
                        elementConfig: {
                        },
                        validation: {
                            required: true,
                            isDate: true,
                        },
                        label: 'First registered:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    country: {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {key: 'UK', value: 'United Kingdom'},
                                {key: 'ES', value: 'Spain'},
                                {key: 'FR', value: 'France'}
                            ]
                        },
                        validation: {
                            required: true,
                        },
                        label: 'Country of registration:',
                        value: '',
                        valid: false,
                        errors: [],
                    },
                    colour: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                        },
                        validation: {
                            required: true,
                            maxLength: 12
                        },
                        label: 'Colour:',
                        value: '',
                        valid: false,
                        errors: [],
                        // changed: this.inputChangedHandler
                    },
                }
            }
        }
    }

    updateHandler = (fieldName, value) => {
        // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
        let updatedForm = { ...this.state.vehicleForm };
        let updatedFormElement = {...updatedForm[fieldName]};
        updatedFormElement.value = value;
        checkValidity(updatedFormElement);
        updatedFormElement.touched = true;
        updatedForm[fieldName] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({vehicleForm: updatedForm });
    }

    inputChangedHandler = event => {
        debugger
        const updatedVehicleForm = {
            ...this.state.vehicleForm
        };
        let nodeName;
        let targetId;
        let id = event.target.id
        if (id.indexOf('/') >-1) {
            let parts = id.split('/');
            nodeName = parts[0];
            targetId = parts[1];
        } else {
            targetId = id;
        }

        let updatedFormElement;
        if (nodeName === undefined) {
            updatedFormElement = {...updatedVehicleForm[targetId]};
        } else {
            updatedFormElement = {...updatedVehicleForm[nodeName][targetId]};
        }
        updatedFormElement.value = event.target.value;
        // updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        checkValidity(updatedFormElement)
        updatedFormElement.touched = true;
        let formIsValid = true;
        if (nodeName === undefined) {
            updatedVehicleForm[targetId] = updatedFormElement;
            for (let inputIdentifier in updatedVehicleForm) {
                formIsValid = updatedVehicleForm[inputIdentifier].valid && formIsValid;
            }

        } else {
            updatedVehicleForm[nodeName][targetId] = updatedFormElement;
            for (let inputIdentifier in updatedVehicleForm[nodeName]) {
                formIsValid = updatedVehicleForm[nodeName][inputIdentifier].valid && formIsValid;
            }
        }



        this.setState({vehicleForm: updatedVehicleForm,
                            formIsValid: formIsValid,
                            formIsDirty: true
        });
    }

    // componentDidMount() {
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        if(this.state.vehicleDetails !== prevState.vehicleDetails) {
            // Updates nested attributes by making a copy of parent, then element, updating and assigning back to original
            let updateForm = { ...this.state.vehicleForm };

            Object.keys(this.state.vehicleForm).forEach(field => {
                debugger
                if (field.slice(-4) != 'Form') {
                    // root level fields
                    if (this.state.vehicleForm.hasOwnProperty(field)) {
                        let updateElement = { ...updateForm[field] };
                        updateElement.value = this.state.vehicleDetails[field];
                        updateForm[field] = { ...updateElement };
                    }

                } else {
                    // nested level fields
                    let node = field.slice(0, field.length - 4);
                    Object.keys(this.state.vehicleForm[field]).forEach(nodeField => {
                        if (this.state.vehicleDetails[node].hasOwnProperty(nodeField)) {
                            let updateElement = { ...updateForm[field][nodeField] };
                            updateElement.value = this.state.vehicleDetails[node][nodeField];
                            updateForm[field][nodeField] = { ...updateElement };
                        }
                    });
                }
            });
            this.setState({vehicleForm: { ...updateForm }})
        }
    }

    // componentWillUnmount() {
    // }


    render() {
        const {objType, objId, objAction} = ParseLocation();

        // Always start with a spinner
        let card_content = <Spinner />

        // Once the Card data has been loaded then show the real content
        // if (this.state.liveryDetail !== null) {
        //     card_content = processRows(this.state.liveryDetail)
        // }
        // if (this.state.vehicleDetails !== null && this.state.vehicleDetails.Vehicle_ID === this.state.vehicleForm.Vehicle_ID.value) {
        if (JSON.stringify(this.state.vehicleDetails) !== "{}" || objAction === 'Add') {
            card_content = []
            Object.keys(this.state.vehicleForm).forEach((key, index) => {
                if (key.slice(-4) !== 'Form') {
                    // let input_content = <Input {...this.state.vehicleForm[key]} id={key} field={key} changed={this.inputChangedHandler} />
                    let input_content = <Input {...this.state.vehicleForm[key]} id={key} changed={this.inputChangedHandler} />
                    card_content.push(input_content);
                } else {
                    let node = key.slice(0, key.length -4);
                    Object.keys(this.state.vehicleForm[key]).forEach((nodeKey, index) => {
                        // let input_content = <Input {...this.state.vehicleForm[key][nodeKey]} id={key+'/'+nodeKey} field={nodeKey} changed={this.inputChangedHandler}/>
                        let input_content = <Input {...this.state.vehicleForm[key][nodeKey]} id={key+'/'+nodeKey} changed={this.inputChangedHandler}/>
                        card_content.push(input_content);
                    });
                }
            });
        }

        return (
            <div className="card">
                <div className="card-header">
                    <div><h4 className="card-title">Registration</h4></div>
                    <CardHeaderControls/>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        { card_content }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleEditRegistrationCard;