import React from 'react';


import CardHeaderControls from "../../common/cardHeaderControls";
import VehicleListTable from "../parts/vehicleListTable";

class VehicleListCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <div className="card overflow-auto ">

                {/* CARD CONTENT HEADER */}
                <div className="card-header">
                    <h4 className="card-title">Vehicles</h4>
                    <CardHeaderControls/>
                </div>

                {/* CARD CONTENT BODY */}
                <div className="card-content show">
                    <div className="card-body">
                        <VehicleListTable {...this.props}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleListCard;