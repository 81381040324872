import React from 'react';
import { Link } from "react-router-dom";
import ParseLocation from "./functions/parse_location";
// import { lastLocation } from 'react-router-last-location'
import $ from 'jquery'

class EditActionBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let {objType, objId, objAction} = ParseLocation();
        debugger
        return (
                <div className="float-md-right">
                    {/*TODO - This should pick up the return location from location history really */}
                    <Link to={objAction === "Add" ? "/"+objType+"s" : "/"+objType+"/Detail/"+objId}>
                    {/*<Link to={ lastLocation }>*/}
                        <button id="btnCancel"  className="btn btn-primary btn-sm mr-1">
                            <span><i className="ft-x mr-1"></i>Cancel</span>
                        </button>
                    </Link>

                    {/* JQuery should be used to validate and conditionally navigate */}
                    <button className="btn btn-primary btn-sm "
                            onClick={this.props.saveFormHandler}>
                        <span><i className="ft-edit white mr-1"></i>Save</span>
                    </button>
                </div>
        )
    }
}

export default EditActionBar;
