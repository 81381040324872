import React from 'react'

// APIS
import getVehicleLiveryData from "../../common/functions/vehicle_livery_api";
import VehicleLiveryTable from "../parts/vehicleLiveryTable";
import Breadcrumbs from "../../common/breadcrumbs";


class VehicleLiveryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {vehicle_liveries: []};
        this.updateTableCard = React.createRef();
    }

    componentDidMount() {
        getVehicleLiveryData(null, 'Template', (data) => {
            this.setState({"vehicle_liveries": data.Items})
            this.updateTableCard.current.setState({vehicle_liveries: this.state.vehicle_liveries})
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        this.updateTableCard.current.setState({vehicle_liveries: this.state.vehicle_liveries})
    }

    // componentWillUnmount() {
    // }

    render() {
        return(
            <div className="app-content content">
                <div className="content-wrapper">
                    {/* PAGE CONTENT HEADER */}
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2">
                            <h2>Vehicle Livery Templates</h2>
                        </div>
                    </div>
                    <div className="content-header row">
                        <div className={"content-header-left col-md-12 col-12 mb-1"}>
                            <Breadcrumbs {...this.props}/>
                        </div>
                    </div>

                    {/* PAGE CONTENT BODY */}
                    <div className="content-body col-md-12 col-12">
                        <div className={"container col-md-12 col-12"}>
                            <div className={"row col-12"}>
                                <div className={"col col-md-12 col-12 content"}>
                                    <VehicleLiveryTable {...this.props} ref={this.updateTableCard} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

export default VehicleLiveryList;